




































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import { types as layoutTypes } from '../../store/layout';
import { types as applicationTypes } from '../../store/application';
import { languagesTypes, metaTypes } from '@client/store/types';
import { smoothlyMenu } from '../../theme/inspinia';
import { utils } from '@client/router/utils';

@Component({
  components: {},
  computed: {
    ...mapGetters({
          currentLanguage : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
          isLanguageSwitchAllowed : 'application/' + applicationTypes.getters.IS_LANGUAGE_SWITCH_ALLOWED,
        }),
    ...mapState({
          availableLanguages : (state:any) => state.languages.availableLanguages
        })
  }
})
export default class Header extends Vue {

  isSearchAllowed:boolean = false;
  isConfigAllowed:boolean = false;

  onMiniNavbarClick(evt:Event) {
    evt.preventDefault();
    // Open / Close menu in canvas mode
    this.$store.commit('meta/' + metaTypes.mutations.TOGGLE_BODY_CLASS, 'mini-navbar');
    // We ensure that the opening is smooth
    smoothlyMenu(this.$store);
  }

  onLanguageClick(languageCode:string) {
        // We get the current route short name
        const routeShortName = utils.getRouteShortNameFromRoute(this.$store.state.route, this.$router.app);
        this.$router.push('/' + languageCode + '/' + routeShortName);
    }

}
















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PageTitle extends Vue {

    @Prop({
    type: String,
    required: true
    }) readonly title!: string

    /**
     * Array of breadcrumb which contain:
     * - isActive: boolean true if link should be active.
     * - label: the label to be displayed.
     */
    @Prop({
    type: Array,
    required: false
    }) readonly breadcrumbs!: any[]

}


































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { mapState, mapGetters } from 'vuex';
import { Component, Watch } from 'vue-property-decorator';
import Header from '../panels/Header.vue';
import Menu from '../panels/Menu.vue';

@Component({
  components: { Header, Menu }
})
export default class Portal extends Vue {

  year = (new Date()).getFullYear();
  
  constructor() {
    super();
  }

  mounted() {
    
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
  afterLeave () {
    this.$root.$emit('triggerScroll');
  }

}

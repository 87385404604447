












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';

@Component({
  components : {}
})
export default class Conditions extends mixins(GenericPage) {

  mounted() {
    if(this.$route.query.print) {
      window.print();
    }
  }
  
}















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Portal from './components/layouts/Portal.vue';
import Empty from './components/layouts/Empty.vue';
import { types as layoutTypes } from './store/layout';
import { messagesTypes, MessageTypes, metaTypes, Message } from '@client/store/types';
import { checkWindowSize , fixHeight } from './theme/inspinia';
import { toastrMessages } from './utils/application';

@Component({
  components: { Portal }
})
export default class App extends Vue {

  layoutName:string | null = null;

  get layout() {
    if(this.layoutName == null) {
      return Empty;
    }
    else if(this.layoutName == "Portal") {
      return Portal;
    }
    else {
      return this.$store.state.router.availableLayouts[this.layoutName];
    }
  }

  get messages():Message[] {
    return this.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
  }

  /**
   * Lifecycle events
   */
  created() {
    if(typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize);
      window.addEventListener('load', this.onLoad);
      window.addEventListener('scroll', this.onScroll);
    }
    this.updateLayout();
  }
  
  destroyed() {
    if(typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('load', this.onLoad);
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  mounted() {
    fixHeight(this.$store);
    if(this.messages.length > 0) {
      toastrMessages(this);
    }
  }

  /**
   * Watch
   */
  @Watch('$route')
  onRouteChange(to:any, from:any) {
      this.updateLayout();
  }

  @Watch('messages')
  onMessagesChange(messages:Message[], previous:Message[]) {
    toastrMessages(this);
  }

  onLoad(event:Event) {
    checkWindowSize(this.$store);
    
    var _self = this;
    setTimeout(function(){
        if(_self.$store.state.meta.bodyClasses.indexOf('body-small') < 0) {
          fixHeight(_self.$store);
        }
    });
  }

  onResize(event:Event) {
    checkWindowSize(this.$store);
    
    var _self = this;
    setTimeout(function(){
        if(_self.$store.state.meta.bodyClasses.indexOf('body-small') < 0) {
          fixHeight(_self.$store);
        }
    });
  }

  onScroll(event:Event) {
    var _self = this;
    setTimeout(function(){
        if(_self.$store.state.meta.bodyClasses.indexOf('body-small') < 0) {
          fixHeight(_self.$store);
        }
    });
  }

  /**
   * Methods
   */
  updateLayout() {
    // We check the layout configured for the current route.
    var layoutName = this.$route.meta.layout;
    if(layoutName) {
      this.layoutName = layoutName;
    }
    else {
      this.layoutName = null;
    }
  }
}

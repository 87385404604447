















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Civility extends mixins<GenericInput<string>>(GenericInput) {

  civilities = ["MR","MRS"];

  inputLabel = "";
  inputPlaceholder = "";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$t('profile.info.civility') as string
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$t('profile.info.civility_select') as string
  }
  
}

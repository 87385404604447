import { Module } from 'vuex';
import { Route } from 'vue-router';
import Vue from 'vue';

import { utils as RouterUtils } from '@client/router/utils';
import { metaTypes as types, languagesTypes } from '@client/store/types';


export function createMetaStore () {
  const store:Module<any, any> = {
    namespaced: true,
    state: {
      /**
       * metas contains for each language the common meta + all route meta loaded
       * ex:
       *  metas: {
       *    fr: {
       *      bundle : {
       *        key : value
       *      }
       *    }
       *  }
       */
      metas: {},
      bodyClasses: []
    },
    getters: {
      /**
       * GET_CURRENT_META
       * This getter get the localized meta from current route (in route store).
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.getters.GET_CURRENT_META] (state, getters, rootState, rootGetters):any {
        // This will be updated each time the route is updated
        var route:Route = rootState.route;
        // This will be updated each time the language is updated as well.
        const languageCode = rootState.languages.currentLanguageCode;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - META - GET_CURRENT_META - " + route.path + " - " + languageCode)
        }

        // We get the common localized metas
        const localizedMeta = state.metas[languageCode];
        var routeBundles = RouterUtils.getLanguageBundlesFromRoute(route);
        const metaCommon = localizedMeta[languagesTypes.COMMON_BUNDLE];

        var meta = {...metaCommon};
        if(routeBundles) {
          // We check in all route bundles
          for(var routeBundle of routeBundles) {
            var metaRoute = localizedMeta[routeBundle];
            if(metaRoute) {
              // We merge with the route specific localized metas
              meta = {...meta, ...metaRoute}
            }
          }
        }

        if(!meta) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - META - GET_CURRENT_META - No localized meta defined for current route");
          }
          meta = {};
        }

        return meta;
      },
      /**
       * GET_BODY_CLASSES
       * This getter get the body classes from current route (in route store) and current store.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.getters.GET_BODY_CLASSES] (state, getters, rootState, rootGetters):any {
        // This will be updated each time the route is updated
        var route:Route = rootState.route;
        // This will be updated each time the bodyClasses is updated
        const storeBodyClasses = state.bodyClasses;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - META - GET_BODY_CLASSES - " + route.path)
        }

        // We check about the bodyClasses attribute in Route meta object
        var bodyClasses:Array<string> = [];
        if(route.meta && route.meta.bodyClasses) {
          bodyClasses = route.meta.bodyClasses;
        };
        if(storeBodyClasses.length > 0) {
            bodyClasses = bodyClasses.concat(storeBodyClasses);
        }

        return bodyClasses;
      }
    },
    mutations: {
        [types.mutations.ADD_BODY_CLASS] (state, bodyClass) {
            // We update the store
            if(state.bodyClasses.indexOf(bodyClass) < 0) {
                state.bodyClasses.push(bodyClass);
            }
        },
        [types.mutations.REMOVE_BODY_CLASS] (state, bodyClass) {
            if(state.bodyClasses.indexOf(bodyClass) > -1) {
                state.bodyClasses.splice( state.bodyClasses.indexOf(bodyClass), 1 );
            }
        },
        [types.mutations.TOGGLE_BODY_CLASS] (state, bodyClass) {
            if(state.bodyClasses.indexOf(bodyClass) > -1) {
                state.bodyClasses.splice( state.bodyClasses.indexOf(bodyClass), 1 );
            }
            else {
                state.bodyClasses.push(bodyClass);
            }
        },
        [types.mutations.UPDATE_META_FROM_TRANSLATIONS] (state, payload) {
            var {languageCode, translations, bundle} = payload;
            if(translations !== undefined) {
                // We extract the metas from translations (metas are always prefixed by bundle indicator)
                var metas = {
                    [bundle] : translations.meta
                };
    
                // merge the meta
                if(state.metas[languageCode]) {
                    state.metas[languageCode] = {
                        ...state.metas[languageCode],
                        ...metas
                    }
                }
                else {
                    Vue.set(state.metas, languageCode, metas);
                }
            }
        }
    },
    actions: {
      
    }
  };

  return store;
};



import {localize} from "vee-validate";

import { Module } from 'vuex';


export const types = {
  getters : {
    IS_LANGUAGE_SWITCH_ALLOWED : 'IS_LANGUAGE_SWITCH_ALLOWED'
  },
  mutations : {
    SET_AVAILABLE_MODULES : 'SET_AVAILABLE_MODULES',
    SET_IS_LANGUAGE_SWITCH_ALLOWED : 'SET_IS_LANGUAGE_SWITCH_ALLOWED'
  },
  actions : {
    UPDATE_LANGUAGE : 'UPDATE_LANGUAGE'
  }
}

export function createApplicationStore () {

  const applicationStore:Module<any, any> = {
    namespaced: true,
    state: {
      availableModules : [], // These modules are the one available for the logged in user.
      isLanguageSwitchAllowed : false, // True if the user has possibility to switch language dynamically
    },
    getters: {
      /**
       * IS_LANGUAGE_SWITCH_ALLOWED
       * This getter returns if language switch is allowed
       */
      [types.getters.IS_LANGUAGE_SWITCH_ALLOWED] (state):any {
        return state.isLanguageSwitchAllowed;
      }
    },
    mutations: {
      [types.mutations.SET_AVAILABLE_MODULES] (state, modules) {
        // We update the store
        state.availableModules = modules;
      }, 
      [types.mutations.SET_IS_LANGUAGE_SWITCH_ALLOWED] (state, isLanguageSwitchAllowed) {
        // We update the store
        state.isLanguageSwitchAllowed = isLanguageSwitchAllowed;
      }
    },
    actions: {
      [types.actions.UPDATE_LANGUAGE]({state, commit, dispatch}, {languageCode}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("APPLICATION STORE - UPDATE LANGUAGE - language:"+languageCode);
        }
        // We localize VeeValidate
        localize(languageCode);
      }
    }
    
  }
  return applicationStore;
};
import Vue, {VueConstructor} from "vue";
import { Store } from 'vuex';
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";

/**
 * hooks.ts
 * This script is used to apply custom code based on hooks available within the generic code of the framework.
 * This file is to be added to each site with the customized extended hooks handler.
 */
export module hooks {
  /**
   * onCreateAppEnter
   * This method is called first when we enter the createApp method of the client.ts
   * @param Vue 
   * @param initialState 
   */
  export function onCreateAppEnter (Vue:VueConstructor<Vue>, initialState:any) {}

  /**
   * onVueDependenciesCreated
   * This method is called when all dependencies are created (store, router, i18n), before creating the Vue instance.
   * This method is called in client.ts.
   * @param store 
   * @param router 
   * @param i18n 
   * @param initialState 
   */
  export function onVueDependenciesCreated (store: Store<any>, router: VueRouter, i18n: VueI18n, initialState: any) {}

  /**
   * onStoreCreated
   * This method is called when the store is created within store/index.ts
   * @param store - the store instance just created.
   * @param initialState - the initial state of the store if available.
   */
  export function onStoreCreated (store: Store<any>, initialState:any) {}
}




















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})

export default class RepresentativeCountryPicker extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  representativeCountry!: any;

  @Prop({
    type: Array,
    required: true,
    default: []
  })
  representativeCountries!: Array<any>;

  @Prop({
    type: Array,
    required: true,
    default: []
  })
  readonly selectedRepresentativeCountriesCode!: Array<any>;

  @Prop({
    type: Boolean,
    required: true,
    default: false
  })
  readonly showNumberOfWorkers?: boolean;

  @Prop({
    type: Number,
    required: false,
  })
  readonly maxIndividualOfWorkers?: number;

  @Prop({
    type: Object,
    required: true
  })
  readonly businessIntroducer?: any;

  get availableListRepresentativeCountries() {
    return this.representativeCountries.filter((c:any) => !this.selectedRepresentativeCountriesCode.includes(c.code) || c.code === this.representativeCountry.object.code)
  }

  selectedCountryPackages(countryCode: string) {
    var country = this.representativeCountries.find(
      repCountry => repCountry.code === countryCode
    );

    if (!country || !country.workerPackages) {
      return [];
    }

    if (this.businessIntroducer !== null && this.businessIntroducer.workerPackages) {
      return this.businessIntroducer.workerPackages;
    }

    return country.workerPackages;
  }

  countryPackageSignature(countryPackage:any) {
    return countryPackage.fixedAmount ? 'f|' + countryPackage.fixedAmount : 'w|' + countryPackage.perWorkerAmount
  }

  resetPackage(representativeCountry:any) {
    var country = this.representativeCountries.find(
      repCountry => repCountry.code === representativeCountry.object.code
    );

    if (!country.workerPackages) {
      representativeCountry.package = null;
    } else {
      var firstPackage = this.selectedCountryPackages(
        representativeCountry.object.code
      )[0];
      representativeCountry.package = this.countryPackageSignature(
        firstPackage
      );
    }
  }
}










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import IFrameWithForm from '../../../panels/IFrameWithForm.vue';
import * as api from '@client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components : {
    IFrameWithForm
  }
})
export default class Payment extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();

  paymentRedirectionForm = this.$store.state.eurofiscalis.workers.register.payment;

  beforeCreate() {
    if(!this.$store.state.eurofiscalis.workers.register.payment.action) {
      // We need to redirect to register.
      this.$router.push("./register");
    }
  }

  mounted() {
    
  }

  onRegisterFormSubmit(evt:Event) {
    
  }
  
}

























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import Messages from '../panels/Messages.vue';
import * as api from '@client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery';

@Component({
  components: {}
})
export default class Info extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly company!: any

  created() {}

  mounted() {
    enableIbox(this.$refs.companyInfo as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.companyInfo as HTMLElement);
  }

}

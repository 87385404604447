import Vue from 'vue';

import { getAPI, ApiOptions } from '@client/utils/api';

/**
 * enableKeepAlive
 * The goal of this method is to enable keep alive calls to check that session remains available while the user has page opened.
 */
export function enableKeepAlive(app:Vue, state:any) {

    // We get the ttl of the session within the initial state
    if(state.server && state.server.sessionTtl) {
        // We will do the check 30 seconds before the end of session.
        var sessionTtl = (state.server.sessionTtl * 1000) - 30000;

        const callKeepAlive = function() {
            setTimeout(function() {
                var options:ApiOptions =  {
                    app: app,
                    localized: false
                }
                getAPI('/session/keepAlive', options).then((response:any) => {
                    if(response.alive) {
                        // We trigger the new check.
                        callKeepAlive();
                        if(!response.logged && app.$store.state.authentication.isLoggedIn) {
                            // The backend is not logged anymore.
                            window.location.reload();
                        }
                    }
                    else {
                        // There is an issue with keep alive. We redirect to home page.
                        window.location.reload();
                    }
                });
               
            }, sessionTtl);
        }

        // We initialise the first check
        callKeepAlive();
    }
}






































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 
import ConditionsModal from './ConditionsModal.vue';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis';

@Component({
  components : {
    ConditionsModal
  }
})
export default class Contract extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly contract!: any

  get representativeCountries() {
    return this.$t("COUNTRIES."+ this.contract.representativeCountry.code);
  }

  get declaredWorkers() {
    if(this.numberOfWorkers != null) {
      if(this.contract.allowedNumberOfWorkers == "Infinity") {
        return this.numberOfWorkers;
      }
      else {
        return this.numberOfWorkers + "/" + this.contract.allowedNumberOfWorkers;
      }
    }
    return "";
  }

  get numberOfWorkers() {
    return this.$store.getters['eurofiscalis/' + eurofiscalisTypes.getters.GET_NUMBER_OF_WORKERS];
  }

  showConditionsModal() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }

  getTermsOfSalesDocumentURL() {
    var url =
      "/api/representativeCountries/" +
      this.contract.representativeCountry._id +
      "/termsOfSales/" +
      this.$store.state.languages.currentLanguageCode +
      "/download";
    return url;
  }
}


/**
 * setCookie helper
 * https://github.com/cmp-cc/vue-cookies/blob/master/vue-cookies.js
 * @param key 
 * @param value 
 * @param expireTimes 
 * @param path 
 * @param domain 
 * @param secure 
 */
export function setCookie(key:string, value:string, expireTimes:Number|String|Date, path:string, domain:string, secure:boolean) {
    const defaultPath = "/";
    if (!key) {
        throw new Error("cookie name is not find in first argument");
    } 
    else if (/^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
        throw new Error("cookie key name illegality ,Cannot be set to ['expires','max-age','path','domain','secure']\t current key name: "+key);
    }
    // support json object
    if(value && value.constructor === Object ) {
        value = JSON.stringify(value);
    }
    var _expires = "; max-age=86400"; // temp value, default expire time for 1 day
    if (expireTimes) {
        switch (expireTimes.constructor) {
            case Number:
                if(expireTimes === Infinity || expireTimes === -1) _expires = "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                else _expires = "; max-age=" + expireTimes;
                break;
            case String:
                if (/^(?:\d{1,}(y|m|d|h|min|s))$/i.test(expireTimes as string)) {
                    // get capture number group
                    var _expireTime = (expireTimes as string).replace(/^(\d{1,})(?:y|m|d|h|min|s)$/i, "$1");
                    // get capture type group , to lower case
                    switch ((expireTimes as string).replace(/^(?:\d{1,})(y|m|d|h|min|s)$/i, "$1").toLowerCase()) {
                        // Frequency sorting
                        case 'm':  _expires = "; max-age=" + +_expireTime * 2592000; break; // 60 * 60 * 24 * 30
                        case 'd':  _expires = "; max-age=" + +_expireTime * 86400; break; // 60 * 60 * 24
                        case 'h': _expires = "; max-age=" + +_expireTime * 3600; break; // 60 * 60
                        case 'min':  _expires = "; max-age=" + +_expireTime * 60; break; // 60
                        case 's': _expires = "; max-age=" + _expireTime; break;
                        case 'y': _expires = "; max-age=" + +_expireTime * 31104000; break; // 60 * 60 * 24 * 30 * 12
                        default: new Error("unknown exception of 'set operation'");
                    }
                } else {
                    _expires = "; expires=" + expireTimes;
                }
                break;
            case Date:
                _expires = "; expires=" + (expireTimes as Date).toUTCString();
                break;
        }
    }
    document.cookie = encodeURIComponent(key) + "=" + encodeURIComponent(value) + _expires + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : defaultPath) + (secure ? "; secure" : "");
}

export function getBrowserPreferredLanguages() {
    // These window.navigator contain language information
    // 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
    // 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari, 
    //                 Chrome sends Browser UI language
    // 3. browserLanguage -> UI Language of IE
    // 4. userLanguage    -> Language of Windows Regional Options
    // 5. systemLanguage  -> UI Language of Windows
    var browserLanguagePropertyKeys = ['languages', 'language', 'browserLanguage', 'userLanguage', 'systemLanguage'];

    var browserLanguages = [];
    for(let key of browserLanguagePropertyKeys) {
        var languages:any = (window as any).navigator[key];
        if(languages) {
            if(!(languages instanceof Array)) {
                languages = [languages];
            }
            for(let language of languages) {
                browserLanguages.push(language.substr(0, 2));
            }
            break;
        }
    } 
    return browserLanguages;   
};

/**
 * downloadFileFromString
 * This method trigger a browser download of file, with any content provided as string.
 * @param content - the content of the file to download
 * @param fileName - the file name to download
 * @param contentType - the content type of the file
 */
export function downloadFileFromString(content:string|Uint8Array, fileName:string, contentType:string) {
    var blob = new Blob([content], {type : contentType});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
}
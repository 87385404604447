







































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import { formatAmount } from "@client/utils/helper";
import moment from "moment";

@Component({
  components: {}
})
export default class ListCreditNote extends Vue {
  debouncedSearch: Function = () => {};
  listInvoiceLoading: boolean = false;
  sortBy = "number";
  sortDesc = false;

  listInvoiceData: any[] = [];

  validatedDateFrom: string = "";
  validatedDateTo: string = "";

  emptyForm: any = {
    dateFrom: "",
    dateTo: "",
    global: ""
  };

  searchForm: any = {
    ...this.emptyForm
  };

  listInvoiceFields = [
    {
      key: "number",
      label: "",
      sortable: true
    },
    {
      key: "creationDateTime",
      label: "",
      sortable: true,
      sortByFormatted: true,
      formatter: (value: any) => {
        return Date.parse(value);
      }
    },
    {
      key: "customer.name",
      label: "",
      sortable: true
    },
    {
      key: "customer.vatNumber",
      label: "",
      sortable: true
    },
    {
      key: "totalAmountWithoutTax",
      label: "",
      sortable: true
    },
    {
      key: "totalAmountWithTaxes",
      label: "",
      sortable: true
    },
    {
      key: "transaction.paymentReference",
      sortable: true
    },
    {
      key: "transaction.details.method",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: ""
    }
  ];

  @Watch("searchForm", { deep: true })
  onSearchFormChange(val: any) {
    this.debouncedSearch();
  }

  mounted() {
    this.listInvoiceFields[0].label = this.$t(
      "admin_invoices.headers.number"
    ) as string;
    this.listInvoiceFields[1].label = this.$t(
      "admin_invoices.headers.date"
    ) as string;
    this.listInvoiceFields[2].label = this.$t(
      "admin_invoices.headers.name"
    ) as string;
    this.listInvoiceFields[3].label = this.$t(
      "admin_invoices.headers.vatNumber"
    ) as string;
    this.listInvoiceFields[4].label = this.$t(
      "admin_invoices.headers.excludingTaxes"
    ) as string;
    this.listInvoiceFields[5].label = this.$t(
      "admin_invoices.headers.includingTaxes"
    ) as string;
    this.listInvoiceFields[6].label = this.$t(
      "eurofiscalis_workers.list.paymentReference"
    ) as string;
     this.listInvoiceFields[7].label = this.$t(
      "admin_invoices.headers.paymentMethod"
    ) as string;

    this.getListInvoice();

    this.debouncedSearch = debounce(() => {
      if (!moment(this.searchForm.dateFrom).isValid())
        this.validatedDateFrom = "";
      else this.validatedDateFrom = this.searchForm.dateFrom;

      if (!moment(this.searchForm.dateTo).isValid()) this.validatedDateTo = "";
      else this.validatedDateTo = this.searchForm.dateTo;

      this.getListInvoice();
    }, 500);
  }

  getListInvoice() {
    var options: api.ApiOptions = {
      app: this
    };

    var input = {
      dateFrom: this.validatedDateFrom,
      dateTo: this.validatedDateTo,
      global: this.searchForm.global
    }

    api.postAPI("/api/admin/invoices/list", input, options).then((response: any) => {
      this.listInvoiceData = response.invoices;
    });
  }

  formatAmount(amount: number, currencyCode: string) {
    return formatAmount(
      this.$store.state.languages.currentLanguageCode,
      currencyCode,
      amount
    );
  }

  formatInvoices() {
    var invoices: any[] = [];
    this.listInvoiceData.forEach(i => {
      invoices.push({
        number: i.number,
        date: i.creationDateTime,
        companyName: i.customer.name,
        vatNumber: i.customer.vatNumber,
        amountExcludingTaxes: i.totalAmountWithoutTax,
        amountIncludingTaxes: i.totalAmountWithTaxes,
        paymentmethod: i.transaction.details.method
      });
    });
    return invoices;
  }

  exportInvoices() {
    var invoices = this.formatInvoices();
    var book = utils.book_new();
    var sheet = utils.json_to_sheet(invoices);
    utils.book_append_sheet(book, sheet);
    writeFile(book, "invoices.xlsx");
  }
  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  getDownloadURL(invoice:any) {
    return '/'+ this.currentLanguage.code +'/api/invoices/'+invoice._id+'/download';
  }

  resetFilter() {
    this.searchForm = {
      ...this.emptyForm
    }
  }
}

export const positions = ["MANAGER","PRESIDENT","GENERAL_DIRECTOR","FINANCIAL_DIRECTOR","COMMERCIAL_DIRECTOR","EXPORT_MANAGER","COMMUNICATION_DIRECTOR","ACCOUNTING_MANAGER","ACCOUNTANT","ACCOUNTING_ASSISTANT","ASSISTANT_DIRECTOR","COMMERCIAL","OWNER"];

export const contractDurationInMonth = 6;

export const currencyCode = "EUR"

export const nbOfEmployees = ["10","50","100","500","more"];

export const companySources = ["WORKER","EUROFISCALIS"];

export enum PhoneTypes {
  HOME = "HOME",
  MOBILE = "MOBILE",
  BUSINESS = "BUSINESS"
}

export enum PaymentMethod {
  CARD = "CARD",
  TRANSFER = "TRANSFER"
}

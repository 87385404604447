

































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import PageTitle from '../panels/PageTitle.vue';
import Contracts from '../panels/company/Contracts.vue';
import * as api from '@client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../theme/inspinia-jquery';

@Component({
  components: {
    PageTitle,
    Contracts,
    CompanyInfo : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/Info.vue'),
    FaqContent : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/FaqContent.vue'),
  }
})
export default class Company extends mixins(GenericPage) {
  workerContracts:any[]=[]
  workerRequests:any[]=[]
  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('company.title')
    }]
  }

  company:any = null;

  created() {
    this.initLoad();
  }

  mounted() {
    enableIbox(this.$refs.companyContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.companyContent as HTMLElement);
  }

  initLoad() {
    this.loadCompany(this.$route.params.id);
    this.loadWorkerContracts(this.$route.params.id);
    this.loadWorkerRequests(this.$route.params.id);
  }

  loadCompany(companyId:any) {
    // We check if we have information from user profile
    if(this.$store.state.authentication.isLoggedIn &&
        this.$store.state.authentication.user.profile &&
        this.$store.state.authentication.user.profile.company &&
        (companyId == undefined || this.$store.state.authentication.user.profile.company._id == companyId)) {
      var company =  this.$store.state.authentication.user.profile.company;
      this.company = {...company}
      return;
    }
    // We load the company from backend
    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI('/api/eurofiscalis/company/'+companyId, options).then((response:any) => {
      if(response.company) {
        this.company = response.company;
      }
    })
  }

  loadWorkerContracts(companyId:any) {
    // We check if we have information from user profile
    if(this.$store.state.authentication.isLoggedIn &&
        this.$store.state.authentication.user.profile &&
        this.$store.state.authentication.user.profile.company &&
        this.$store.state.authentication.user.profile.company._id == companyId &&
        this.$store.state.eurofiscalis.workers.contracts.length > 0)
    {
      this.workerContracts = [...this.$store.state.eurofiscalis.workers.contracts]
      return;
    }

    // We load the company from backend
    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI('/api/eurofiscalis/company/'+companyId+'/contracts', options).then((response:any) => {
      if(response.workerContracts) {
        this.workerContracts = response.workerContracts;
      }
    })
  }

  loadWorkerRequests(companyId:any) {
    // We check if we have information from user profile
    if(this.$store.state.authentication.isLoggedIn &&
        this.$store.state.authentication.user.profile &&
        this.$store.state.authentication.user.profile.company &&
        this.$store.state.authentication.user.profile.company._id == companyId &&
        this.$store.state.eurofiscalis.workers.workerRequests.length > 0)
    {
      this.workerRequests = [...this.$store.state.eurofiscalis.workers.workerRequests]
      return;
    }

    // We load the company from backend
    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI('/api/eurofiscalis/company/'+companyId+'/workerRequests', options).then((response:any) => {
      if(response.workerRequests) {
        this.workerRequests = response.workerRequests;
      }
    })
  }

  onMigrateToMobilityPacket(companyID:any) {
    var options:api.ApiOptions =  {
      app: this
    }

    api.postAPI(
      '/api/eurofiscalis/company/' + companyID + '/migrateToMobilityPacket',
      {},
      options
    ).then((response:any) => {
      this.initLoad();
      window.location.reload();
    });
  }
}

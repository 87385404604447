


















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { mixins } from 'vue-class-component';
import { write, utils, writeFile } from 'xlsx'
import * as api from '@client/utils/api';
import { debounce } from 'ts-debounce';
import { languagesTypes } from '@client/store/types';
import UpdateProfile from './UpdateProfile.vue';

@Component({
  components: { 
    UpdateProfile
  }
})
export default class ListProfiles extends Vue {

  debouncedSearch:Function = ()=>{};
  currentProfile = null
  isMultiSelectAllowed = false;
  listProfilesLoading:boolean = false;
  profiles = [];
  allProfiles = [];
  search="";
  sortBy= 'company.name';
  sortDesc= false;

  listProfilesFields = [
      {
        key: "company.name",
        label: "",
        sortable: true
      },
      {
        key: "company.vatNumber",
        label: "",
        sortable: true
      },
      {
        key: "civility",
        label: "",
        sortable: true
      },
            {
        key: "lastName",
        label: "",
        sortable: true
      },
      {
        key: "firstName",
        label: "",
        sortable: true
      },
      {
        key: "options"
      }
    ];

  mounted() {
    this.debouncedSearch = debounce(() => {
      this.profiles = this.allProfiles.filter((p:any) => {
        return p.company.name.toLowerCase().includes(this.search.toLowerCase()) || p.firstName.toLowerCase().includes(this.search.toLowerCase()) || p.lastName.toLowerCase().includes(this.search.toLowerCase())
      })
    },250);
    this.listProfilesFields[0].label = this.$t('company.info.name') as string;
    this.listProfilesFields[1].label = this.$t('company.info.vatNumber') as string;
    this.listProfilesFields[2].label = this.$t('profile.info.civility') as string;
    this.listProfilesFields[3].label = this.$t('profile.info.lastName') as string;
    this.listProfilesFields[4].label = this.$t('profile.info.firstName') as string;
    this.listProfilesLoading = true
    this.getProfiles()
  }

  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  get listProfiles() {
    return this.profiles
  }

  @Watch('search')
  onSearchChange() {
    this.debouncedSearch();   
  }

  showProfilePage(profile:any) {
    this.currentProfile = profile
    // @ts-ignore
    this.$refs.updateProfileModal.show()
  }

  formatProfiles() {
    var profiles:any[] = [];
    this.profiles.forEach((p:any) => {
      profiles.push({
        companyName: p.company.name,
        vatNumber : p.company.vatNumber,
        civility: p.civility,
        lastName: p.lastName,
        firstName: p.firstName
      })
    })
    return profiles;
  }

  exportProfiles() {
    var profiles = this.formatProfiles();
    var book = utils.book_new();
    var sheet = utils.json_to_sheet(profiles)
    utils.book_append_sheet(book,sheet);
    writeFile(book, "users.xlsx")
  }

  getProfiles() {
    var options:api.ApiOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/profiles/list', options).then((response:any) => {
      if(response.options) {
        this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
      }
      if(response.profiles) {
        this.profiles = response.profiles;
        this.allProfiles = response.profiles;
      }
      else {
        this.profiles = [];
      }
      this.listProfilesLoading = false
    })
  }

  onProfileUpdated() {
    this.listProfilesLoading = true
    this.search="";
    this.getProfiles();
        // @ts-ignore
    this.$refs.updateProfileModal.hide()
  }
}
















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Date from '../../../panels/input/Date.vue';
import TextField from '../../../panels/input/TextField.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';
import moment from 'moment'

@Component({
  components: { 
    Date,
    TextField,
  }
})
export default class Create extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly InvoiceId!: string

  emptyForm:any = {
    date: moment().format('YYYY-MM-DD'),
    amount: '',
  }

  form:any = {
    ...this.emptyForm
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-creditnote] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "date" : this.form.date,
      "amount" : this.form.amount,
      
      "InvoiceId" : this.InvoiceId
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/creditnotes/invoice/'+this.InvoiceId+'/creditnote/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('creditnote-created', response.creditnote);

        // We reset the fields
        this.form = {
          ...this.emptyForm
        }
      }
      this.laddaSubmit!.stop();
    });
  }
  
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-t"},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"text-left",attrs:{"id":"login-form","name":"login-form","role":"form","action":"#","method":"post"},on:{"submit":_vm.onLoginFormSubmit}},[_c('validation-provider',{attrs:{"name":"login","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.login),expression:"loginForm.login"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('profile.info.login_placeholder'),"required":"","autocomplete":"on","name":"login"},domProps:{"value":(_vm.loginForm.login)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "login", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"password","mode":"aggressive","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.password),expression:"loginForm.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":_vm.$t('profile.info.password_placeholder'),"required":"","autocomplete":"off"},domProps:{"value":(_vm.loginForm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "password", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary block full-width m-b ladda-button",attrs:{"disabled":invalid,"type":"submit","data-style":"zoom-in"}},[_vm._v(_vm._s(_vm.$t('profile.info.login-button')))]),_vm._v(" "),(_vm.isForgotPasswordAllowed)?_c('p',{staticClass:"text-center"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.onResetPasswordClick}},[_c('small',[_vm._v(_vm._s(_vm.$t('profile.info.forgotPassword')))])])]):_vm._e(),_vm._v(" "),_c('b-modal',{ref:"resetPasswordModal",attrs:{"size":"lg","title":_vm.$t('profile.reset-password.title'),"hide-footer":"","lazy":""}},[_c('ResetPassword',{on:{"password-reseted":_vm.onPasswordReseted}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }

























































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import moment from 'moment'

@Component({
  components: {}
})
export default class ListRequests extends Vue {
  debouncedSearch: Function = () => {};
  isRemoveAllowed = false;
  isValidationAllowed = false;
  isMultiSelectAllowed = false;
  sortBy = "company.name";
  sortDesc = false;

  searchForm = {
    promoCode: "",
    filterUpgradeRequest: false,
    representativeCountries: []
  };

  listRepresentativeCountries: any[] = [];
  listBusinessIntroducers: any[] = [];

  emptyRequest = {
    company: {
      name: ""
    }
  };

  requestToRemove: any = { ...this.emptyRequest };
  requestToValidate: any = { ...this.emptyRequest };
  requestValidationStartDates = [];

  listRequestsLoading: boolean = false;
  listRequestsSelected = [];
  listRequestsData: any[] = [];
  listRequestsFields = [
    {
      key: "select",
      label: "",
      class: this.isMultiSelectAllowed ? "" : "hidden"
    },
    {
      key: "company.name",
      sortable: true
    },
    {
      key: "creationDateTime",
      sortable: true
    },
    {
      key: "company.vatNumber",
      sortable: true
    },
    {
      key: "user.login",
      sortable: true
    },
    {
      key: "user.profile.phones[0].number",
      label: "Phone",
      sortable: true
    },
    {
      key: "contracts",
      sortable: false
    },
    {
      key: "promoCode",
      sortable: true
    },
    {
      key: "transaction.amount",
      sortable: true
    },
    {
      key: "transaction.paymentReference",
      sortable: true
    },
    {
      key: "mop",
      sortable: true
    },
    {
      key: "isRenewalRequest",
      sortable: true
    },
    {
      key: "options"
    }
  ];

  mounted() {
    this.updateListRepresentativeCountries();
    this.updateListBusinessIntroducers();
    this.updateListRequests();
    this.setlistRequestsFields();
    this.debouncedSearch = debounce(this.updateListRequests, 400);
  }

  setlistRequestsFields() {
    this.listRequestsFields[1].label = this.$t(
      "eurofiscalis_workers.list.name"
    ) as string;
    this.listRequestsFields[2].label = this.$t(
      "eurofiscalis_workers.list.creation-date"
    ) as string;
    this.listRequestsFields[3].label = this.$t(
      "company.headers.vatNumber"
    ) as string;
    this.listRequestsFields[4].label = this.$t(
      "eurofiscalis_workers.list.email"
    ) as string;
    this.listRequestsFields[5].label = this.$t(
      "eurofiscalis_workers.list.phone"
    ) as string;
    this.listRequestsFields[6].label = this.$t(
      "eurofiscalis_workers.list.countriescode"
    ) as string;
    this.listRequestsFields[7].label = this.$t(
      "eurofiscalis_workers.list.promoCode"
    ) as string;
    this.listRequestsFields[8].label = this.$t(
      "eurofiscalis_workers.list.transactionAmount"
    ) as string;
    this.listRequestsFields[9].label = this.$t(
      "eurofiscalis_workers.list.paymentReference"
    ) as string;
    this.listRequestsFields[10].label = this.$t(
      "Metoda płatności"
    ) as string;
    this.listRequestsFields[11].label = this.$t(
      "eurofiscalis_workers.list.type"
    ) as string;
    this.listRequestsFields[12].label = this.$t(
      "eurofiscalis_workers.list.options"
    ) as string;
  }

  get currentLanguage() {
    return this.$store.getters[
      "languages/" + languagesTypes.getters.GET_CURRENT_LANGUAGE
    ];
  }

  @Watch("$store.state.languages.currentLanguageCode")
  onLanguageChange(to: any, from: any) {
    this.setlistRequestsFields();
  }

  @Watch("searchForm", { deep: true })
  onSearchFormChange(val: any) {
    this.debouncedSearch();
  }

  onRequestRemoved() {
    this.updateListRequests();
    // @ts-ignore
    this.$refs.listRequests.refresh();
  }

  confirmRemoveRequest(request: any) {
    this.requestToRemove = request;
    // @ts-ignore
    this.$refs.removeRequestModal.show();
  }

  onRequestValidated() {
    this.$emit("worker-request-validated");
    this.updateListRequests();
    // @ts-ignore
    this.$refs.listRequests.refresh();
  }

  confirmValidateRequest(request: any) {
    this.requestValidationStartDates = request.contracts.map((c:any) => {
      return {
        countryCode: c.representativeCountry.code.toUpperCase(),
        startDate: new Date(c.startDate)
      };
    });
    this.requestToValidate = request;
    // @ts-ignore
    this.$refs.validateRequestModal.show();
  }

  formatRequestsData() {
    var requests: any[] = [];
    this.listRequestsData.forEach(r => {
      requests.push({
        companyName: r.company.name,
        vatNumber: r.company.vatNumber,
        userLogin: r.user.login,
        userPhone: r.user.profile.phones ? r.user.profile.phones[0].number : "",
        contracts: r.contracts ? r.contracts.map((c:any) => c.representativeCountry.code.toUpperCase() + " : " + c.allowedNumberOfWorkers).join(' | ') : "",
        promoCode: r.contracts && r.contracts[0].businessIntroducer ? r.contracts[0].businessIntroducer.code : "",
        transactionAmount: r.transaction.amount,
        isUpgradeRequest: r.isUpgradeRequest
      });
    });
    return requests;
  }

  exportRequests() {
    var requests = this.formatRequestsData();
    var book = utils.book_new();
    var sheet = utils.json_to_sheet(requests);
    utils.book_append_sheet(book, sheet);
    writeFile(book, "requests.xlsx");
  }

  momentDate(date:Date){
    return moment(date).format('DD/MM/YYYY');
  }

  momentDateTime(date:Date){
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  updateListRepresentativeCountries() {
    // We need to get the list of available companies for the current logged in user
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/representativeCountries/list", options)
      .then((response: any) => {
        if (response.countries) {
          this.listRepresentativeCountries = response.countries;
          var _self = this;
          if (
            _self.searchForm.representativeCountries &&
            _self.searchForm.representativeCountries.length > 0
          ) {
            // We get the value from representativeCountries
            var selected = this.listRepresentativeCountries.filter(function(
              representativeCountry
            ) {
              return (
                _self.searchForm.representativeCountries
                  .map((country: any) => {
                    return country.code;
                  })
                  .indexOf(representativeCountry.code) > -1
              );
            });
            if (selected && selected.length > 0) {
              // @ts-ignore
              _self.$refs.listRepresentativeCountries.updateValue(selected);
            }
          }
        }
      });
  }

  updateListBusinessIntroducers() {
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/admin/businessIntroducer/listAvailable", options)
      .then((response: any) => {
        if (response.businessIntroducers) {
          this.listBusinessIntroducers = response.businessIntroducers;
        }
      });
  }
  updateListRequests() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .postAPI("/api/admin/workers/list-requests", this.searchForm, options)
      .then((response: any) => {
        // We check the additional fields
        if (response.options) {
          this.isRemoveAllowed = response.options.isRemoveAllowed;
          this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
          this.isValidationAllowed = response.options.isValidationAllowed;
        }
        if (response.requests) {
          this.listRequestsData = response.requests;
        } else {
          this.listRequestsData = [];
        }
      });
  }

  removeRequest() {
    var input = {
      requestID: this.requestToRemove._id
    };

    var options: api.ApiOptions = {
      app: this
    };

    this.listRequestsLoading = true;

    api
      .postAPI("/api/admin/workers/request/remove", input, options)
      .then((response: any) => {
        if (response.removed) {
          // We update the list of requests
          this.onRequestRemoved();
        }
        // We reset the request to remove
        this.requestToRemove = { ...this.emptyRequest };

        this.listRequestsLoading = false;
      });
  }

  validateRequest() {
    var input = {
      requestID: this.requestToValidate._id,
      startDates: this.requestValidationStartDates
    };

    var options: api.ApiOptions = {
      app: this
    };

    this.listRequestsLoading = true;

    api
      .postAPI("/api/admin/workers/request/validate", input, options)
      .then((response: any) => {
        if (response.validated) {
          // We update the list of requests
          this.onRequestValidated();
        }
        // We reset the request to validate
        this.requestToValidate = { ...this.emptyRequest };

        this.listRequestsLoading = false;
      });
  }
}

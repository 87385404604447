


































































































































































import Vue from 'vue';
import { Component,Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { mixins } from 'vue-class-component';
import { write, utils, writeFile } from 'xlsx'
import { debounce } from 'ts-debounce';
import * as api from '@client/utils/api';
import { languagesTypes } from '@client/store/types';
import DateInput from '../../input/Date.vue';
import moment from 'moment';
import { formatAmount } from "@client/utils/helper";
import { Context } from 'mocha';
import Checkbox from "../../../panels/input/Checkbox.vue";

@Component({
  components: {
    Checkbox
  }
})
export default class listWorkerContracts extends Vue {
  debouncedSearch:Function = ()=>{};

  listWorkerContractsLoading:boolean = false;
  listWorkerContractsSelected = [];
  sortBy = 'company.name';
  sortDesc = false;
  perPage = 50;
  currentPage = 1;
  rows= 0;

  listRepresentativeCountries:any[] = [];
  listBusinessIntroducers: any[] = [];

  searchForm =  {
    promoCode:"",
    representativeCountries:[],
    companyIsActive:"",
    activeDateContractFrom:"",
    activeDateContractTo:"",
    startDateContractFrom:"",
    startDateContractTo:"",
    endDateContractFrom: "",
    endDateContractTo: "",
    excludeExpiredContracts: false,
    companyName:"",
    excludedVatNumbers:"",
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    currentPage:this.currentPage,
    perPage:this.perPage
  }
  listWorkerContractsData:any[]=[];

  listWorkerContractsFields = [
      {
        key: "company.name",
        label: "",
        sortable: true
      },
      {
        key: "company.vatNumber",
        label: "",
        sortable: true
      },
      {
        key: "businessIntroducer.code",
        label: "",
        sortable: true
      },
      {
        key: "representativeCountry.code",
        label: "",
        sortable: true
      },
      {
        key: "allowedNumberOfWorkers",
        label: "",
        sortable: false
      },
      {
        key: "totalAmount",
        label: "",
        sortable: true
      },
      {
        key: "totalBillingWihtoutTax",
        label: "",
        sortable: true
      },
      {
        key: "startDate",
        label: "",
        sortable: true,
        sortByFormatted: true,
        formatter: (value: any) => {
        return Date.parse(value);
        }
      },
      {
        key: "endDate",
        label: "",
        sortable: true,
        sortByFormatted: true,
        formatter: (value: any) => {
        return Date.parse(value);
        }
      },
      {
        key: "email",
        label: "",
        sortable: false
      },
      {
        key:"options",
        label:"",
        sortable:false
      }
    ];

  mounted() {
    this.updateListRepresentativeCountries();
    this.updateListBusinessIntroducers();
    this.debouncedSearch = debounce(() => {
      this.$root.$emit('bv::refresh::table', 'contracts-table');
    }, 500);

    this.listWorkerContractsFields[0].label = this.$t('admin_workerContracts.workerContracts.headers.name') as string;
    this.listWorkerContractsFields[1].label = this.$t('admin_workerContracts.workerContracts.headers.vatNumber') as string;
    this.listWorkerContractsFields[2].label = this.$t('admin_workerContracts.workerContracts.headers.promoCode') as string;
    this.listWorkerContractsFields[3].label = this.$t('admin_workerContracts.workerContracts.headers.representativeCountry') as string;
    this.listWorkerContractsFields[4].label = this.$t('admin_workerContracts.workerContracts.headers.allowedNumberOfWorkers') as string;
    this.listWorkerContractsFields[5].label = this.$t('admin_workerContracts.workerContracts.headers.totalAmount') as string;
    this.listWorkerContractsFields[6].label = this.$t('admin_workerContracts.workerContracts.headers.totalInvoicesAmount') as string;
    this.listWorkerContractsFields[7].label = this.$t('admin_workerContracts.workerContracts.headers.startDate') as string;
    this.listWorkerContractsFields[8].label = this.$t('admin_workerContracts.workerContracts.headers.endDate') as string;
    this.listWorkerContractsFields[9].label = 'E-mail';
  }

  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  resetFilter() {
    this.searchForm =  {
      promoCode:"",
      companyIsActive:"",
      representativeCountries:[],
      activeDateContractFrom:"",
      activeDateContractTo:"",
      startDateContractFrom:"",
      startDateContractTo:"",
      endDateContractFrom: "",
      endDateContractTo: "",
      excludeExpiredContracts: false,
      excludedVatNumbers:"",
      companyName:"",
    }
  }

  formatAmount(amount: number, currencyCode: string) {
    return formatAmount(
      this.$store.state.languages.currentLanguageCode,
      currencyCode,
      amount
    );
  }

  exportWorkerContracts() {
    this.fetchWorkerContracts({
      currentPage: 1, perPage: 9999
    }).then(response => {
      var contracts = response.workerContracts.map(c => {
        return {
          companyName: c.company ? c.company.name : '',
          vatNumber: c.company ? c.company.vatNumber : '',
          businessIntroducerCode : (c.businessIntroducer)?c.businessIntroducer.code:"",
          representativeCountry:c.representativeCountry.code,
          allowedNumberOfWorkers:c.allowedNumberOfWorkers,
          amount : c.totalAmount,
          billingAmount : c.totalBillingWihtoutTax,
          startDate: c.formattedStartDate,
          endDate: c.formattedEndDate,
          email: (c.profiles && c.profiles[0] && c.profiles[0].emails) ? c.profiles[0].emails[0].email : ''
        }
      });

      var book = utils.book_new();
      var sheet = utils.json_to_sheet(contracts)
      utils.book_append_sheet(book,sheet);
      writeFile(book, "contracts.xlsx")
    });
  }

  @Watch('searchForm', { deep: true })
  onSearchFormChange(val:any) {
    this.debouncedSearch();
  }

  workerContractsProvider(ctx:any) {
    return this.fetchWorkerContracts({}).then((response:any) => {
      this.listWorkerContractsLoading=false;

      if(response.workerContracts && response.workerContracts.length > 0) {
          this.rows = response.workerContractsTotal;
          return response.workerContracts;
        }
        else {
          return [];
        }
    });
  }

  fetchWorkerContracts({
    currentPage = this.currentPage, perPage = this.perPage,
    sortBy = this.sortBy, sortDesc = this.sortDesc
  }) {
    var options:api.ApiOptions =  {
      app: this
    }
    var input = Object.assign({}, this.searchForm);
    input['currentPage'] = currentPage;
    input['perPage'] = perPage;
    input['sortBy'] = sortBy;
    input['sortDesc'] = sortDesc;

    return api.postAPI('/api/admin/workerContracts/list', input, options);
  }

   updateListRepresentativeCountries() {
        // We need to get the list of available companies for the current logged in user
        var options:api.ApiOptions =  {
          app: this
        }
        api.getAPI('/api/representativeCountries/list?getAll=true', options).then((response:any) => {
          if(response.countries) {
            this.listRepresentativeCountries = response.countries;
            if(this.searchForm.representativeCountries && this.searchForm.representativeCountries.length > 0) {
              // We get the value from representativeCountries
              var selected = this.listRepresentativeCountries.filter(representativeCountry => {
                return this.searchForm.representativeCountries.map((country:any) => {
                  return country.code;
                }).indexOf(representativeCountry.code) > -1;
              });
              if(selected && selected.length > 0) {
                // @ts-ignore
                this.$refs.listRepresentativeCountries.updateValue(selected);
              }
            }
          }
        });
      }

  updateListBusinessIntroducers() {
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/admin/businessIntroducer/listAvailable", options)
      .then((response: any) => {
        if (response.businessIntroducers) {
          this.listBusinessIntroducers = response.businessIntroducers;
        }
      });
  }

    showCompanyPage(workerContract: any) {
    this.$router.push(
      "/" + this.currentLanguage.code + "/company/" + workerContract.company._id
    );
    }

  }

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Store } from 'vuex';
import { languagesTypes } from '@client/store/types';
import * as api from '@client/utils/api';
import appConfig from '../../../config/application.json';
import { References } from '@client/store/languages.js';


Vue.use(VueI18n);

const languages:any = {
  'fr': {
    code: 'fr',
    label: 'Francais'
  },
  'en': {
    code: 'en',
    label: 'Anglais'
  },
  'pl': {
    code: 'pl',
    label: 'Polonais'
  }
}

/**
 * getAvailableLanguages
 * This method returns the list of available languages based on application.json config file.
 */
export function getAvailableLanguages() {
  var availableLanguages:any = {};
  for(var languageCode of appConfig.default.availableLanguages) {
    availableLanguages[languageCode] = languages[languageCode];
  }
  return availableLanguages;
}

/**
 * createI18n
 * This method create the i18n instance
 * @param languageCode - the initial language code to be set.
 */
export function createI18n (store:Store<any>) {

  // We initialize localication based on the values in the store
  // We have translations after ssr rendering.
  let languageCode = store.state.languages.currentLanguageCode;
  let translations = store.state.languages.translations;

  var vuei18n = new VueI18n({
      locale: languageCode, // set locale
      messages: {
        ...translations
      },
      preserveDirectiveContent: true
    });

  for(var availableLanguageCode of appConfig.default.availableLanguages) {
    if(availableLanguageCode != languageCode) {
      vuei18n.setLocaleMessage(availableLanguageCode, {});
      vuei18n.locale = availableLanguageCode;
    }
  }

  vuei18n.locale = languageCode;

  return vuei18n;
}

/**
 * loadLanguageBundleAsync
 * This method is called to load the language bundle.
 * @param languageCode
 * @param vm
 */
export function loadLanguageBundleAsync(languageCode:string):Promise<any> {
  if(process.env.CONSOLE == "LOG") {
    console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ")");
  }
  try {
    // you can use fetch or import which ever you want.
    // Just make sure your webpack support import syntax
    // const res = await axios.get(`./src/lang/${payload}.json`)
    return import(/* webpackChunkName: "lang-[request]" */ `../languages/${languageCode}/common.json`)
    .then(msgs => {
      if(process.env.CONSOLE == "LOG") {
        console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ") - DONE");
      }
      return {
        "languageCode" : languageCode,
        "translations" : {...msgs.default}, // We need to copy the value to make sure the result from import is not updated.
        "bundles" : [languagesTypes.COMMON_BUNDLE] // we set the generic common bundle
      };
    });
  }
  catch(e) {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ") - ERROR");
      console.log(e)
    }
    return Promise.reject(e);
  }
}

export function loadRouteLanguageBundlesAsync(languageCode:string, routeBundles:string[]):Promise<any> {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles.join(", ") + ")");
    }
    try {
      var promises = [];
      for(var routeBundle of routeBundles) {
        // We need to use a closure to pass the routeBundle parameter to keep its value within the result.
        (function(bundleName) {
          promises.push(import(/* webpackChunkName: "lang-[request]" */ `../languages/${languageCode}/${bundleName}.json`)
            .then(msgs => {
              // We return the translations with routeBundle to notify that it corresponds to specific route
              return {
                "languageCode" : languageCode,
                "translations" : {
                  [bundleName] : {...msgs.default} // We need to copy the value to make sure the result from import is not updated. We also add prefix to translations with route bundle
                },
                "bundle" : bundleName
              };
            }
          ));
        })(routeBundle);
      }
      return Promise.all(promises).then(results => {
        var resultRouteBundles = []
        var translations = {};
        for(var result of results) {
          resultRouteBundles.push(result.bundle);
          translations = {...result.translations, ...translations};
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles!.join(", ") + ") - DONE");
        }
        return {
          "languageCode" : languageCode,
          "translations" : translations,
          "bundles" : resultRouteBundles
        }
      });
    }
    catch(e) {
      if(process.env.CONSOLE == "LOG") {
        console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles!.join(", ") + ") - ERROR");
        console.log(e)
      }
      return Promise.reject(e);
    }
}

export function loadReferences(languageCode:string, routeReferences:References[], app:Vue):Promise<any> {
  if(process.env.CONSOLE == "LOG") {
    console.log("LANGUAGES - LOAD REFERENCES (" + languageCode + ")");
  }
  var options:api.ApiOptions =  {
    app: app,
    languageCode: languageCode // We force the language code as the new language is not yet set in store.
  }
  var input = {
    references: routeReferences
  }
  return api.postAPI('/api/utils/references', input, options).then((response:any) => {
    if(response.references) {
      return Promise.resolve(response.references);
    }
    else {
      return Promise.resolve({});
    }
  })
  .catch(e => {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD REFERENCES (" + languageCode + ") - ERROR");
      console.log(e)
    }
    return Promise.reject(e);
  })
}




























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery';
import * as api from '@client/utils/api';

@Component({
  components: {}
})
export default class Representative extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly representativeCountry!: any
}



















































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import CreateRepresentativeCountry from "./Create.vue";
import UpdateRepresentativeCountry from "./Update.vue";

@Component({
  components: {
    CreateRepresentativeCountry,
    UpdateRepresentativeCountry
  }
})
export default class ListRepresentativeCountries extends Vue {
  selectedRepresentativeCountry: any = null;
  listRepresentativeCountriesLoading: boolean = false;
  sortBy = "name";
  sortDesc = false;

  listRepresentativeCountriesData: any[] = [];

  listRepresentativeCountriesFields = [
    {
      key: "name",
      label: "",
      sortable: true
    },
    {
      key: "code",
      label: "",
      sortable: true
    },
    {
      key: "unlimitedPackageAmount",
      label: "",
      sortable: true
    },
    {
      key: "workerPackageAmount",
      label: "",
      sortable: true
    },
    {
      key: "termsOfSales",
      label: ""
    },
    {
      key: "workerPackages",
      label: ""
    },
    {
      key: "isActive",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: ""
    }
  ];

  mounted() {
    this.listRepresentativeCountriesFields[0].label = this.$t(
      "admin_settings.representative_countries.headers.name"
    ) as string;
    this.listRepresentativeCountriesFields[1].label = this.$t(
      "admin_settings.representative_countries.headers.code"
    ) as string;
    this.listRepresentativeCountriesFields[2].label = this.$t(
      "admin_settings.representative_countries.headers.unlimitedPackageAmount"
    ) as string;
    this.listRepresentativeCountriesFields[3].label = this.$t(
      "admin_settings.representative_countries.headers.workerPackageAmount"
    ) as string;
    this.listRepresentativeCountriesFields[4].label = this.$t(
      "admin_settings.representative_countries.termsOfSales"
    ) as string;
    this.listRepresentativeCountriesFields[5].label = this.$t(
      "admin_settings.representative_countries.headers.workerPackages"
    ) as string;
    this.listRepresentativeCountriesFields[6].label = this.$t(
      "admin_settings.representative_countries.headers.isActive"
    ) as string;
    this.getListRepresentativeCountries();
  }

  getListRepresentativeCountries() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/admin/representativeCountries/list", options)
      .then((response: any) => {
        this.listRepresentativeCountriesData = response.countries;
      });
  }

  updateRepresentativeCountry(representativeCountry: any) {
    this.selectedRepresentativeCountry = representativeCountry;
    // @ts-ignore
    this.$refs.updateRepresentativeCountryModal.show();
  }

  addRepresentativeCountry() {
    // @ts-ignore
    this.$refs.createRepresentativeCountryModal.show();
  }

  onRepresentativeCountryCreated() {
    // @ts-ignore
    this.$refs.createRepresentativeCountryModal.hide();
    this.getListRepresentativeCountries();
  }

  onRepresentativeCountryUpdated() {
    // @ts-ignore
    this.$refs.updateRepresentativeCountryModal.hide();
    this.getListRepresentativeCountries();
  }

  getTermsOfSalesDocumentURL(termOfSales: any, representativeCountry:any) {
    var url =
      "/api/representativeCountries/" +
      representativeCountry._id +
      "/termsOfSales/" +
      termOfSales.countryCode +
      "/download";
    return url;
  }
}

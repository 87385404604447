





















































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import DateInput from '../../../panels/input/Date.vue';
import TextField from '../../../panels/input/TextField.vue';
import TextArea from '../../../panels/input/TextArea.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';
import moment from 'moment'

@Component({
  components: { 
    DateInput,
    TextField,
    TextArea
  }
})
export default class Update extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly company!: any

  emptyForm:any = {
    businessIntroducer: ''
  }

  form:any = {
    ...this.emptyForm
  };

  listBusinessIntroducers: any[] = [];

  listWorkerContractsData:any[]=[];
  updateDisable = false;

  listWorkerContractsFields = [
      {
        key: "businessIntroducer.code",
        label: ""
      },
      {
        key: "representativeCountry.code",
        label: ""
      },
      {
        key:"allowedNumberOfWorkers",
        label:""
      },
      {
        key: "totalAmount",
        label: ""
      },
      {
        key: "startDate",
        label: ""
       
      },
      {
        key: "endDate",
        label: ""
      }
    ];

  laddaSubmit:Ladda.LaddaButton|null = null;

  updateListActiveContracts()
  {
    var options:api.ApiOptions =  {
      app: this
      }
    var input = {
        "companyId" : this.company._id,
        }
    api.postAPI('/api/admin/workercontracts/listActive', input, options).then((response:any) => {
        if(response.workerContracts) {  
          if(response.workerContracts && response.workerContracts.length>0) {  
            this.listWorkerContractsData= response.workerContracts;
          }
          else {
            this.listWorkerContractsData= [];
          }
        }
        if(response.businessIntroducerIds)
        {
            this.updateListBusinessIntroducers();
        }
        this.updateDisable = response.updateDisable;
     
    });
  }


  mounted() {
    this.updateListActiveContracts();
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-workerContract] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
    this.listWorkerContractsFields[0].label = this.$t('admin_workerContracts.workerContracts.headers.promoCode') as string;
    this.listWorkerContractsFields[1].label = this.$t('admin_workerContracts.workerContracts.headers.representativeCountry') as string;
    this.listWorkerContractsFields[2].label = this.$t('admin_workerContracts.workerContracts.headers.allowedNumberOfWorkers') as string;
    this.listWorkerContractsFields[3].label = this.$t('admin_workerContracts.workerContracts.headers.totalAmount') as string;
    this.listWorkerContractsFields[4].label = this.$t('admin_workerContracts.workerContracts.headers.startDate') as string;
    this.listWorkerContractsFields[5].label = this.$t('admin_workerContracts.workerContracts.headers.endDate') as string;
  }

   updateListBusinessIntroducers() {
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/admin/businessIntroducer/listAvailable", options)
      .then((response: any) => {
        if (response.businessIntroducers) {
          this.listBusinessIntroducers = response.businessIntroducers;
        }
      });
  }
  onFormSubmit(evt:Event) {

    var submitValid = true;
    var input = {};
  
    if(!this.form.businessIntroducer)
      {
        submitValid=false;
      }
    input = {
      "businessIntroducer": this.form.businessIntroducer
    }
    
    // We prevent submit of the page
    evt.preventDefault();

    if(submitValid)
    {
      var options:api.ApiOptions =  {
      app: this
      }

     this.laddaSubmit!.start();
    
      api.postAPI('/api/admin/workercontracts/'+this.company._id+'/updateActive', input, options).then((response:any) => {
        if(response.updated) {  
          this.$emit('workerContract-updated', response.workerContract);

          // We reset the fields
          this.form = {
            ...this.emptyForm
          }
        }
        this.laddaSubmit!.stop();
    });
    }
    
  }
  
}

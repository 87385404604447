
import { PAGE_NOT_FOUND } from '@client/router/utils';
import { RouteConfig } from 'vue-router';
import { utils } from '@client/router/utils';
import { Store } from 'vuex';
import { languagesTypes } from '@client/store/types';
import { References } from '@client/store/languages';

import { routes as authenticationRoutes } from './authentication';
import { routes as eurofiscalisWorkersRoutes } from './eurofiscalis-workers';
import { routes as adminRoutes } from './admin';


/*
 * We use Lazy Loading to have each route loaded asynchronously from the main bundle in the page.
 * Note that to support this syntax with Babel, we need to use syntax-dynamic-import plugin for babel.
 */
const Home = () => import(/* webpackChunkName: "route-home" */ '../components/pages/Home.vue').then(m => m.default);
const Profile = () => import(/* webpackChunkName: "route-profile" */ '../components/pages/Profile.vue').then(m => m.default);
const Faq = () => import(/* webpackChunkName: "route-profile" */ '../components/pages/Faq.vue').then(m => m.default);
const PageNotFound = () => import(/* webpackChunkName: "route-pageNotFound" */ '../components/pages/PageNotFound.vue').then(m => m.default);

const Company = () => import(/* webpackChunkName: "route-module-company" */ '../components/modules/Company.vue').then(m => m.default);

const routes = [
  { path: '/', redirect: '/home' }, // When user target root path, we redirect it to the home path.
  {
    path: '/home',
    component: Home,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: ['home','company','profile','eurofiscalis_workers','invoice','eurofiscalis_company','eurofiscalis_creditnote','creditnote', 'common'],
      languageReferences: [References.COUNTRIES]
    }
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: 'home'
    }
  },
  {
    path: '/faq',
    component: Faq,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: 'profile'
    }
  },
  ...authenticationRoutes,
  ...eurofiscalisWorkersRoutes,
  ...adminRoutes,
  {
    path: '/company/:id?',
    component: Company,
    meta: {
      layout: "Portal",
      languageBundle: ['home','company','profile','eurofiscalis_workers','invoice','eurofiscalis_creditnote','creditnote','eurofiscalis_invoice', 'eurofiscalis_company', 'common'],
      languageReferences: [References.COUNTRIES],
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    // The default route is used to catch all urls redirecting to non existing routes.
    path: '*',
    component: PageNotFound,
    name: PAGE_NOT_FOUND,
    meta: {
      bodyClasses: ['gray-bg']
    }
  }
];


// We export all the routes
export function getRoutes(store:Store<any>):Array<RouteConfig> {
  // We update the routes to add localized version
  const supportedLanguages:string[] = store.getters['languages/' + languagesTypes.getters.GET_AVAILABLE_LANGUAGES]
  return utils.addLocalizedRoutes(routes, supportedLanguages);
}

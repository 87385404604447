
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as api from '@client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Update extends Vue {

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  form = {
    login: this.user.login,
    email: this.user.email,
    currentPassword : '',
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-user] button.ladda-button.update-details' );
    this.laddaSubmit = Ladda.create(updateDetailsButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "login" : this.user.login != this.user.email ? this.form.login : undefined, // We send the login only if it is different from the email
      "email" : this.form.email,
      "currentPassword" : this.form.currentPassword
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/update', input, options).then((response:any) => {
      if(response.updated) {  
        var user = response.user;
        // We emit event as user is updated
        this.$emit('user-updated', user);

        // We update the form based on values returned by backend (in case not updated)
        this.form.login = user.login;
        this.form.email = user.email;
      }

       this.laddaSubmit!.stop();
    });
  }
  
}

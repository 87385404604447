import { createApp } from '@client/client'
import { languagesTypes } from '@client/store/types';
import { getBrowserPreferredLanguages } from '@client/utils/browser';
import { enableKeepAlive } from '@client/utils/session';

const initialState = (window as any).__INITIAL_STATE__;

const { app, router, store } = createApp(initialState);

if(!initialState) {
    // In case we load the application without coming from SSR.
    // set the default language
    store.dispatch('languages/' + languagesTypes.actions.SET_DEFAULT_LANGUAGE, {
        acceptedLanguages: getBrowserPreferredLanguages(),
        app: app
      });
}
else {
    // We load the application coming with SSR so meaining that we have a backend.
    // We enable the keep alive calls to keep a session.
    (enableKeepAlive(app, initialState));
}

// We load the default language bundle (note that the route bundle is loaded via router beforeEach)
let languagePromise:Promise<any> = store.dispatch('languages/' + languagesTypes.actions.CHECK_BUNDLE_LANGUAGE, {
    app: app
});

// We store the vue application as a global object.
(window as any).__VUE_APP__ = app;

router.onReady(() => {
    // This is called after the router initial navigation
    languagePromise.then(() => {
        // We wait the language bundle to be loaded before loading the application
        app.$mount('#app');
    });
});
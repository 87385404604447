








import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import GenericPage from "@client/components/mixins/GenericPage.vue";

@Component({
  components: {
    FaqContent : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/FaqContent.vue'),
  }
})
export default class Faq extends mixins(GenericPage) {
}

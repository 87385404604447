












import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Date extends mixins<GenericInput<Date>>(GenericInput) {

  inputLabel = "";
  inputPlaceholder = "";
  inputId = "date";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : "";
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : "";
    if(this.id) { this.inputId = this.id }
  }
  
}




























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import NumberField from "../../../../panels/input/NumberField.vue";
import Checkbox from "../../../../panels/input/Checkbox.vue";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import moment from "moment";

@Component({
  components: {
    TextField,
    NumberField,
    Checkbox
  }
})
export default class Create extends Vue {
  emptyForm: any = {
    code: "",
    name: "",
    unlimitedPackageAmount: 0,
    workerPackageAmount: null,
    workerPackageMaxNumberOfWorker: null,
    isActive: true
  };

  form: any = {
    ...this.emptyForm
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=form-businessIntroducer] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    var input = {
      name: this.form.name,
      code: this.form.code,
      unlimitedPackageAmount: this.form.unlimitedPackageAmount,
      workerPackageAmount: this.form.workerPackageAmount,
      workerPackageMaxNumberOfWorker: this.form.workerPackageMaxNumberOfWorker,
      isActive: this.form.isActive
    };

    this.laddaSubmit!.start();

    api
      .postAPI("/api/admin/businessIntroducer/create", input, options)
      .then((response: any) => {
        if (response.created) {
          this.$emit("businessIntroducerCreated", response.businessIntroducer);
          this.form = {
            ...this.emptyForm
          };
        }
        this.laddaSubmit!.stop();
      });
  }
}




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListCompanies from '../../panels/admin/companies/ListCompanies.vue';
import ListRequests from '../../panels/admin/eurofiscalis/workers/ListRequests.vue';
import ListProfiles from '../../panels/admin/profiles/ListProfiles.vue';

@Component({
  components: { 
    PageTitle,
    ListCompanies,
    ListRequests,
    ListProfiles
  }
})
export default class Eurofiscalis extends mixins(GenericPage) {
  
}

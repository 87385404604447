
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery';

@Component({})
export default class FaqContent extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly collapse?: boolean

  faqContent = [
    {
      title: '1. Czy oprócz rejestracji i dodaniu pracowników portalu Eurofiscalis muszę dodatkowo zgłosić kierowcę w innym miejscu?',
      text: 'Tak, należy zarejestrować się na europejskiej platformie IMI pod adresem: <a href="https://www.postingdeclaration.eu">https://www.postingdeclaration.eu</a> W dokonywaniu zgłoszenia należy podać dane naszego przedstawiciela oraz przesłać nam zaproszenie do współadministrowania kontem IMI na wypadek kontroli. Zarówno dane przedstawiciela, jak i instrukcja dostępna jest na Twoim koncie w portalu Eurofiscalis.'
    },
    {
      title: '2. Gdzie znajdę instrukcję rejestracji do IMI?',
      text: 'Instrukcję rejestracji i delegowania kierowców w IMI znajdziesz po zalogowaniu do indywidualnego konta klienta na głównej stronie w sekcji „Załączniki”.'
    },
    {
      title: '3. Jak dodać kierowcę w portalu?',
      text: 'Aby dodać kierowcę, po zalogowaniu należy kliknąć w przycisk „Dodaj kierowcę”, następnie wypełnić jego dane oraz zatwierdzić. W instrukcji do portalu Eurofiscalis możesz uzyskać wskazówki, jak tego dokonać.'
    },
    {
      title: '4. Jak dodać dokumenty kierowcy w portalu?',
      text: 'Aby dodać dokumenty kierowcy, należy odnaleźć go na liście pracowników i kliknąć w przycisk „szczegóły” a następnie dodać dokumenty. Uprzednio należy wybrać kategorię danego dokumentu z listy.'
    },
    {
      title: '5. Dlaczego nie mogę dodać dokumentu do kierowcy?',
      text: 'Najprawdopodobniej należy zmienić nazwę pliku, ponieważ poprzednia nazwa figuruje już w systemie. Jeśli to nie rozwiąże problemu należy skierować tę sprawę do zespołu Eurofiscalis na adres e-mail: <a href="mailto:transport@eurofiscalis.com">transport@eurofiscalis.com</a>'
    },
    {
      title: '6. Gdzie mogę pobrać fakturę z portalu?',
      text: 'Faktura do pobrania dostępna jest w portalu po zalogowaniu w sekcji „Lista faktur”. Aby pobrać fakturę, należy kliknąć w symbol niebieskiej chmurki.'
    },
    {
      title: '7. Gdzie są dostępne dane przedstawiciela?',
      text: 'Dane przedstawiciela dostępne są na głównej stronie portalu po zalogowaniu się do swojego indywidualnego konta klienta. Znajdują się one w sekcji „Informacje o przedstawicielu Eurofiscalis”'
    },
    {
      title: '8. Jak zmienić hasło i adres e-mail w portalu?',
      text: 'Aby dokonać zmiany hasła lub adresu e-mail należy zalogować się do swojego portalu, następnie kliknąć w nazwę firmy dostępną w lewym górnym rogu, a następnie kliknąć w „twój profil” i kolejno „Zmiana hasła” lub „zmiana e-mail”. Wskazówki znajdziesz w instrukcji do portalu Eurofiscalis.'
    },
    {
      title: '9. Gdzie mogę sprawdzić datę zakończenia mojej umowy?',
      text: 'Data zakończenia umowy znajduje się w Twoim indywidualnym koncie klienta po zalogowaniu, na głównej stronie, w sekcji „Twoje umowy”.'
    },
    {
      title: '10. Gdzie mogę sprawdzić ilość wykupionych przeze mnie kierowców?',
      text: 'Ilość wykupionych kierowców możesz sprawdzić w sekcji „Twoje umowy” po zalogowaniu się do swojego indywidualnego konta klienta.'
    },
    {
      title: '11. Dlaczego nie mogę dodać większej ilości pracowników?',
      text: 'Najprawdopodobniej jest to błąd przeglądarki. Spróbuj użyć przeglądarki Google Chrome, która jest kompatybilna z portalem Eurofiscalis. Drugim problemem może być fakt, iż wykupiłeś mniejszą ilość usług dla pracowników, niż potrzebujesz. Jeśli chcesz powiększyć swój pakiet, kliknij „Dokup usługi”.  Wskazówki znajdziesz w instrukcji do portalu Eurofiscalis.'
    },
    {
      title: '12. Jak dokupić usługę dla kolejnych pracowników?',
      text: 'Aby dokupić usługę dla kolejnych pracowników należy nacisnąć przycisk „Dokup usługi”, , wybrać formę płatności, zatwierdzić warunki sprzedaży oraz zaakceptować zakup. Wskazówki znajdziesz w instrukcji do portalu Eurofiscalis.'
    },
    {
      title: '13. Jak dokonać przedłużenia umowy?',
      text: 'Aby dokonać przedłużenia umowy, należy kliknąć w przycisk „przedłuż umowę”. Jeśli posiadasz kod promocyjny, należy go wprowadzić. Wprowadź liczbę pracowników, zweryfikuj poprawność danych firmy, które znajdą się na fakturze, wybierz formę płatności, zaakceptuj warunki sprzedaży oraz zatwierdź zakup. Po zakupie dostarcz potwierdzenie zapłaty na adres e-mail: <a href="mailto:transport@eurofiscalis.com">transport@eurofiscalis.com</a>,  co przyspieszy aktywację usługi. Wskazówki znajdziesz w instrukcji do portalu Eurofiscalis.'
    },
    {
      title: '14. Gdzie mogę przeczytać umowę, którą zawarłem podczas rejestracji w portalu Eurofiscalis?',
      text: 'Umowa dostępna jest w portalu Eurofiscalis po zalogowaniu się do swojego indywidualnego konta. Dokument znajduje się pod aktywnym przyciskiem w sekcji „Twoje umowy” pod tytułem „Ogólne warunki sprzedaży”.'
    },
    {
      title: '15. Jeśli mój kierowca został zwolniony, co należy zrobić?',
      text: 'Jeśli Twój kierowca został zwolniony, należy usunąć jego dane z portalu Eurofiscalis (przycisk „usuń” przy danym pracowniku) oraz usunąć jego delegację z portalu IMI.'
    },
    {
      title: '16. Zmieniły się dane mojej firmy. Gdzie mogę dokonać zmiany w portalu Eurofiscalis?',
      text: 'Jeśli dane Twojej firmy uległy zmianie, prosimy o przesłanie nowych na adres: <a href="mailto:transport@eurofiscalis.com">transport@eurofiscalis.com</a>'
    }
  ];

  mounted() {
    enableIbox(this.$refs.faqContainer as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.faqContainer as HTMLElement);
  }
}

import { languagesTypes } from '@client/store/types';
import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import { PositionResult, Position } from 'vue-router/types/router';
import { References } from '@client/store/languages';

export const PAGE_NOT_FOUND:string = "pageNotFound";
export const IS_LOCALIZED_PATH:string = "isLocalizedPath";
export const LOCALIZED_PATH_PREFIX:string = "/:lang";
const LOCALIZED_PATH_REGEX:RegExp = /\/\:lang\(.+\)/gi;

export module utils {

  /**
   * getLocalizedPathPrefix
   * This method provide the prefix to be used for localized routes based on supported languages.
   * @param supportedLanguages - the list of supported languages code.
   */
  export function getLocalizedPathPrefix(supportedLanguages: string[], isOptional:boolean = false) {
    var prefix = LOCALIZED_PATH_PREFIX + '(' + supportedLanguages.join('|') + ')';
    if(isOptional) {
      // We add empty value
      prefix += "?"
    }
    return prefix;
  }

  /**
   * getRouteShortNameFromRouteConfig
   * This method return the route short name.
   * It is based on a RouteConfig with path being the dyanmic path.
   * @param route 
   */
  export function getRouteShortNameFromRouteConfig(route:RouteConfig) {
    var name = route.path;
    // We remove the language parameter from path if any (RouteConfig)
    name = name.replace(LOCALIZED_PATH_REGEX,'');
    // We remove the last /
    name = name.substring(1);
    return name;
  }

  /**
   * getRouteShortNameFromRoute
   * This method return the route short name.
   * It is based on a Route with path being the URL in browser.
   * @param route 
   */
  export function getRouteShortNameFromRoute(route:Route, app:Vue) {
    var name = route.path;
    // We remove the language parameter from path if any (Route)
    const languageCode = app.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE].code;
    name = name.replace('/'+languageCode+'/','/');
    // We remove the last /
    name = name.substring(1);
    return name;
  }

  /**
   * getLanguageBundlesFromRoute
   * This method return the route associated language bundles if any or null.
   * @param route 
   */
  export function getLanguageBundlesFromRoute(route:Route):string[]|null {
    if(!route.meta || !route.meta.languageBundle) {
      return null;
    }
    var bundles = route.meta.languageBundle;
    if(!Array.isArray(bundles)) {
      bundles = [bundles];
    }
    return bundles;
  }

  /**
   * getLanguageReferencesFromRoute
   * This method return the route associated language references if any or null.
   * @param route 
   */
  export function getLanguageReferencesFromRoute(route:Route):References[]|null {
    if(!route.meta || !route.meta.languageReferences) {
      return null;
    }
    var references = route.meta.languageReferences;
    if(!Array.isArray(references)) {
      references = [references];
    }
    return references;
  }

  /**
   * addLocalizedRoutes
   * This method return the routes with additional routes supporting language prefix.
   * @param routes - the routes for which we want to add language prefix support
   */
  export function addLocalizedRoutes(routes: RouteConfig[], supportedLanguages: string[]) {
    var keptRoutes:RouteConfig[] = [];
    
    // We build the pattern with listed supported languages
    var routePatternPrefix = getLocalizedPathPrefix(supportedLanguages);

    const localizedRoutes:RouteConfig[] = routes.filter(function(route:RouteConfig, index, array) {
      // We exclude the PAGE NOT FOUND generic route from the localized list.
      if(route.name && route.name == PAGE_NOT_FOUND) {
        // We add the PAGE NOT FOUND generic route to the list of kept routes.
        keptRoutes.push({...route});
        return false;
      }
      return true;
    }).map(function(route:RouteConfig, index, array) {
      var localizedRoute = {...route};
      
      // We update the path to add the language prefix
      localizedRoute.path = routePatternPrefix + localizedRoute.path;

      // We check if the route is a redirect
      if(localizedRoute.redirect) {
        // We update the redirect to add the language prefix
        if(typeof localizedRoute.redirect == 'string') {
          localizedRoute.redirect = routePatternPrefix + localizedRoute.redirect;
        }
      }
      
      // We add specific meta to be able to identify them.
      if(!localizedRoute.meta) {
        localizedRoute.meta = {};
      }
      else {
        localizedRoute.meta = {...localizedRoute.meta};
      }
      localizedRoute.meta[IS_LOCALIZED_PATH] = true;
      return localizedRoute;
    });

    const mergedRoutes:RouteConfig[] = [
      ...localizedRoutes,
      // We do not keep the routes at root level as we have specific redirection
      // ...routes
      ...keptRoutes
    ];

    return mergedRoutes;
  }

  /**
   * @name scrollBehavior
   * @description This method handle the scroll behavior when switching between routes.
   * @param to the destination route.
   * @param from the previous route.
   * @param savedPosition the saved position if destination route was already in history.
   * @param router the VueRouter instance.
   */
  export function scrollBehavior (to:Route, from:Route, savedPosition: void | Position, router:VueRouter) : PositionResult | Promise<PositionResult> {
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition
    } else {
  
      // scroll to anchor by returning the selector
      if (to.hash) {
        let position:PositionResult = {
          selector: to.hash,
          offset: undefined
        }
  
        // specify offset of the element
        if (to.hash === '#anchor2') {
          position.offset = { x:0, y: 100 }
        }
  
        if (document.querySelector(to.hash)) {
          return position
        }
  
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return;
      }
  
      return new Promise(resolve => {
        let position = {} as Position;
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some(m => m.meta.scrollToTop)) {
          // coords will be used if no selector is provided,
          // or if the selector didn't match any element.
          position.x = 0;
          position.y = 0;
        }
  
        // wait for the out transition to complete (if necessary)
        // This event is emitted once the transition is done on App.vue afterLeave.
        router.app.$root.$once('triggerScroll', () => {
          // if the resolved position is falsy or an empty object,
          // will retain current scroll position.
          resolve(position)
        })
      })
    }
  }
}

















































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import { types as eurofiscalisTypes } from "../../../../store/eurofiscalis";
import Checkbox from "../../../panels/input/Checkbox.vue";
import RepresentativeCountryPicker from "../../input/RepresentativeCountry.vue";
import { PaymentMethod } from "../../../../types/common";

@Component({
  components: {
    RepresentativeCountryPicker,
    Checkbox
  }
})
export default class Create extends Vue {
  @Prop({
    type: String,
    required: true
  })
  readonly companyId!: string;

  @Prop({
    type: Array,
    required: true
  })
  readonly workerContracts!: any[];

  representativeCountries: any[] = [];
  allowedMOPs: string[] = [];

  form: any = {
    representativeCountries: [],
    conditions: false,
    mop: PaymentMethod.CARD
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  availableListRepresentativeCountries(representativeCountry: any) {
    return this.representativeCountries.filter(
      (c: any) =>
        !this.selectedRepresentativeCountriesCode.includes(c.code) ||
        c.code === representativeCountry.code
    );
  }

  get selectedRepresentativeCountriesCode() {
    return this.form.representativeCountries.map((c: any) => c.object.code);
  }

  get canAddRepresentativeCountry() {
    return (
      this.form.representativeCountries.length <
      this.representativeCountries.length
    );
  }

  mounted() {
    this.updateListAllowedMops();
    this.getRepresentativeCountries();

    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=upgrade-account] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }



  numberOfWorkersAllowed(country: any) {
    return country.allowedNumberOfWorkers;
  }

  canDeleteRepresentativeCountry(country: any) {
    return country.isNew;
  }

  addRepresentativeCountry() {
    if (this.canAddRepresentativeCountry) {
      this.form.representativeCountries.push({
        numberOfWorkers: this.workerContracts[0].businessIntroducer ? null : "more",
        allowedNumberOfWorkers: 0,
        object: {},
        businessIntroducer: this.workerContracts[0].businessIntroducer,
        isNew: true
      });
    }
  }

  deleteRepresentativeCountry(index: any) {
    if (
      this.canDeleteRepresentativeCountry(
        this.form.representativeCountries[index]
      )
    ) {
      this.form.representativeCountries.splice(index, 1);
    }
  }

  showNumberOfWorkers(country: any) {
    return country.businessIntroducer
      ? country.businessIntroducer.workerPackageAmount !== undefined
      : false;
  }

  maxNumberOfWorker(country: any) {
    return country.businessIntroducer
      ? country.businessIntroducer.workerPackageMaxNumberOfWorker !== undefined
        ? country.businessIntroducer.workerPackageMaxNumberOfWorker
        : 0
      : 0;
  }

  selectedCountryPackages(countryCode: string, businessIntroducer:any) {
    var country = this.representativeCountries.find(
      repCountry => repCountry.code === countryCode
    );

    if (!country || !country.workerPackages) {
      return [];
    }

    if (businessIntroducer !== undefined && businessIntroducer !== null && businessIntroducer.workerPackages) {
      return businessIntroducer.workerPackages;
    }

    return country.workerPackages;
  }

  countryPackageSignature(countryPackage:any) {
    return countryPackage.fixedAmount ? 'f|' + countryPackage.fixedAmount : 'w|' + countryPackage.perWorkerAmount
  }

  minNumberOfWorkerForCountry(countryCode: string) {
    if(this.$store.state.authentication.user.roles.indexOf('eurofiscalis-workers-admin') !== -1) {
      return 1;
    }

    var latestContractForCountry = this.latestContractForCountry(countryCode);
    return (latestContractForCountry && latestContractForCountry.allowedNumberOfWorkers) || 0;
  }

  updateListAllowedMops() {
    // We need to get the list of available companies for the current logged in user
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/eurofiscalis/workers/listAllowedMops", options)
      .then((response: any) => {
        if (response.mops) {
          this.allowedMOPs = response.mops;
        }
      });
  }

  fillFormWithDefaults() {
    this.workerContracts.forEach((w: any) => {
      this.form.representativeCountries.push({
        object: w.representativeCountry,
        numberOfWorkers:
          w.allowedNumberOfWorkers === "Infinity"
            ? "more"
            : w.allowedNumberOfWorkers,
        allowedNumberOfWorkers:
          w.allowedNumberOfWorkers === "Infinity"
            ? "more"
            : w.allowedNumberOfWorkers,
        businessIntroducer: w.businessIntroducer,
        workerContract_id: w._id,
        package: this.selectDefaultPackage(w)
      });
    });
  }

  selectDefaultPackage(workerContract:any) {
    var packages = this.selectedCountryPackages(
      workerContract.representativeCountry.code,
      workerContract.businessIntroducer
    );
    if (packages.length === 0) {
      return '';
    }
    var defaultPackage;

    if (workerContract.allowedNumberOfWorkers === 'Infinity') {
      defaultPackage = packages.at(-1);
    } else {
      defaultPackage = packages.find((r:any) => {
        if(r.perWorkerAmount && workerContract.allowedNumberOfWorkers <= r.maxWorkers) {
          return true;
        }

        if (r.minWorkers > workerContract.allowedNumberOfWorkersers) {
          return true;
        }

        return false;
      });
    }

    return this.countryPackageSignature(defaultPackage);
  }

  getRepresentativeCountries() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/representativeCountries/list", options)
      .then((response: any) => {
        if (response.countries) {
          this.representativeCountries = response.countries;
          this.fillFormWithDefaults();
        }
      });
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    this.laddaSubmit!.start();

    api
      .postAPI(
        "/api/eurofiscalis/workers/company/" +
          this.companyId +
          "/upgradeAccount",
        this.form,
        options
      )
      .then((response: any) => {
        if (response.paymentRedirectionForm) {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            response.paymentRedirectionForm
          );
          // We switch to the payment page
          this.$router.push("/workers/payment");
        } else if (response.mop && response.mop == "TRANSFER") {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            {
              mop: response.mop,
              requestId: response.requestId
            }
          );
          // We switch to the bank-transfer page
          this.$router.push("/workers/bank-transfer");
        }

        this.laddaSubmit!.stop();
      });
  }

  getTermsOfSalesDocumentURL(representativeCountry: any) {
    var url =
      "/api/representativeCountries/" +
      representativeCountry._id +
      "/termsOfSales/" +
      this.$store.state.languages.currentLanguageCode +
      "/download";
    return url;
  }
}

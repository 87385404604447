
































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import PageTitle from '../panels/PageTitle.vue';
import UpdateUser from '../panels/authentication/UpdateUser.vue';
import UpdatePassword from '../panels/authentication/UpdatePassword.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes, authenticationTypes } from '@client/store/types';
import * as Ladda from 'ladda';

import { positions } from '../../types/common';

@Component({
  components: {
    PageTitle,
    UpdateUser,
    UpdatePassword
  }
})
export default class Profile extends mixins(GenericPage) {

  isUpdateUserAllowed:boolean = true;
  isUpdatePasswordAllowed:boolean = true;

  positions = positions;

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  get profile() {
    return this.user.profile;
  }
  
  form = {
    firstName : this.profile.firstName,
    middleName : this.profile.middleName,
    lastName : this.profile.lastName,
    position: this.profile.position
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-profile] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onProfileFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "userID" : this.user._id,
      "profile": {
        "firstName" : this.form.firstName,
        "middleName" : this.form.middleName,
        "lastName" : this.form.lastName
      }
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/profile/update', input, options).then((response:any) => {
      this.laddaSubmit!.stop();
      if(response.updated) {  
        // We update the user in the store
        this.$store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, response.user);
      }
    });
  }

  onUpdateUserButtonClick() {
    // @ts-ignore
    this.$refs.updateUserModal.show()
  }

  onUpdatePasswordButtonClick() {
    // @ts-ignore
    this.$refs.updatePasswordModal.show()
  }

  onUserUpdated(user:any) {
    this.$store.commit('authentication/' + authenticationTypes.mutations.UPDATE_USER, user);
  }

}

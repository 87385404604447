


























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import NumberField from "../../../../panels/input/NumberField.vue";
import Checkbox from "../../../../panels/input/Checkbox.vue";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import moment from "moment";

@Component({
  components: {
    TextField,
    NumberField,
    Checkbox
  }
})
export default class Create extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  businessIntroducer!: any;

  form: any = {
    code: this.businessIntroducer.code,
    name: this.businessIntroducer.name,
    unlimitedPackageAmount: this.businessIntroducer.unlimitedPackageAmount,
    workerPackageAmount: this.businessIntroducer.workerPackageAmount,
    workerPackageMaxNumberOfWorker: this.businessIntroducer
      .workerPackageMaxNumberOfWorker,
    isActive: this.businessIntroducer.isActive
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=form-businessIntroducer] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    var input = {
      name: this.form.name,
      code: this.form.code,
      unlimitedPackageAmount: this.form.unlimitedPackageAmount,
      workerPackageAmount: this.form.workerPackageAmount,
      workerPackageMaxNumberOfWorker: this.form.workerPackageMaxNumberOfWorker,
      isActive: this.form.isActive
    };

    this.laddaSubmit!.start();

    api
      .postAPI(
        "/api/admin/businessIntroducer/" +
          this.businessIntroducer._id +
          "/update",
        input,
        options
      )
      .then((response: any) => {
        if (response.updated) {
          this.$emit("businessIntroducerUpdated", response.businessIntroducer);
        }
        this.laddaSubmit!.stop();
      });
  }
}

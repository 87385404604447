import Vue from 'vue';
import { Module } from 'vuex';

export const types = {
  getters: {
    GET_REGISTER_PAYMENT : "GET_REGISTER_PAYMENT",
    GET_NUMBER_OF_WORKERS : "GET_NUMBER_OF_WORKERS"
  },
  mutations : {
    WORKERS_REGISTER_UPDATE_PAYMENT : 'WORKERS_REGISTER_UPDATE_PAYMENT',
    WORKERS_SET_ACTIVE_CONTRACTS : 'WORKERS_SET_ACTIVE_CONTRACTS',
    WORKERS_SET_REQUESTS : 'WORKERS_SET_REQUESTS',
    WORKERS_SET_NUMBER_OF_WORKERS : 'WORKERS_SET_NUMBER_OF_WORKERS',
  }
}

export function createEurofiscalisStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      workers : {
        register : {
          payment : null
        },
        contracts : [], // The active contracts if any.
        workerRequests : [], // The active workerRequests if any.
        numberOfWorkers : null  // The number of workers added by the user
      }
    },
    getters: {
      /**
       * GET_REGISTER_PAYMENT
       * This getter returns if adding worker is allowed
       */
      [types.getters.GET_REGISTER_PAYMENT] (state):any {
        return state.workers.register.payment;
      },
      /**
      * GET_NUMBER_OF_WORKERS
      * This getter returns the number of workers
      */
     [types.getters.GET_NUMBER_OF_WORKERS] (state):any {
       return state.workers.numberOfWorkers;
     },
    },
    mutations: {
      [types.mutations.WORKERS_REGISTER_UPDATE_PAYMENT] (state, payment) {
        // We update the store
        Vue.set(state.workers.register, 'payment', payment);
      },
      [types.mutations.WORKERS_SET_ACTIVE_CONTRACTS] (state, contracts) {
        // We update the store
        Vue.set(state.workers, 'contracts', contracts);
      },
      [types.mutations.WORKERS_SET_REQUESTS] (state, workerRequests) {
        // We update the store
        Vue.set(state.workers, 'workerRequests', workerRequests);
      },
      [types.mutations.WORKERS_SET_NUMBER_OF_WORKERS] (state, numberOfWorkers) {
        // We update the store
        Vue.set(state.workers, 'numberOfWorkers', numberOfWorkers);
      }
    }

  }
  return store;
};






























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';
import * as api from '@client/utils/api';

@Component({
  components : {}
})
export default class Nationality extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: String,
    required: false,
    default: "EU"
  }) readonly type!: string

  inputLabel = "";
  inputPlaceholder = "";
  inputId = "nationality";

  listNationalities:any[] = [];

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$t('profile.info.countryOfNationality') as string
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$t('profile.info.countryOfNationality_select') as string
    if(this.id) { this.inputId = this.id }

    this.updateListNationalities();
  }

  updateListNationalities() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiOptions =  {
      app: this
    }

    var endpoint = "listEUNationalities";
    if(this.type != "EU") {
      endpoint = "listNationalities";
    }

    api.getAPI('/api/utils/' + endpoint, options).then((response:any) => {
      if(response.nationalities) {  
        this.listNationalities = response.nationalities;
        var _self = this;
        if(_self.input && _self.input != "") {
          // We get the value from countryCode
          var selected = this.listNationalities.find(function(element) {
            return element.code == _self.input;
          });
          if(selected) {
            // @ts-ignore
            _self.$refs.listNationalities.updateValue(selected);
          }
        }
      }
    });
  }

  get validationRules() {
    if(this.required) {
      return {
        "required" : true
      }
    }
    else {
      return {}
    }
  }

  @Watch('input')
  onInputChange() {
    // @ts-ignore
    this.$refs.input.validate();
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListNationalities();
  }
  
}

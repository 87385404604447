


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { languagesTypes } from '@client/store/types';

@Component({
  computed: {
    ...mapGetters({
          currentLanguage : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
  },
})
export default class MenuItem extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly node!: any

  @Prop({
    type: Number,
    required: false,
    default: 1
  }) readonly level!: number

  @Prop({
    type: String,
    required: false
  }) readonly activeClass!: string

  get ariaExpanded() {
    if(this.node.expanded) {
      return "true";
    }
    return "false"
  }

  nodeRootClass = this.node && this.node.expanded ? "mm-active" : null;

  get subMenuClass() {
    var cssClass = "";
    switch(this.level) {
      case 1:
        cssClass = "nav nav-second-level mm-collapse";
        break;
      case 2:
        cssClass = "nav nav-third-level mm-collapse";
        break;
    }
    if(this.node.expanded) {
      cssClass += " mm-show";
    }
    return cssClass;
  } 

  beforeUpdate() {
    if(this.node.nodes) {
      // @ts-ignore
      this.nodeRootClass = this.$refs['nodeRoot'].className;

      // @ts-ignore
      this.node.expanded = this.$refs['subMenu'].className.indexOf("mm-show") > -1;
    }
  }

}

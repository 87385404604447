









































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';

@Component({
})
export default class Confirmation extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();

  onLoginButtonClick() {
    this.$router.push('../login');
  }
  
}













































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import NumberField from "../../../../panels/input/NumberField.vue";
import Checkbox from "../../../../panels/input/Checkbox.vue";
import RepresentativeCompany from "./FormRepresentativeCompany.vue";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import moment from "moment";

@Component({
  components: {
    TextField,
    NumberField,
    Checkbox,
    RepresentativeCompany
  }
})
export default class Create extends Vue {
  fileToUploadFr: any = null;
  fileToUploadPl: any = null;
  uploadFieldName: string = "filesTermsOfSales";
  emptyForm: any = {
    code: "",
    name: "",
    unlimitedPackageAmount: 0,
    workerPackageAmount: 0,
    representativeCompany: {
      name: "",
      address: {
        addressLines: [],
        postalCode: "",
        city: "",
        countryCode: ""
      },
      phone: {
        type: "BUSINESS",
        number: ""
      },
      email: "",
      siret: "",
      siren: "",
      vatNumber: "",
      fiscalCode: "",
      contact: {
        name: "",
        dateOfBirth: "",
        cityOfBirth: "",
        countryOfBirth: "",
        nationality: ""
      },
      personalIdentificationNumber: ""
    },
    isActive: true
  };

  form: any = {
    ...this.emptyForm
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=form-representativeCountry] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    const formData = new FormData();
    formData.append(
      this.uploadFieldName,
      this.fileToUploadFr,
      this.fileToUploadFr.name
    );
    formData.append(
      this.uploadFieldName,
      this.fileToUploadPl,
      this.fileToUploadPl.name
    );
    formData.append("countries", "fr")
    formData.append("countries", "pl")

    var input = {
      name: this.form.name,
      code: this.form.code,
      unlimitedPackageAmount: this.form.unlimitedPackageAmount,
      workerPackageAmount: this.form.workerPackageAmount,
      representativeCompany: this.form.representativeCompany,
      isActive: this.form.isActive
    };

    formData.append("input", JSON.stringify(input));


    console.log('formData', formData);

    this.laddaSubmit!.start();

    api
      .postAPIFormData(
        "/api/admin/representativeCountries/create",
        formData,
        options
      )
      .then((response: any) => {
        if (response.created) {
          this.$emit(
            "representativeCountryCreated",
            response.representativeCountry
          );
          this.form = {
            ...this.emptyForm
          };
        }
        this.laddaSubmit!.stop();
      });
  }
}

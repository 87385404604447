
































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";

@Component({
  components: {}
})
export default class Duplicate extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  readonly worker!: any;

  @Prop({
    type: Object,
    required: true
  })
  readonly workerContract!: any;

  @Prop({
    type: String,
    required: true
  })
  readonly companyId!: string;

  @Prop({
    type: Array,
    required: true
  })
  readonly workerContracts!: any[];

  form: any = {
    contract: null
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  get availableContracts() {
    return this.workerContracts.filter((c:any) => c._id != this.workerContract._id);
  }

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=update-worker] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt: Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    this.laddaSubmit!.start();

    api
      .postAPI(
        "/api/eurofiscalis/workers/company/" +
          this.companyId +
          "/contract/" +
          this.form.contract._id +
          "/worker/" +
          this.worker._id +
          "/duplicate",
        this.form,
        options
      )
      .then((response: any) => {
        if (response.duplicated) {
          this.$emit("worker-duplicated", response.duplicated);
        }
        this.laddaSubmit!.stop();
      });
  }
}






import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class GenericInput<T> extends Vue {
  
  @Prop({
    type: [String,Date,Boolean,Number],
    required: false
  }) readonly value!: T | undefined

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly required!: boolean

  @Prop({
    type: String,
    required: false
  }) readonly id!: string | undefined

  @Prop({
    type: String,
    required: false
  }) readonly label!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly labelAsColumn!: boolean

   @Prop({
    type: String,
    required: false
  }) readonly placeholder!: string

  input = this.value !== undefined ? this.value : "";

  labelCSSClass = this.labelAsColumn ? "col-lg-3 col-form-label" : null
  fieldCSSClass = this.labelAsColumn ? "col-lg-9" : null;
  rowCSSClass = this.labelAsColumn ? {"form-group":true, "row":true} : {"form-group":true};

  @Watch('input')
  onChange(val: any, oldVal: any) {
    this.$emit('update:value', val);
  }
  
}

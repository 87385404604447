export const languagesTypes = {
    actions : {
        SET_DEFAULT_LANGUAGE : 'SET_DEFAULT_LANGUAGE',
        UPDATE_LANGUAGE : 'UPDATE_LANGUAGE',
        CHECK_ROUTE_LANGUAGE: 'CHECK_ROUTE_LANGUAGE',
        CHECK_BUNDLE_LANGUAGE: 'CHECK_BUNDLE_LANGUAGE'
    },
    getters : {
        GET_AVAILABLE_LANGUAGES : 'GET_AVAILABLE_LANGUAGES',
        GET_CURRENT_LANGUAGE : 'GET_CURRENT_LANGUAGE',
        IS_AVAILABLE_LANGUAGE : 'IS_AVAILABLE_LANGUAGE'
    },
    mutations : {
        ADD_BEFORE_SET_LANGUAGE_ACTION : 'ADD_BEFORE_SET_LANGUAGE_ACTION',
        ADD_AFTER_SET_LANGUAGE_ACTION : 'ADD_AFTER_SET_LANGUAGE_ACTION'
    },
    COMMON_BUNDLE : 'COMMON_BUNDLE'
}

export const metaTypes = {
    getters : {
        GET_CURRENT_META : 'GET_CURRENT_META',
        GET_BODY_CLASSES : 'GET_BODY_CLASSES'
    },
    mutations : {
        ADD_BODY_CLASS : "ADD_BODY_CLASS",
        REMOVE_BODY_CLASS : "REMOVE_BODY_CLASS",
        TOGGLE_BODY_CLASS : "TOGGLE_BODY_CLASS",
        UPDATE_META_FROM_TRANSLATIONS : "UPDATE_META_FROM_TRANSLATIONS"
    }
}

// WARNING: Same as MessageTypes in Message in server
export enum MessageTypes {
    ERROR = "ERROR",
    WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	MESSAGE = "MESSAGE"
}
// WARNING: Same as Message in server
export interface Message {
    key:number,
    formattedMessage:string,
    number:number,
    secondNumber?:number,
    type: MessageTypes
}

export const messagesTypes = {
    actions : {
        
    },
    getters : {
        GET_MESSAGES : 'GET_MESSAGES'
    },
    mutations : {
        ADD_MESSAGES : 'ADD_MESSAGES',
        ADD_GENERIC_MESSAGE : 'ADD_GENERIC_MESSAGE',
        CLEAR_MESSAGES : 'CLEAR_MESSAGES'
    }
}

export const routerTypes = {
    mutations : {
        SET_ROUTES : 'SET_ROUTES',
        SET_ASYNC_DATA : 'SET_ASYNC_DATA',
        SET_COMPONENTS : 'SET_COMPONENTS',
        ADD_LAYOUT : 'ADD_LAYOUT'
    },
    getters : {
        GET_ASYNC_DATA_BY_COMPONENT : 'GET_ASYNC_DATA_BY_COMPONENT',
        GET_ROUTES : 'GET_ROUTES'
    },
    actions : {
        UPDATE_COMPONENTS : 'UPDATE_COMPONENTS',
        CHECK_ASYNC_DATA_COMPONENT : 'CHECK_ASYNC_DATA_COMPONENT',
        CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE : 'CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE',
        CHECK_LAYOUT : 'CHECK_LAYOUT'
    }
}

export const authenticationTypes = {
    actions : {
        AUTH_LOGOUT : "AUTH_LOGOUT",
    },
    mutations : {
        AUTH_SUCCESS : "AUTH_SUCCESS",
        UPDATE_USER : "UPDATE_USER"
    }
};
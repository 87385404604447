
















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes, languagesTypes } from '@client/store/types';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery';
import CreateNote from '../admin/creditnote/Create.vue';
import CreateInvoice from '../admin/invoice/Create.vue';
import moment from 'moment'

@Component({
  components: {
    CreateNote,CreateInvoice
  }
})
export default class ListInvoices extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  @Prop({
    type: String,
    required: true
  }) readonly workerContractId!: string

  @Prop({
      type: Boolean,
      required: false,
      default: true
    }) readonly showCompanyColumn?: boolean

  @Prop({
    type: String,
    required: true
  }) readonly countryCode!: string

  invoiceId:any = "";
  forCreateInvoiceObject:any="";
  emptyCreditNote:any = {
    date : "",
    Number : "",
    amount : ""
  };


  listInvoicesLoading = false;

  listInvoicesSelected = [];
  isAdmin = false;

  invoiceFields = [
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "number",
        label: ""
      },
      {
        key: "contractValidity",
        formatter: (value:any, key:any, item:any) => {
          return '[' + (item.items[0].details ? item.items[0].details.parameters.startDate : '') + ' - ' +
                   (item.items[0].details ? item.items[0].details.parameters.endDate : '') + ': ' +
                   (item.workerContract && item.workerContract.allowedNumberOfWorkers) + ']';
        },
        label:""
      },
      {
        key: "company",
        formatter: (value:any, key:any, item:any) => {
          return item.company.name;
        },
        class: (this.showCompanyColumn ? "" : "hidden"),
        label:""
      },
      {
        key: "formattedTotalAmountWithoutTax",
        label: ""
      },
      {
        key: "options",
        label: ""
      },
      {
        key: "FormattedTotalCreditNoteAmountWithoutTax",
        label: ""
      }
      ,
      {
        key: "generalOptions",
        label: ""
      }

    ];

  creditNoteFields = [ {
        key: "date",
        label: ""
      },
      {
        key: "number",
        label: ""
      },
      {
        key: "amount",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
      ];

  mounted() {
    this.updateTableLabels();
    enableIbox(this.$refs.listInvoices as HTMLElement);
  }

  momentDate(date:Date){
    return moment(date).format('YYYY-MM-DD');
  }

  updateTableLabels() {
    this.invoiceFields[0].label = this.$t('invoice.headers.creationDateTime') as string;
    this.invoiceFields[1].label = this.$t('invoice.headers.number') as string;
    this.invoiceFields[2].label = this.$t('invoice.headers.contractValidity') as string;
    this.invoiceFields[3].label = this.$t('invoice.headers.company') as string;
    this.invoiceFields[4].label = this.$t('invoice.headers.totalAmountWithoutTax') as string;
    this.invoiceFields[5].label = this.$t('invoice.headers.options') as string;
    this.invoiceFields[6].label = this.$t('invoice.headers.creditnotes') as string;

    this.creditNoteFields[0].label =  this.$t('creditnote.headers.date') as string;
    this.creditNoteFields[1].label = this.$t('creditnote.headers.number') as string;
    this.creditNoteFields[2].label = this.$t('creditnote.headers.totalWithoutTax') as string;
    this.creditNoteFields[3].label = this.$t('creditnote.headers.options') as string;
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  beforeDestroy() {
    disableIbox(this.$refs.listInvoices as HTMLElement);
  }

  invoicesProvider(ctx:any) {
    var options:api.ApiOptions =  {
      app: this
    }
    this.listInvoicesLoading = true;
    return api.getAPI('/api/invoices/company/'+this.companyId+'/contract/' + this.workerContractId + '/list', options).then((response:any) => {
      this.listInvoicesLoading = false;
      if(response.invoices) {
        this.isAdmin = response.isAdmin;

        return response.invoices.filter(i => {
          if (this.countryCode === 'EU') {
            return true;
          }

          return i.workerContract &&
                 i.workerContract.representativeCountry &&
                 (i.workerContract.representativeCountry.code === this.countryCode);
        }).sort((a, b) => a.number > b.number ? -1 : 1);
      }
      else {
        return [];
      }
    })
  }

  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  getDownloadURL(invoice:any) {
    return '/'+ this.currentLanguage.code +'/api/invoices/'+invoice._id+'/download';
  }
  getDownloadCreditNoteURL(creditnote:any) {
    return '/'+ this.currentLanguage.code +'/api/creditnotes/'+creditnote._id+'/download';
  }

  onCreditNoteCreated() {
    // @ts-ignore
    this.$refs.creditNoteAddModal.hide()
    // @ts-ignore
    this.$refs.listInvoicesTable.refresh()
  }

    onInvoiceCreated() {
    // @ts-ignore
    this.$refs.invoiceAddModal.hide()
    // @ts-ignore
    this.$refs.listInvoicesTable.refresh()
  }

  showCreditNoteAddModal(invoice:any) {

   this.invoiceId = invoice._id;
    // @ts-ignore
    this.$refs.creditNoteAddModal.show()
  }
 showInvoiceAddModal() {

   this.forCreateInvoiceObject ={companyId:this.companyId,workerContractId:this.workerContractId};
    // @ts-ignore
    this.$refs.invoiceAddModal.show()
  }
}

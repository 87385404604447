import { Store } from 'vuex';
import { metaTypes } from '@client/store/types';
import { enableMetismenu } from './inspinia-jquery';

/**********************************************************************
 * NEEDED POLYFILLS
 **********************************************************************/

if(process.env.VUE_ENV == 'client') {

    if (!Element.prototype.matches)
        // @ts-ignore
        Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                    Element.prototype.webkitMatchesSelector;

    if (!Element.prototype.closest)
        Element.prototype.closest = function(s:string) {
            var el:any = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType == 1); 
            return null;
        };
}

/**********************************************************************
 * This file contains translated method from inspinia.js file adapted
 * to the application framework without jQuery
 **********************************************************************/

// Toggle class function
function toggleClass(element:HTMLElement, className:string){
    if (!element || !className){
        return;
    }
    
    var classString = element.className, nameIndex = classString.indexOf(className);
    if (nameIndex == -1) {
        classString += ' ' + className;
    }
    else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex+className.length);
    }
    element.className = classString;
}

/**
 * checkWindowSize
 * This method trigger UI updates depending on the window size.
 * @param store - the root store of the application
 */
export const checkWindowSize = function(store:Store<any>) {
    if(window.innerWidth < 749) {
        store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'body-small');
    }
    else {
        store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'body-small');
    }
}

/**
 * smoothlyMenu
 * 
 * @param store - the root store of the application
 */
export const smoothlyMenu = function (store:Store<any>) {
    const bodyClasses = store.state.meta.bodyClasses;
    if (bodyClasses.indexOf('mini-navbar') < 0 || bodyClasses.indexOf('body-small') > -1) {
        // Hide menu in order to smoothly turn on when maximize menu
        document.getElementById('side-menu')!.style.transition = 'all 0s';
        document.getElementById('side-menu')!.style.opacity = '0';
        
        // For smoothly turn on menu`
        setTimeout(
            function () {
              document.getElementById('side-menu')!.style.transition = "";
              document.getElementById('side-menu')!.style.opacity = '1';
            }, 200);
    } else if (bodyClasses.indexOf('fixed-sidebar') > -1) {
        document.getElementById('side-menu')!.style.transition = 'all 0s';
        document.getElementById('side-menu')!.style.opacity = '0';
        setTimeout(
            function () {
              document.getElementById('side-menu')!.style.transition = "";
              document.getElementById('side-menu')!.style.opacity = '1';
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        document.getElementById('side-menu')!.removeAttribute('style');
    }
    enableMetismenu(store);
}

export const fixHeight = function(store:Store<any>) {
    const bodyClasses = store.state.meta.bodyClasses;

    var wrapper = document.getElementById("wrapper");
    if(!wrapper) {
        // We check that the layout is using wrapper id.
        return;
    }

    var wrapperStyle = getComputedStyle(wrapper);

    var heightWithoutNavbar = parseInt(wrapperStyle.height!) - 62;
    var sideBarPanel:HTMLElement|null = document.querySelector(".sidebar-panel");
    if(sideBarPanel != null) {
        sideBarPanel.style.minHeight = heightWithoutNavbar + "px";
    }

    var pageWrapper:HTMLElement|null = document.querySelector('#page-wrapper');
    var pageWrapperStyle = getComputedStyle(pageWrapper!);
    var pageWrapperHeight = parseInt(pageWrapperStyle.height!);
    var navbar:HTMLElement|null = document.querySelector('nav.navbar-default');
    var navbarStyle = getComputedStyle(navbar!);
    var navbarheight = parseInt(navbarStyle.height!);
    

    if (pageWrapper != null && navbarheight > pageWrapperHeight) {
        pageWrapper.style.minHeight = navbarheight + "px";
    }

    if (pageWrapper != null && navbarheight < pageWrapperHeight) {
        pageWrapper.style.minHeight = window.innerHeight + "px";
    }

    if(pageWrapper != null && bodyClasses.indexOf('fixed-nav')>-1) {
        if (navbarheight > pageWrapperHeight) {
            pageWrapper.style.minHeight = navbarheight + "px";
        } else {
            pageWrapper.style.minHeight = (window.innerHeight - 60) + "px";
        }
    }
}

export const enableIbox = function() {
    // Collapse ibox function
    var collapseLinks:NodeListOf<HTMLElement> = document.querySelectorAll('.collapse-link');

    var clickHandler = function(evt:Event) {
        evt.preventDefault();
        var link = evt.currentTarget! as HTMLElement;
        var ibox = link.closest('div.ibox')! as HTMLElement;
        var button = link.querySelector('i')!;
        var content = ibox.querySelector('.ibox-content')! as HTMLElement;

        var contentStyle = getComputedStyle(content);
        var contentHeight = parseInt(contentStyle.height!);

        if(contentHeight > 0) {
            // We reset transition to set default size
            content.style.transition = 'all 0s';
            content.style.height = contentStyle.height;
            content.setAttribute('originalHeight',contentStyle.height!);

            setTimeout(
                function () {
                    content.style.transition = 'all 1s';
                    content.style.height = '0px';
                    content.style.paddingTop = '0px';
                    content.style.paddingBottom = '0px';
                    content.style.border = '0px';
                    content.style.overflowY = 'hidden';
                }, 200);
        }
        else {
            content.style.height = content.getAttribute('originalHeight') as string;
            content.style.paddingTop = '';
            content.style.paddingBottom = '';
            content.style.border = '';
        }
        
        toggleClass(button, 'fa-chevron-up');
        toggleClass(button, 'fa-chevron-down');
        
        toggleClass(ibox, '');
        toggleClass(ibox, 'border-bottom');
        
        /*
        setTimeout(function () {
            ibox.dispatchEvent(new Event('resize'));
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
        */
    }

    for(var link of collapseLinks) {
        link.addEventListener('click', clickHandler);
    }

    /*

    // Close ibox function
    $('.close-link').on('click', function (e) {
        e.preventDefault();
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link').on('click', function (e) {
        e.preventDefault();
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });
*/
}
















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Date from '../../../panels/input/Date.vue';
import TextField from '../../../panels/input/TextField.vue';
import TextArea from '../../../panels/input/TextArea.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';
import moment from 'moment'

@Component({
  components: { 
    Date,
    TextField,
    TextArea
  }
})
export default class Create extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly forCreateInvoiceObject!: any
// forCreateInvoiceObject = {companyId:companyId,workerContractId:workerContractId};
  emptyForm:any = {
    date: moment().format('YYYY-MM-DD'),
    amount: '',
  }

  form:any = {
    ...this.emptyForm
  };

  vatNumberInvalid:boolean = true;

  laddaSubmit:Ladda.LaddaButton|null = null;
  created()
  {
    this.checkVAT();
  }

  mounted() {
    
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-invoice] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
    
  }

  checkVAT()
  {
    
        var input = {
          companyId: this.forCreateInvoiceObject.companyId
        };
        
        var options: api.ApiOptions = {
          app: this
        };
        api.postAPI('/api/admin/companies/checkVatNumber', input, options).then((response:any) => {
            if (response.valid) 
              this.vatNumberInvalid= false;
          });
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    if(this.vatNumberInvalid==false)
    this.form.amountTaxes = 0;


    var input = {
      "date" : this.form.date,
      "amountWithoutTax" :this.form.amountWithoutTax,
      "amountTaxes" : this.form.amountTaxes,
      "description": this.form.description,
      "workerContractId" : this.forCreateInvoiceObject.workerContractId
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/workercontracts/'+this.forCreateInvoiceObject.workerContractId+'/invoice/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('invoice-created', response.invoice);

        // We reset the fields
        this.form = {
          ...this.emptyForm
        }
      }
      this.laddaSubmit!.stop();
    });
  }
  
}

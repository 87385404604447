const Register = () => import(/* webpackChunkName: "route-module-eurofiscalis-workers-register" */ '../components/pages/eurofiscalis/workers/Register.vue').then(m => m.default);
const Conditions = () => import(/* webpackChunkName: "route-module-eurofiscalis-workers-conditions" */ '../components/pages/eurofiscalis/workers/Conditions.vue').then(m => m.default);
const Payment = () => import(/* webpackChunkName: "route-module-eurofiscalis-workers-payment" */ '../components/pages/eurofiscalis/workers/Payment.vue').then(m => m.default);
const Confirmation = () => import(/* webpackChunkName: "route-module-eurofiscalis-workers-confirmation" */ '../components/pages/eurofiscalis/workers/Confirmation.vue').then(m => m.default);
const BankTransfer = () => import(/* webpackChunkName: "route-module-eurofiscalis-workers-bank-transfer" */ '../components/pages/eurofiscalis/workers/BankTransfer.vue').then(m => m.default);

export const routes = [
  {
    path: '/workers/register',
    component: Register,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['company','profile','eurofiscalis_workers', 'common']
    }
  },
  {
    path: '/workers/conditions',
    component: Conditions,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['eurofiscalis_workers']
    }
  },
  {
    path: '/workers/payment',
    component: Payment,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: []
    }
  },
  {
    path: '/workers/confirmation',
    component: Confirmation,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['eurofiscalis_workers','profile']
    }
  },
  {
    path: '/workers/bank-transfer',
    component: BankTransfer,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['eurofiscalis_workers']
    }
  }
]
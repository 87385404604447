
























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Civility from '../../../panels/input/Civility.vue';
import FirstName from '../../../panels/input/FirstName.vue';
import LastName from '../../../panels/input/LastName.vue';
import Date from '../../../panels/input/Date.vue';
import TextField from '../../../panels/input/TextField.vue';
import Nationality from '../../../panels/input/Nationality.vue';
import Country from '../../../panels/input/Country.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { 
    Civility,
    FirstName,
    LastName,
    Date,
    TextField,
    Nationality,
    Country
  }
})
export default class Create extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  @Prop({
    type: String,
    required: true
  }) readonly workerContractId!: string

  emptyForm:any = {
    civility: '',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    cityOfBirth: '',
    countryCodeOfBirth: '',
    countryCodeOfNationality: ''
  }

  form:any = {
    ...this.emptyForm
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-worker] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "civility" : this.form.civility,
      "firstName" : this.form.firstName,
      "lastName" : this.form.lastName,
      "dateOfBirth" : this.form.dateOfBirth,
      "cityOfBirth" : this.form.cityOfBirth,
      "countryCodeOfBirth" : this.form.countryCodeOfBirth,
      "countryCodeOfNationality" : this.form.countryCodeOfNationality,
      "companyId" : this.companyId
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/eurofiscalis/workers/company/'+this.companyId+'/contract/' + this.workerContractId + '/worker/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('worker-created', response.worker);

        // We reset the fields
        this.form = {
          ...this.emptyForm
        }
      }
      this.laddaSubmit!.stop();
    });
  }
  
}

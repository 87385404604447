var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"role":"form","name":"update-user"},on:{"submit":_vm.onFormSubmit}},[(_vm.user.login != _vm.user.email)?_c('validation-provider',{attrs:{"name":"login","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true, 'row':true}, classes)},[_c('label',{staticClass:"col-lg-3 col-form-label",attrs:{"for":"updateDetailsLogin"}},[_vm._v(_vm._s(_vm.$t('profile.info.login')))]),_vm._v(" "),_c('div',{staticClass:"col-lg-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.login),expression:"form.login"}],staticClass:"form-control",attrs:{"id":"updateDetailsLogin","type":"text","placeholder":_vm.$t('profile.info.login_placeholder'),"required":""},domProps:{"value":(_vm.form.login)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "login", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}):_vm._e(),_vm._v(" "),_c('validation-provider',{attrs:{"name":"email","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true, 'row':true}, classes)},[_c('label',{staticClass:"col-lg-3 col-form-label",attrs:{"for":"updateDetailsEmail"}},[_vm._v(_vm._s(_vm.$t('profile.info.email')))]),_vm._v(" "),_c('div',{staticClass:"col-lg-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"id":"updateDetailsEmail","type":"email","placeholder":_vm.$t('profile.info.email_placeholder'),"required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"hr-line-dashed"}),_vm._v(" "),_c('validation-provider',{ref:"currentPasswordProvider",attrs:{"name":"currentPassword","mode":"aggressive","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true, 'row':true}, classes)},[_c('label',{staticClass:"col-lg-3 col-form-label",attrs:{"for":"updateDetailsCurrentPassword"}},[_vm._v(_vm._s(_vm.$t('profile.info.currentPassword')))]),_vm._v(" "),_c('div',{staticClass:"col-lg-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.currentPassword),expression:"form.currentPassword"}],staticClass:"form-control",attrs:{"id":"updateDetailsCurrentPassword","type":"password","placeholder":_vm.$t('profile.info.currentPassword_placeholder'),"autocomplete":"new-password","required":""},domProps:{"value":(_vm.form.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "currentPassword", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button update-details",attrs:{"disabled":invalid,"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.$t('profile.info.save')))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
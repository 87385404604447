




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { languagesTypes, routerTypes, metaTypes} from '@client/store/types';
import { MetaInfo } from '@client/utils/class-component-decorators';
import { Route } from 'vue-router';

@Component({})
export default class GenericPage extends Vue {
  metas:any;
  bodyClasses:string[] = [];

  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }


  get currentMeta() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_CURRENT_META];
  }

  get currentBodyClasses() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_BODY_CLASSES];
  }

  @MetaInfo
  metaInfo() {
    const currentMeta = this.currentMeta;
    const bodyClasses = this.currentBodyClasses;

    var metaInfo = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: currentMeta.title || "",
      // all titles will be injected into this template
      titleTemplate: undefined,
      htmlAttrs: { 
        // attributes within HTML tag
        lang : this.currentLanguage.code
      },
      headAttrs: {
        // attributes within HEAD tag
      },
      bodyAttrs: {
        class : bodyClasses.join(" ")
      },
      meta : [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
    if(currentMeta.description) {
      (metaInfo.meta as Array<any>).push({ vmid: 'description', name : "description" , content : currentMeta.description});
    }
    return metaInfo;
  }
  
}









































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import GenericPage from "@client/components/mixins/GenericPage.vue";
import Messages from "../../../panels/Messages.vue";
import Phone from "../../../panels/contact/phones/Phone.vue";
import Civility from "../../../panels/input/Civility.vue";
import FirstName from "../../../panels/input/FirstName.vue";
import LastName from "../../../panels/input/LastName.vue";
import Checkbox from "../../../panels/input/Checkbox.vue";
import RepresentativeCountryPicker from "../../../panels/input/RepresentativeCountry.vue";
import UpdateAddress from "../../../panels/UpdateAddress.vue";
import { messagesTypes, Message } from "@client/store/types";
import * as api from "@client/utils/api";
import * as Ladda from "ladda";
import { extend } from "vee-validate";
import { types as eurofiscalisTypes } from "../../../../store/eurofiscalis";
import {
  positions,
  nbOfEmployees,
  contractDurationInMonth,
  currencyCode,
  PaymentMethod
} from "../../../../types/common";

@Component({
  components: {
    UpdateAddress,
    Messages,
    Phone,
    Civility,
    FirstName,
    LastName,
    Checkbox,
    RepresentativeCountryPicker
  }
})
export default class Register extends mixins(GenericPage) {
  contractDurationInMonth:number = contractDurationInMonth;
  isPromoCode = true;
  isRegisterWithoutPromoConfirmed = false;
  businessIntroducer: any = null;
  showNumberOfWorkers = false;

  errors: Message[] = [];

  year = new Date().getFullYear();

  positions = positions;
  nbOfEmployees = nbOfEmployees;

  addressOptions = {
    showName: false,
    labelAsColumn: false,
    nbAddressLines: 2
  };

  listRepresentativeCountries: any[] = [];
  allowedMOPs: string[] = [];

  vatDetails: any = "";

  emptyForm: any = {
    company: {
      name: "",
      vatNumber: "",
      address: {
        addressLines: [],
        postalCode: "",
        city: "",
        countryCode: ""
      },
      size: ""
    },
    profile: {
      civility: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      position: "",
      phone: {
        type: "BUSINESS",
        number: ""
      }
    },
    promoCode: "",
    representativeCountries: [
      {
        numberOfWorkers: 1,
        object: {},
        package: null
      }
    ],
    conditions: false,
    mop: PaymentMethod.CARD
  };

  form: any = {
    ...this.emptyForm
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  get vatNumberValidationRules() {
    return {
      required: true,
      vatNumberValidation: true
    };
  }

  get canAddRepresentativeCountry() {
    return (
      this.form.representativeCountries.length <
      this.listRepresentativeCountries.length
    );
  }

  get canDeleteRepresentativeCountry() {
    return this.form.representativeCountries.length > 1;
  }

  get selectedRepresentativeCountriesCode() {
    return this.form.representativeCountries.map((c: any) => c.object.code);
  }

  get selectedRepresentativeCountries() {
    return this.form.representativeCountries.filter(
      (c: any) => c.object && c.object.code
    );
  }

  get hasSelectedCountry() {
    var has = false;
    this.form.representativeCountries.forEach((c: any) => {
      if (c.object && c.object.code) {
        has = true;
        return;
      }
    });
    return has;
  }

  //TODO: add vat
  get includingTaxesAmount() {
    var amountByMonth = this.calculateAmountByMonth();
    var totalAmount = amountByMonth * contractDurationInMonth;
    return new Intl.NumberFormat(
      this.$store.state.languages.currentLanguageCode,
      {
        style: "currency",
        currency: currencyCode
      }
    ).format(totalAmount);
  }

  get excludingTaxesAmount() {
    var amountByMonth = this.calculateAmountByMonth();
    var totalAmount = amountByMonth * contractDurationInMonth;
    return new Intl.NumberFormat(
      this.$store.state.languages.currentLanguageCode,
      {
        style: "currency",
        currency: currencyCode
      }
    ).format(totalAmount);
  }

  get vatAmount() {
    return 0;
  }

  get maxNumberOfWorker() {
    return this.businessIntroducer !== null
      ? this.businessIntroducer.workerPackageMaxNumberOfWorker
      : null;
  }

  created() {
    // We get the messages from store
    var messages = this.$store.getters[
      "messages/" + messagesTypes.getters.GET_MESSAGES
    ];
    if (messages.length > 0) {
      // We update the list of errors
      this.errors = messages;
    }

    this.addValidation();

    // We update the list of representative countries
    this.updateListRepresentativeCountries();
    this.updateListAllowedMops();
  }

  mounted() {
    // We clear the messages from store
    this.$store.commit("messages/" + messagesTypes.mutations.CLEAR_MESSAGES);

    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=register-form] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  calculateAmountByMonth() {
    var amount = 0;
    console.log(this.form.representativeCountries);
    this.form.representativeCountries.forEach((c: any) => {
      if (c.package) {
        var packageAmount = parseInt(c.package.slice(2));
        if (c.package[0] === 'w') {
          amount += packageAmount * c.numberOfWorkers;
        } else {
          amount += packageAmount;
        }
      } else {
        if (this.businessIntroducer !== null && c.object && c.object.code) {
          if (this.showNumberOfWorkers && c.numberOfWorkers) {
            if (c.numberOfWorkers === "more")
              amount += this.businessIntroducer.unlimitedPackageAmount;
            else
              amount +=
                c.numberOfWorkers * this.businessIntroducer.workerPackageAmount;
          } else if (!this.showNumberOfWorkers) {
            amount += this.businessIntroducer.unlimitedPackageAmount;
          }
        } else if (c.object && c.object.unlimitedPackageAmount) {
          amount += c.object.unlimitedPackageAmount;
        }
      }
    });

    return amount;
  }

  updateListRepresentativeCountries() {
    // We need to get the list of available companies for the current logged in user
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/representativeCountries/list", options)
      .then((response: any) => {
        if (response.countries) {
          this.listRepresentativeCountries = response.countries;
          var _self = this;
          if (
            _self.form.representativeCountries &&
            _self.form.representativeCountries.length > 0
          ) {
            // We get the value from representativeCountries
            var selected = this.listRepresentativeCountries.filter(function(
              representativeCountry
            ) {
              return (
                _self.form.representativeCountries
                  .map((country: any) => {
                    return country.code;
                  })
                  .indexOf(representativeCountry.code) > -1
              );
            });
            if (selected && selected.length > 0) {
              // @ts-ignore
              _self.$refs.listRepresentativeCountries.updateValue(selected);
            }
          }
        }
      });
  }

  updateListAllowedMops() {
    // We need to get the list of available companies for the current logged in user
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/eurofiscalis/workers/listAllowedMops", options)
      .then((response: any) => {
        if (response.mops) {
          this.allowedMOPs = response.mops;
        }
      });
  }

  countryPackageSignature(countryPackage:any) {
    return countryPackage.fixedAmount ? 'f|' + countryPackage.fixedAmount : 'w|' + countryPackage.perWorkerAmount
  }

  selectDefaultPackages() {
    this.form.representativeCountries.forEach((c:any) => {
      console.log(c)
      console.log(this.listRepresentativeCountries)
      var country = this.listRepresentativeCountries.find((country: any) => {
        return c.object.code === country.code;
      });

      if(country.workerPackages) {
        c.package = this.countryPackageSignature(country.workerPackages[0]);
      }
    });
  }

  @Watch("$store.state.languages.currentLanguageCode")
  onLanguageChange(to: any, from: any) {
    this.updateListRepresentativeCountries();
  }

  addValidation() {
    var componentInstance = this;

    extend("promoCode", {
      validate(promoCode): Promise<boolean | string> {
        if (!promoCode || promoCode == "") {
          componentInstance.showNumberOfWorkers = false;
          componentInstance.businessIntroducer = null;
          return Promise.resolve(true);
        }

        var input = {
          promoCode: promoCode
        };

        var options: api.ApiOptions = {
          app: componentInstance
        };

        return new Promise((resolve, reject) => {
          api
            .postAPI(
              "/api/businessIntroducer/validatePromoCode",
              input,
              options
            )
            .then((response: any) => {
              if (response.introducer) {
                componentInstance.showNumberOfWorkers =
                  response.introducer.isNumberOfWorkersNeeded;
                componentInstance.businessIntroducer = response.introducer;
              } else {
                componentInstance.showNumberOfWorkers = false;
                componentInstance.businessIntroducer = null;
              }
              return resolve(true);
            })
            .catch(error => {
              return resolve(error);
            });
        });
      }
    });

    extend("vatNumberValidation", {
      validate: vatNumber => {
        if (!vatNumber || vatNumber == "") {
          return {
            valid: false
          };
        }

        var countryCode: string = vatNumber.substring(0, 2);
        var number: string = vatNumber.substring(2, vatNumber.length);

        var input = {
          countryCode: countryCode,
          vatNumber: number
        };

        var options: api.ApiOptions = {
          app: this
        };

        return api
          .postAPI("/api/utils/checkVat", input, options)
          .then(response => {
            if (response.valid) {
              return true;
            } else {
              return {
                valid: false
              };
            }
          })
          .catch(err => {
            console.log("err", err);
            return {
              valid: false
            };
          });
      },
      message: this.$t("common.error.fields.vatNumber") as string
    });
  }

  onRegisterFormSubmit() {
    // We check if there is a promoCode and if not if we are allowed
    if (
      this.isPromoCode &&
      this.businessIntroducer === null &&
      !this.isRegisterWithoutPromoConfirmed
    ) {
      // @ts-ignore
      this.$refs.confirmEmptyPromoCodeModal.show();
      return;
    }

    var options: api.ApiOptions = {
      app: this
    };

    this.form.businessIntroducer = this.businessIntroducer;

    this.laddaSubmit!.start();

    api
      .postAPI("/api/eurofiscalis/workers/register", this.form, options)
      .then((response: any) => {
        if (response.paymentRedirectionForm) {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            response.paymentRedirectionForm
          );
          // We switch to the payment page
          this.$router.push("./payment");
        } else if (response.mop && response.mop == "TRANSFER") {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            {
              mop: response.mop,
              requestId: response.requestId
            }
          );
          // We switch to the bank-transfer page
          this.$router.push("./bank-transfer");
        }

        this.laddaSubmit!.stop();
      });
  }

  confirmRegisterWithoutPromo() {
    // We update the flag to allow register without promo code
    this.isRegisterWithoutPromoConfirmed = true;
    // We submit the form
    (this.$refs.registerForm as HTMLFormElement).dispatchEvent(
      new Event("submit")
    );
  }

  showConditionsModal() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }

  addRepresentativeCountry() {
    if (this.canAddRepresentativeCountry) {
      this.form.representativeCountries.push({
        numberOfWorkers: null,
        object: {}
      });
    }
  }

  deleteRepresentativeCountry(index: any) {
    if (this.canDeleteRepresentativeCountry) {
      this.form.representativeCountries.splice(index, 1);
    }
  }

  getTermsOfSalesDocumentURL(representativeCountry: any) {
    var url =
      "/api/representativeCountries/" +
      representativeCountry._id +
      "/termsOfSales/" +
      this.$store.state.languages.currentLanguageCode +
      "/download";
    return url;
  }
}

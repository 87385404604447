
























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { languagesTypes } from '@client/store/types';



@Component({
  components: { 
    
  },
  computed: {
    ...mapGetters({
            currentLanguage : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
    })
  }
})
export default class ConditionsModal extends Vue {

  show() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }
}

import Vue from 'vue';
import Vuex from 'vuex';

import { createLanguagesStore } from '@client/store/languages';
import { createRouterStore } from '@client/store/router';
import { createMessagesStore } from '@client/store/messages';
import { createMetaStore } from '@client/store/meta';

import { hooks } from '../hooks';

Vue.use(Vuex);

declare var process: any;

export function createStore (initialState:any) {

  // We create the modules. This is needed to support SSR.
  const languages = createLanguagesStore();

  const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
      languages
    },
    state: {},
    mutations: {},
    actions: {}
  });

  // We create the router store
  const routerStore = createRouterStore(store);
  store.registerModule("router", routerStore);

  // We create the messages store
  const messagesStore = createMessagesStore();
  store.registerModule("messages", messagesStore);

  // We create the meta store
  const metaStore = createMetaStore();
  store.registerModule("meta", metaStore);


  if(hooks.onStoreCreated) {
    hooks.onStoreCreated(store, initialState);
  }

  // We override the state if initial state is given (from ssr)
  // TODO - Check if we should move this outside of the store before the Vue app creation in client.ts
  if(initialState) {
    store.replaceState(initialState);
  }

  return store;
}
import { Module } from 'vuex';
import * as api from '@client/utils/api';
import { authenticationTypes as types } from '@client/store/types';

const privateTypes = {
  mutations : {
    AUTH_ERROR : 'AUTH_ERROR',
    AUTH_LOGOUT : 'AUTH_LOGOUT'
  }
}

/**
 * This is a generic authentication store which can be used by any application.
 * To use it, the store must be registered in the hooks.ts file of the application.
 *   var authenticationStore = createAuthenticationStore();
 *   store.registerModule("authentication", authenticationStore);
 */
export function createAuthenticationStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      isLoggedIn : false,
      user : null
    },
    mutations: {
      [types.mutations.AUTH_SUCCESS] (state, user) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - AUTH_SUCCESS");
        }
        // We update the store
        state.isLoggedIn = true;
        state.user = user;
      },
      [types.mutations.UPDATE_USER] (state, user) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - UPDATE_USER");
        }
        // We update the store
        state.user = user;
      },
      [privateTypes.mutations.AUTH_ERROR] (state) {
        // We update the store
        state.isLoggedIn = false;
        state.user = null;
      },
      [privateTypes.mutations.AUTH_LOGOUT] (state) {
        // We update the store
        state.isLoggedIn = false;
        state.user = null;
      }
    },
    actions: {
      [types.actions.AUTH_LOGOUT]({commit}, {app}):Promise<any> {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - AUTH_LOGOUT");
        }
        var options:api.ApiOptions =  {
          app: app
        }
        return api.getAPI('/api/auth/logout',options).then((response:any) => {
          commit(privateTypes.mutations.AUTH_LOGOUT);
          return Promise.resolve();
        })
      }
    }
  }
  return store;
};
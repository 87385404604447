import Vue from 'vue';
import { Module } from 'vuex';

import { loadLanguageBundleAsync, loadRouteLanguageBundlesAsync, getAvailableLanguages, loadReferences } from '@client/languages';
import { utils as RouterUtils } from '@client/router/utils';
import { languagesTypes as types, metaTypes } from '@client/store/types';

export enum References {
  COUNTRIES = "COUNTRIES"
}

const privateTypes = {
  mutations : {
    SET_TRANSLATIONS : 'SET_TRANSLATIONS',
    SET_REFERENCES : 'SET_REFERENCES',
    SET_LANGUAGE : 'SET_LANGUAGE'
  },
  actions : {
    
  }
}

export function createLanguagesStore () {
  const languageStore:Module<any, any> = {
    namespaced: true,
    state: {
      beforeSetLanguageActions: [], // It contains list of global store actions called before we set language.
      afterSetLanguageActions: [], // It contains list of global store actions called after we set language.
      currentLanguageCode: null,
      availableLanguages: getAvailableLanguages(), // We get the available language from application.json config.
      /**
       * flag for each type of loaded translations bundle to avoid doing it again..
       * ex:
       *  loadedBundles: {
       *    fr: ["bundle1", "bundle2"]
       *  }
       */
      loadedBundles: {},
      /**
       * flag for each type of loaded translations reference to avoid doing it again..
       * ex:
       *  loadedReferences: {
       *    fr: [References.COUNTRIES]
       *  }
       */
      loadedReferences: {},
      /**
       * translations contains for each language the language bundle + all route bundles loaded.
       * ex:
       *  translations: {
       *    fr: {
       *      key : value
       *      subKey (ex: route bundle) : {
       *        key : value
       *      }
       *    }
       *  }
       */
      translations: {},
      /**
       * references contains for each language the references localized data.
       * ex:
       *  references: {
       *    fr: {
       *      COUNTRIES : {}
       *    }
       *  }
       */
      references: {},
    },
    getters: {
      /**
       * IS_AVAILABLE_LANGUAGE
       * This getter check if language code in parameter is available for translations
       */
      [types.getters.IS_AVAILABLE_LANGUAGE]: (state) => (requestedLanguage:string):boolean =>  {
        return state.availableLanguages[requestedLanguage] != null;
      },
      /**
       * GET_AVAILABLE_LANGUAGES
       * This getter returns all available language codes.
       */
      [types.getters.GET_AVAILABLE_LANGUAGES]: (state):Array<string> =>  {
        return Object.keys(state.availableLanguages);
      },
      /**
       * GET_CURRENT_LANGUAGE
       * This getter get the current language information
       * @param state 
       */
      [types.getters.GET_CURRENT_LANGUAGE] (state):any {
        return state.availableLanguages[state.currentLanguageCode];
      }
    },
    mutations: {
        [privateTypes.mutations.SET_LANGUAGE] (state, {languageCode, app}) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - LANGUAGES - MUTATION - SET_LANGUAGE - " + languageCode);
          }
          // We update the store
          state.currentLanguageCode = languageCode;
          // This trigger the plugin to update the resources used.
          app.$i18n.locale = languageCode;
        },
        [privateTypes.mutations.SET_TRANSLATIONS] (state, payload) {
          var {languageCode, app, translations, bundles} = payload;
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - LANGUAGES - MUTATION - SET_TRANSLATIONS - " + languageCode + " - " + bundles.join(", "));
          }
          // We update the translations
          updateTranslationsInStore(state, {languageCode, translations, bundles});
          // We update the $i18n messages
          app.$i18n.mergeLocaleMessage(languageCode, {
            ...translations
          });
        },
        [privateTypes.mutations.SET_REFERENCES] (state, payload) {
          var {languageCode, references, app} = payload;
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - LANGUAGES - MUTATION - SET_REFERENCES - " + languageCode + " - " + Object.keys(references).join(", "));
          }
          // We update the references
          updateReferencesInStore(state, {languageCode, references});
          // We update the $i18n messages
          app.$i18n.mergeLocaleMessage(languageCode, {
            ...references
          });
        },
        /**
         * ADD_BEFORE_SET_LANGUAGE_ACTION
         * This method add a store action to be called before we set the language.
         * @param state - the state of the store
         * @param payload - the payload of the mutation with two possible parameters
         *    - action - a store action reference
         */
        [types.mutations.ADD_BEFORE_SET_LANGUAGE_ACTION] (state, payload) {
          // We add the action to the store
          state.beforeSetLanguageActions.push(payload.action);
        },
        /**
         * ADD_AFTER_SET_LANGUAGE_ACTION
         * This method add a store action to be called after we set the language.
         * @param state - the state of the store
         * @param payload - the payload of the mutation with two possible parameters
         *    - action - a store action reference
         */
        [types.mutations.ADD_AFTER_SET_LANGUAGE_ACTION] (state, payload) {
          // We add the action to the store
          state.afterSetLanguageActions.push(payload.action);
        }
    },
    actions: {
      /**
       * SET_DEFAULT_LANGUAGE
       * This action is triggered to set the defautl language. It has to be called before any route is pushed in the router.
       * @param context - the action context (state, commit)
       * @param payload - the payload for this action (acceptedLanguages, defaultLanguage)
       */
      [types.actions.SET_DEFAULT_LANGUAGE]({state, commit, dispatch}, {acceptedLanguages, app}):void {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - LANGUAGES - SET_DEFAULT_LANGUAGE");
        }

        const availableLanguages = state.availableLanguages;
        var defaultLanguage = Object.keys(availableLanguages)[0];
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - LANGUAGES - SET_DEFAULT_LANGUAGE - Default language: "+defaultLanguage);
        }

        // We check to get the first available language in the list of accepted languages
        if(acceptedLanguages) {
          for(let languageCode of acceptedLanguages) {
            if(availableLanguages[languageCode]) {
              defaultLanguage = languageCode;
              break;
            }
          }
        }

        commit(privateTypes.mutations.SET_LANGUAGE, {
          languageCode: defaultLanguage,
          app: app
        });
      },
      /**
       * UPDATE_LANGUAGE
       * This action is triggered when we want to switch the language.
       * @param context - the action context (state, commit)
       * @param payload - the payload for this action (languageCode, app)
       */
      [types.actions.UPDATE_LANGUAGE]({state, commit, dispatch}, {languageCode, app, route}):Promise<any[]> {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - LANGUAGES - UPDATE_LANGUAGE");
        }
        if(!route) {
          route = app.$router.currentRoute;
        }
        var beforeHandlerPromises:any[] = [];
        state.beforeSetLanguageActions.forEach(function (action:string) {
          var payload = {
            languageCode : languageCode
          }
          var result = app.$store.dispatch(action, payload);
          beforeHandlerPromises.push(result);
        });
        var afterHandlerPromises:any[] = [];
        state.afterSetLanguageActions.forEach(function (action:string) {
          var payload = {
            languageCode : languageCode
          }
          var result = app.$store.dispatch(action, payload);
          afterHandlerPromises.push(result);
        });
        return Promise.all([
          ...beforeHandlerPromises,
          dispatch(types.actions.CHECK_BUNDLE_LANGUAGE, {
            languageCode: languageCode,
            app: app
          }),
          dispatch(types.actions.CHECK_ROUTE_LANGUAGE, {
            languageCode: languageCode,
            route: route,
            app: app
          }),
        ]).then(() => {
          // We update the language in store once we have all bundle loaded
          commit(privateTypes.mutations.SET_LANGUAGE, {
            languageCode: languageCode,
            app: app
          });
          return Promise.all([
            ...afterHandlerPromises
          ])
        })
        .catch(error => {
          console.log("STORE - LANGUAGES - UPDATE_LANGUAGE - Error: " + error);
          return Promise.resolve([]);
        });
      },
      /**
       * CHECK_BUNDLE_LANGUAGE
       * This action is triggered when we want to check if 
       */
      [types.actions.CHECK_BUNDLE_LANGUAGE] ({state, commit, rootState}, {languageCode, app}) {
        if(!languageCode) {
          languageCode = state.currentLanguageCode;
        }
        var loadedBundles = state.loadedBundles;

        // If there is nothing about the language loaded or the common bundle is not loaded
        if(!loadedBundles[languageCode] ||
            !loadedBundles[languageCode].includes(types.COMMON_BUNDLE)) {
          return loadLanguageBundleAsync(languageCode).then((result:any) => {
            var data = {
              "languageCode" : result.languageCode,
              "translations" : result.translations,
              "bundles" : result.bundles,
              "app": app
            }
            commit(privateTypes.mutations.SET_TRANSLATIONS, data);
            commit('meta/' + metaTypes.mutations.UPDATE_META_FROM_TRANSLATIONS, data, { root: true });
          })
        }
        return Promise.resolve();
      },
      /**
       * CHECK_ROUTE_LANGUAGE
       * This action is triggered when we want to check if route language is available.
       * @param context - the action context (state, commit)
       * @param payload - the payload for this action (route, app)
       * @returns Promise object once route bundle is loaded.
       */
      [types.actions.CHECK_ROUTE_LANGUAGE] ({state, commit}, {languageCode, route, app}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - LANGUAGES - CHECK_ROUTE_LANGUAGE");
        }
        if(!languageCode) {
          languageCode = state.currentLanguageCode;
        }
        var routeBundles = RouterUtils.getLanguageBundlesFromRoute(route);
        var routeReferences = RouterUtils.getLanguageReferencesFromRoute(route);
        var loadedBundles = state.loadedBundles[languageCode] ? state.loadedBundles[languageCode] : [];
        var loadedReferences = state.loadedReferences[languageCode] ? state.loadedReferences[languageCode] : [];

        var routeLanguagePromises:any[] = [];

        if(routeBundles) {
          // We check that we need to load
          var missingBundles = getMissingBundles(routeBundles, loadedBundles);
          if(missingBundles.length > 0) {
            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - LANGUAGES - CHECK_ROUTE_LANGUAGE - Missing route bundles: "+missingBundles.join(", "));
            }
            // In case there is no route specific bundle in translations
            routeLanguagePromises.push(loadRouteLanguageBundlesAsync(languageCode, missingBundles).then(result => {
              var data = {
                "languageCode" : result.languageCode,
                "translations" : result.translations,
                "bundles" : result.bundles,
                "app": app
              }
              commit(privateTypes.mutations.SET_TRANSLATIONS, data);
              commit('meta/' + metaTypes.mutations.UPDATE_META_FROM_TRANSLATIONS, data, { root: true });
            }))
          }
        }
        if(routeReferences) {
          // We check what we need to load
          var missingReferences = getMissingReferences(routeReferences, loadedReferences);
          if(missingReferences.length > 0) {
            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - LANGUAGES - CHECK_ROUTE_LANGUAGE - Missing route references: "+missingReferences.join(", "));
            }
            // In case there is no route specific reference 
            routeLanguagePromises.push(loadReferences(languageCode, missingReferences, app).then(references => {
              var data = {
                "languageCode" : languageCode,
                "references" : references,
                "app": app
              }
              commit(privateTypes.mutations.SET_REFERENCES, data);
            }))
          }
        }
        if(routeLanguagePromises.length > 0) {
          return Promise.all(routeLanguagePromises);
        }
        return Promise.resolve();
      }
    }
  };

  return languageStore;
};

function getMissingBundles(routeBundles:string[], loadedBundles:string[]):string[] {
  // We keep the bundles which are associated to the route but not found in loaded languages
  return routeBundles.filter((value:string) => -1 == loadedBundles.indexOf(value))
}

function getMissingReferences(routeReferences:References[], loadedReferences:string[]):References[] {
  // We keep the references which are associated to the route but not found in loaded languages
  return routeReferences.filter((value:string) => -1 == loadedReferences.indexOf(value))
}

/**
 * updateTranslationsInStore
 * This method update the store with retrieved translations from route and bundle associated languages.
 * @param state - the language store state.
 * @param payload - languageCode, translations, loadedBundles
 */
function updateTranslationsInStore(state:any, payload:any) {
  var {languageCode, translations, bundles} = payload;
  if(translations !== undefined) {
    
    // merge the translations
    if(state.translations[languageCode]) {
      state.translations[languageCode] = {
        ...state.translations[languageCode],
        ...translations
      };
    }
    else {
      Vue.set(state.translations, languageCode, {...translations});
    }
    
    // We add the bundle loaded to the list
    if(state.loadedBundles[languageCode]) {
      state.loadedBundles[languageCode] = [...state.loadedBundles[languageCode], ...bundles];
    }
    else {
      Vue.set(state.loadedBundles, languageCode, [...bundles])
    }
  }
}

/**
 * updateReferencesInStore
 * This method update the store with retrieved references from route.
 * @param state - the language store state.
 * @param payload - languageCode, references
 */
function updateReferencesInStore(state:any, payload:any) {
  var {languageCode, references} = payload;
  if(references !== undefined) {
    
    // merge the references
    if(state.references[languageCode]) {
      state.references[languageCode] = {
        ...state.references[languageCode],
        ...references
      };
    }
    else {
      Vue.set(state.references, languageCode, {...references});
    }
    
    // We add the references loaded to the list
    if(state.loadedReferences[languageCode]) {
      state.loadedReferences[languageCode] = [...state.loadedReferences[languageCode], ...Object.keys(references)];
    }
    else {
      Vue.set(state.loadedReferences, languageCode, [...Object.keys(references)])
    }
  }
}
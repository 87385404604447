import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import App from '@client/App.vue';
import { createStore } from '@client/store';
import { createRouter } from '@client/router';
import { createI18n } from '@client/languages';
import '@client/utils/class-component-hooks';

import { hooks } from './hooks';

export function createApp (initialState:any) {

  // We call the onCreateAppEnter hooks if defined.
  if(hooks.onCreateAppEnter) {
    hooks.onCreateAppEnter(Vue, initialState);
  }

  // We get the store instance
  const store = createStore(initialState);
  // We create the i18n instance
  const i18n = createI18n(store);
  // We get the router instance
  const router = createRouter(store);
  
  // Syncronize the router and the store of VueX
  sync(store, router);

  // We call the hook with all dependencies
  if(hooks.onVueDependenciesCreated) {
    hooks.onVueDependenciesCreated(store, router, i18n, initialState);
  }

  const app:Vue = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeCreate () {
      // beforeCreate is called on client and server code
    },
    mounted () {
      // mounted is only called on client code.
      // You'll need this for renderAfterDocumentEvent (cf. PrerenderSPAPlugin in webpack config).
      document.dispatchEvent(new Event('render-event'))
    }
  });

  return { app, router, store, i18n }
}

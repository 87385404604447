



















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis';
import * as api from '@client/utils/api';

@Component({
})
export default class BankTransfer extends mixins(GenericPage) {

  year = (new Date()).getFullYear();

  workerRequest = null;
  requestLoading = false;


  get registerPayment() {
    return this.$store.getters['eurofiscalis/' + eurofiscalisTypes.getters.GET_REGISTER_PAYMENT];
  }

  created() {
    // We get the worker request
    if(this.registerPayment && this.registerPayment.requestId) {
      var options:api.ApiOptions =  {
        app: this
      }

      this.requestLoading = true;

      api.getAPI('/api/eurofiscalis/workers/request/'+this.registerPayment.requestId, options).then((response:any) => {
        this.requestLoading = false;
        if(response.request) {
          this.workerRequest = response.request;
          console.log('workerRequest', this.workerRequest)
        }
        else {
          this.$router.push('./register');
        }
      })
    }
    else {
      // we do not have information in the store.
      this.$router.push('./register');
    }
  }

    onBackButtonClick() {
      this.$router.push('../home');
    }

}

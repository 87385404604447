



























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import NumberField from "../../../../panels/input/NumberField.vue";
import Checkbox from "../../../../panels/input/Checkbox.vue";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import moment from "moment";

@Component({
  components: {
    TextField,
    NumberField,
    Checkbox
  }
})
export default class Create extends Vue {
  attachmentUpload: any = null;
  listRepresentativeCountriesData = [];
  emptyForm: any = {
    title: "",
    countryCode: [],
    attachment: null,
    attachmentUrl: "",
    hasCode: false,
    isActive: true
  };

  form: any = {
    ...this.emptyForm
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  mounted() {
    this.getListRepresentativeCountries();
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=form-businessIntroducer] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  getListRepresentativeCountries() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/admin/representativeCountries/list", options)
      .then((response: any) => {
        this.listRepresentativeCountriesData = response.countries;
      });
  }

  getLabel(country:any) {
    return country.code;
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    const formData = new FormData();
    formData.append(
      'attachment',
      this.attachmentUpload,
      this.attachmentUpload.name
    );
    formData.append('input', JSON.stringify(this.form));

    this.laddaSubmit!.start();
    api
      .postAPI("/api/admin/attachment/create", formData, options)
      .then((response: any) => {
        if (response.created) {
          this.$emit("attachmentCreated", response.attachment);
          this.form = {
            ...this.emptyForm
          };
        }
        this.laddaSubmit!.stop();
      });
  }
}

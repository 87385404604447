









































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import NumberField from "../../../../panels/input/NumberField.vue";
import Checkbox from "../../../../panels/input/Checkbox.vue";
import RepresentativeCompany from "./FormRepresentativeCompany.vue";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import moment from "moment";

@Component({
  components: {
    TextField,
    NumberField,
    Checkbox,
    RepresentativeCompany
  }
})
export default class Create extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  representativeCountry!: any;

  fileToUploadFr: any = null;
  fileToUploadPl: any = null;
  uploadFieldName: string = "filesTermsOfSales";

  form: any = {
    code: this.representativeCountry.code,
    name: this.representativeCountry.name,
    unlimitedPackageAmount: this.representativeCountry.unlimitedPackageAmount,
    workerPackageAmount: this.representativeCountry.workerPackageAmount,
    representativeCompany: this.representativeCountry.representativeCompany,
    isActive: this.representativeCountry.isActive
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=form-representativeCountry] button.ladda-button"
    );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    const formData = new FormData();

    if (this.fileToUploadFr) {
      formData.append(
        this.uploadFieldName,
        this.fileToUploadFr,
        this.fileToUploadFr.name
      );
      formData.append("countries", "fr");
    }

    if (this.fileToUploadPl) {
      formData.append(
        this.uploadFieldName,
        this.fileToUploadPl,
        this.fileToUploadPl.name
      );
      formData.append("countries", "pl");
    }

    var input = {
      name: this.form.name,
      code: this.form.code,
      unlimitedPackageAmount: this.form.unlimitedPackageAmount,
      workerPackageAmount: this.form.workerPackageAmount,
      representativeCompany: this.form.representativeCompany,
      isActive: this.form.isActive
    };

    formData.append("input", JSON.stringify(input));

    this.laddaSubmit!.start();

    api
      .postAPIFormData(
        "/api/admin/representativeCountries/" +
          this.representativeCountry._id +
          "/update",
        formData,
        options
      )
      .then((response: any) => {
        if (response.updated) {
          this.$emit(
            "representativeCountryUpdated",
            response.representativeCountry
          );
        }
        this.laddaSubmit!.stop();
      });
  }
}

























































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { Component } from 'vue-property-decorator';
import { languagesTypes } from '@client/store/types';
import { enableMetismenu } from '../../theme/inspinia-jquery';
import MenuItem from './MenuItem.vue';

@Component({
  computed: {
    ...mapGetters({
          currentLanguage : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
  },
  components: {
    MenuItem
  }
})
export default class Menu extends Vue {
  activeClass = "active";

  get username() {
    if(this.$store.state.authentication.isLoggedIn) {
      const displayName = this.$store.state.authentication.user.profile.displayName;
      if(displayName && displayName != "") {
        return displayName;
      }
      else {
        return this.$store.state.authentication.user.login;
      }
    }
    return null;
  }

  get menuProfileLink() {
    if(this.$store.state.authentication.isLoggedIn && 
        this.$store.state.authentication.user.profile && 
        this.$store.state.authentication.user.profile.company) {
      return this.$store.state.authentication.user.profile.company.name
    }
    return this.$t("menu.profileDropdown.link");
  }

  mounted() {
    // We enable metis menu
    enableMetismenu(this.$store);
  }

  get treeModulesAsNodes() {
    const storeModules = this.$store.state.application.availableModules;
    var treeModules:any = {};

    for(var modulePath of storeModules) {
      var split = modulePath.split("/");
      var root:any = treeModules;

      for(var index = 0 ; index < split.length ; index ++) {
        if(index == split.length - 1) {
          // We are at the end of the tree
          root[split[index]] = modulePath;
        }
        else {
          // We build the tree
          if(!root[split[index]]) {
            root[split[index]] = {}
          }
          // We move to the sub tree
          root = root[split[index]]
        }
      }
    }
    var nodes = this.transformModuleToNodes(treeModules);
    return nodes;
  }

  transformModuleToNodes(treeModules:any, parentKey:string = ""):any[] {
    var nodes = [];
    var treeModuleKeys = Object.keys(treeModules);
    for(var key of treeModuleKeys) {
      var fullKey = parentKey != "" ? parentKey + "/" + key : key;
      var treeModule = treeModules[key];
      if(typeof treeModule === "object") {
        nodes.push({
          labelKey: fullKey,
          nodes: this.transformModuleToNodes(treeModule, fullKey),
          expanded: treeModuleKeys.length == 1
        })
      }
      else {
        nodes.push({
          labelKey: fullKey,
          path: treeModule
        })
      }
    }
    return nodes;
  }

  onProfileLinkClick() {
    // We force closing the menu as it does not close when clicking on this link the first time.
    // @ts-ignore
    this.$refs.profile.hide(true)
  }

}














































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import CreateBusiniessIntroducer from "./Create.vue";
import UpdateBusiniessIntroducer from "./Update.vue";

@Component({
  components: {
    CreateBusiniessIntroducer,
    UpdateBusiniessIntroducer
  }
})
export default class ListBusinessIntroducer extends Vue {
  selectedBusinessIntroducer:any=null;
  ListBusinessIntroducerLoading: boolean = false;
  sortBy = "name";
  sortDesc = false;

  ListBusinessIntroducerData: any[] = [];

  ListBusinessIntroducerFields = [
    {
      key: "name",
      label: "",
      sortable: true
    },
    {
      key: "code",
      label: "",
      sortable: true
    },
    {
      key: "unlimitedPackageAmount",
      label: "",
      sortable: true
    },
    {
      key: "workerPackageAmount",
      label: "",
      sortable: true
    },
    {
      key: "workerPackageMaxNumberOfWorker",
      label: "",
      sortable: true
    },
    {
      key: "workerPackages",
      label: "",
      sortable: true
    },
    {
      key: "isActive",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: ""
    }
  ];

  mounted() {
    this.ListBusinessIntroducerFields[0].label = this.$t(
      "admin_settings.business_introducer.headers.name"
    ) as string;
    this.ListBusinessIntroducerFields[1].label = this.$t(
      "admin_settings.business_introducer.headers.code"
    ) as string;
    this.ListBusinessIntroducerFields[2].label = this.$t(
      "admin_settings.business_introducer.headers.unlimitedPackageAmount"
    ) as string;
    this.ListBusinessIntroducerFields[3].label = this.$t(
      "admin_settings.business_introducer.headers.workerPackageAmount"
    ) as string;
    this.ListBusinessIntroducerFields[4].label = this.$t(
      "admin_settings.business_introducer.headers.workerPackageMaxNumberOfWorker"
    ) as string;
    this.ListBusinessIntroducerFields[5].label = this.$t(
      "admin_settings.business_introducer.headers.workerPackages"
    ) as string;
    this.ListBusinessIntroducerFields[6].label = this.$t(
      "admin_settings.business_introducer.headers.isActive"
    ) as string;
    this.getListBusinessIntroducer();
  }

  getListBusinessIntroducer() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/admin/businessIntroducer/list", options)
      .then((response: any) => {
        this.ListBusinessIntroducerData = response.businessIntroducers;
      });
  }

  addBusinessIntroducer() {
    // @ts-ignore
    this.$refs.createBusinessIntroducerModal.show();
  }

  updateBusinessIntroducer(businessIntroducer:any) {
    this.selectedBusinessIntroducer = businessIntroducer;
    // @ts-ignore
    this.$refs.updateBusinessIntroducerModal.show();
  }

  onBusinessIntroducerCreated() {
    // @ts-ignore
    this.$refs.createBusinessIntroducerModal.hide();
    this.getListBusinessIntroducer();
  }

  onBusinessIntroducerUpdated() {
    // @ts-ignore
    this.$refs.updateBusinessIntroducerModal.hide();
    this.getListBusinessIntroducer();
  }
}


























































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import Contracts from '../panels/company/Contracts.vue';
import RenewContract from '../panels/eurofiscalis/company/RenewContract.vue';
import { DateUtils } from '@server/api/utils/DateUtils';
import * as api from "@client/utils/api";

@Component({
  components: {
    RenewContract,
    Contracts,
    CompanyInfo : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/Info.vue'),
    FaqContent : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/FaqContent.vue'),
  }
})
export default class Home extends mixins(GenericPage) {
  isUpgradeAccountAllowed:boolean=false
  pendingRequest:boolean=false
  activeTab:number = 0;
  isDismissed:boolean = false;
  remindDaysBefore = [
    30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16,
    15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0
  ];
  workerContracts:any[] = [];
  workerRequests:any[] = [];

  mounted() {
    this.initLoad();
  }

  initLoad() {
    this.workerContracts = this.fetchWorkerContracts();
    this.fetchWorkerRequests();
    this.checkUpgradeAccountAllowed();
  }

  dismiss(workerContractID:String, remainingDays:Number) {
    var dismissedReminders = this.remindDaysBefore.filter(d => d >= remainingDays);
    api
      .postAPI(
        "/api/eurofiscalis/workerContracts/" +
          workerContractID +
          "/setDismissedReminders",
        { dismissedReminders: dismissedReminders },
        { app: this }
      )
      .then((response: any) => {
        this.workerContracts = response.workerContracts;
      });
  }

  get showExpiringAlert() {
    return (this.expiringContracts.length !== 0) && (!this.pendingRequest);
  }

  get noActiveNorRenewedContracts() {
    return this.workerRequests.length === 0 && this.activeContracts.length === 0;
  }

  get activeContracts() {
    return this.workerContracts.filter(workerContract => {
      return workerContract.remainingDays > 0 && !workerContract.isExpired;
    });
  }

  get expiringContracts() {
    var currentDate = new Date();
    return this.activeContracts.filter(contract => {
      if (contract.remainingDays > 30) {
        return false;
      }
      var isRenewed = this.workerContracts.find(c => {
        return c.representativeCountry.code === contract.representativeCountry.code &&
               Date.parse(c.startDate) > currentDate.getTime();
      });
      if (isRenewed) {
        return false;
      }
      let remindersToDismiss = this.remindDaysBefore.filter(d => d >= contract.remainingDays); console.log(remindersToDismiss);
      let leftReminders = remindersToDismiss.filter(r => (contract.dismissedReminders || []).indexOf(r) === -1);

      if (leftReminders.length === 0) {
          return false;
      }

      return true;
    });
  }

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  get isListInvoices() {
    return this.user && this.user.profile && this.user.profile.company;
  }

  get isListWorkers() {
    return this.workerContracts.length > 0;
  }

  get migratedToMobilityPacket() {
    console.log( !!(this.user.profile.company && this.user.profile.company.migratedToMobilityPacket));
    return !!(this.user.profile.company && this.user.profile.company.migratedToMobilityPacket);
  }

  checkUpgradeAccountAllowed() {
    this.isUpgradeAccountAllowed = true;

    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI('/api/eurofiscalis/workers/company/'+ this.user.profile.company._id + '/checkUpgradeAccount', options).then((response:any) => {
      this.isUpgradeAccountAllowed = response.isUpgradeAccountAllowed;
      this.pendingRequest = response.pendingWorkerRequest;
    })
  }

  showRenewalModal() {
    // @ts-ignore
    this.$refs.renewalModal.show()
  }

  fetchWorkerContracts() {
    return this.$store.state.eurofiscalis.workers.contracts;
  }

  fetchWorkerRequests() {
    if(this.$store.state.eurofiscalis.workers.workerRequests && this.$store.state.eurofiscalis.workers.workerRequests.length > 0) {
      this.workerRequests = this.$store.state.eurofiscalis.workers.workerRequests;
    } else {
      var options:api.ApiOptions =  {
        app: this
      }

      api.getAPI(
        '/api/eurofiscalis/company/'+this.user.profile.company._id+'/workerRequests',
        options
      ).then((response:any) => {
        if(response.workerRequests) {
          this.workerRequests = response.workerRequests;
        }
      });
    }
  }

  onMigrateToMobilityPacket(companyID:any) {
    var options:api.ApiOptions =  {
      app: this
    }

    api.postAPI(
      '/api/eurofiscalis/company/' + companyID + '/migrateToMobilityPacket',
      {},
      options
    ).then((response:any) => {
      this.initLoad();
      window.location.reload();
    });
  }
}

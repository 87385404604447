














import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Checkbox extends mixins<GenericInput<boolean>>(GenericInput) {

  inputLabel = "";
  inputPlaceholder = "";

  labelCSSClass = "col col-form-label"
  fieldCSSClass = "col-auto";
  rowCSSClass = {"form-group":true, "row":true, "no-gutters":true};

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : "";
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  get validationRules() {
    if(this.required) {
      return { required: { allowFalse: false } }
    }
    else {
      return {}
    }
  }

  
  
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"role":"form","name":"update-profile"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"updateCivility"}},[_vm._v(_vm._s(_vm.$t('profile.info.civility')))]),_vm._v(" "),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.civility),expression:"form.civility"}],staticClass:"form-control",attrs:{"id":"updateCivility","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "civility", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.civilities),function(civilityCode,index){return _c('option',{key:index,domProps:{"value":civilityCode}},[_vm._v(_vm._s(_vm.$t('civilities.short.'+civilityCode)))])}),0)])]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"lastName","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('label',{attrs:{"for":"updateLastName"}},[_vm._v(_vm._s(_vm.$t('profile.info.lastName')))]),_vm._v(" "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",attrs:{"id":"updateLastName","required":"","type":"text","placeholder":_vm.$t('profile.info.lastName_placeholder')},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"firstName","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('label',{attrs:{"for":"updateFirstName"}},[_vm._v(_vm._s(_vm.$t('profile.info.firstName')))]),_vm._v(" "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",attrs:{"id":"updateFirstName","required":"","type":"text","placeholder":_vm.$t('profile.info.firstName_placeholder')},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"updateMiddleName"}},[_vm._v(_vm._s(_vm.$t('profile.info.middleName')))]),_vm._v(" "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.middleName),expression:"form.middleName"}],staticClass:"form-control",attrs:{"id":"updateMiddleName","type":"text","placeholder":_vm.$t('profile.info.middleName_placeholder')},domProps:{"value":(_vm.form.middleName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "middleName", $event.target.value)}}})])]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"position","mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('label',{attrs:{"for":"position"}},[_vm._v(_vm._s(_vm.$t('profile.info.position')))]),_vm._v(" "),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.position),expression:"form.position"}],staticClass:"form-control",attrs:{"id":"position","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "position", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('profile.info.position_select')))]),_vm._v(" "),_vm._l((_vm.positions),function(position,index){return _c('option',{key:index,domProps:{"value":position}},[_vm._v(_vm._s(_vm.$t('profile.positions.'+position)))])})],2),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"updatePhone"}},[_vm._v(_vm._s(_vm.$t('eurofiscalis_workers.register.form.phone')))]),_vm._v(" "),_c('div',[_c('Phone',{attrs:{"phone":_vm.form.phone,"required":""},on:{"update:phone":function($event){return _vm.$set(_vm.form, "phone", $event)}}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"col-lg-6"},[_c('UpdateAddress',{attrs:{"address":_vm.form.company.address,"options":_vm.addressOptions},on:{"update:address":function($event){return _vm.$set(_vm.form.company, "address", $event)}}}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"VAT number","mode":"eager","immediate":"","skipIfEmpty":false,"rules":_vm.vatNumberValidationRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var valid = ref.valid;
return [_c('div',{class:Object.assign({}, {'form-group':true}, classes)},[_c('label',{attrs:{"for":"vatNumber"}},[_vm._v(_vm._s(_vm.$t('company.info.vatNumber')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.company.vatNumber),expression:"form.company.vatNumber"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('company.info.vatNumber_placeholder'),"id":"vatNumber","name":"vatNumber","autocomplete":"vatNumber"},domProps:{"value":(_vm.form.company.vatNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.company, "vatNumber", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_vm._v(" "),(valid)?_c('span',{staticClass:"control-label form-text m-b-none"},[_vm._v(_vm._s(_vm.vatDetails))]):_vm._e()])]}}],null,true)})],1)]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button",attrs:{"disabled":invalid,"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.$t('profile.info.save')))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import Vue from 'vue';

import { messagesTypes, MessageTypes, Message } from '@client/store/types';
import {downloadFileFromString} from '@client/utils/browser';

export interface ApiOptions {
    app:Vue,
    errorsHandler?:Function,
    successesHandler?:Function,
    fileHandler?:Function,
    axios?:any
    localized?:boolean,
    languageCode?:string // to force a specific language
}

/**
 * computePath
 * This method compute the final path to be requested. It adds localization if available.
 * @param path - the path provided as input
 * @param options - the API options
 */
function computePath(path:string, options:ApiOptions) {
    var localized = true;
    if(options.localized != undefined) {
        localized = options.localized;
    }
    if(!localized) {
        // We do not need to localize the path.
        return path;
    }

    // We localize the path
    var languageCode = options.app.$store.state.languages.currentLanguageCode;
    if(options.languageCode) {
        // We force the language
        languageCode = options.languageCode;
    }
    var localizedPath = "";
    if(path.substr(0,1) == "/") {
        localizedPath = "/" + languageCode + path;
    }
    else {
        localizedPath = languageCode + "/" + path;
    }
    return localizedPath;
}

function computeOptions(path:string, options:ApiOptions):AxiosRequestConfig {
    var axiosOptions:AxiosRequestConfig = {
        ...options.axios
    }
    if(process.env.VUE_ENV == "server") {
        // In case of SSR rendering, we need to add specific options for Axios
        var entrySSR = require('@server/entry-ssr');
        axiosOptions = {
            ...axiosOptions,
            ...entrySSR.getAxiosOptions(path)
        }
    }

    return axiosOptions;
}

export function getAPI(path:string, options:ApiOptions):Promise<any> {
    var promise = axios.get(computePath(path, options), computeOptions(path, options));
    return handleAPICall(promise, options);
}

export function postAPI(path:string, input:any, options:ApiOptions):Promise<any> {
    var promise = axios.post(computePath(path, options), input, computeOptions(path, options));
    return handleAPICall(promise, options);
}

export function postAPIFormData(path:string, formData:FormData, options:ApiOptions, input?:any):Promise<any> {
    if(input) {
        formData.append("input", JSON.stringify(input));
    }
    var promise = axios.post(computePath(path, options), formData, computeOptions(path, options));
    return handleAPICall(promise, options);
}

function handleAPICall(promise:Promise<any>, options:ApiOptions):Promise<any> {
    return promise
    .then((response:AxiosResponse<any>) => {
        // We handle API Errors
        handleAPIErrors(response, options);
        // We handle API Successes
        handleAPISuccesses(response, options);
        // We handle API Successes
        handleAPIFile(response, options);
        // We handle redirection
        if(response.data.redirect) {
            // Router.push returns a promise with exception if the current url is the one we push.
            options.app.$router.push(response.data.redirect.action).catch(err => {});
        }
        // We return the data
        return response.data.response;
    })
    .catch((error:any) => {
        if(error.response) {
            // server responded with HTTP status code error
            handleAPIErrors(error.response, options);
        }
        else {
            if(process.env.CONSOLE == "LOG") {
                console.log("APIS - HANDLE API CALL - ERROR");
                console.log(error);
            }
            // We need to add a generic error
            var message = "API error";
            if(options.app.$te('api.generic-error')) {
                message = options.app.$t('api.generic-error') as string;
            }

            options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_GENERIC_MESSAGE, {
                message: message,
                type: MessageTypes.ERROR
            });
        }
        return {};
    });
}

function handleAPIErrors(response:AxiosResponse<any>, options:ApiOptions) {
    if(response.data.errors && response.data.errors.length > 0) {
        if(options.errorsHandler) {
            // In case we have a custom errors handler
            options.errorsHandler.call(null, response.data.errors);
        }
        else {
            // We need to add errors to the list of messages in the store
            options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_MESSAGES, response.data.errors);
        }
    }
    else if(response.status && response.status != 200 && response.status != 304 && response.status != 204 && response.statusText) {
        var message = "API error";
        if(options.app.$te('api.generic-error')) {
            message = options.app.$t('api.generic-error') as string;
        }
        message += " ("+response.status + ") "+response.statusText;
        // options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_GENERIC_MESSAGE, {
        //     message: message,
        //     type: MessageTypes.ERROR
        // });

        if(process.env.CONSOLE == "LOG") {
            console.log("APIS - HANDLE API ERRORS - ERROR");
            console.log(response);
        }
    }
}

function handleAPISuccesses(response:AxiosResponse<any>, options:ApiOptions) {
    if(response.data.successes && response.data.successes.length > 0) {
        if(options.successesHandler) {
            // In case we have a custom successes handler
            options.successesHandler.call(null, response.data.successes);
        }
        else {
            // We need to add errors to the list of messages in the store
            options.app.$store.commit('messages/' + messagesTypes.mutations.ADD_MESSAGES, response.data.successes);
        }
    }
}

function handleAPIFile(response:AxiosResponse<any>, options:ApiOptions) {
    // If the file is passed as part of the IWRequest object
    if(response.data.fileToDownload) {
        var fileToDownload = response.data.fileToDownload;
        if(options.fileHandler) {
            // In case we have a custom file handler
            options.fileHandler.call(null, fileToDownload);
        }
        else {
            // We force the browser to download the file
            downloadFileFromString(fileToDownload.content, fileToDownload.fileName, fileToDownload.contentType);
        }
    }

    // If the response is a file in itself
    if(response.headers['x-download-filename']) {
        var contentType = response.headers['content-type'];
        var data = response.data;
        if(response.headers['x-download-encoding'] && response.headers['x-download-encoding'] == 'base64') {
            data = base64ToArrayBuffer(data);
        }
        downloadFileFromString(data, response.headers['x-download-filename'], contentType);
    }
}

function base64ToArrayBuffer(data:string) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

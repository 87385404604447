




























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListWorkerContracts from '../../panels/admin/workerContracts/ListWorkerContracts.vue';


@Component({
  components: { 
    PageTitle,
   ListWorkerContracts
  }
})
export default class workerContracts extends mixins(GenericPage) {}

















































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as api from "@client/utils/api";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import { types as eurofiscalisTypes } from "../../../../store/eurofiscalis";
import Checkbox from "../../../panels/input/Checkbox.vue";
import RepresentativeCountryPicker from "../../input/RepresentativeCountry.vue";
import UpdateAddress from "../../../panels/UpdateAddress.vue";
import {
  PaymentMethod,
  contractDurationInMonth,
  currencyCode
} from "../../../../types/common";
import { extend } from "vee-validate";
import moment from 'moment'

@Component({
  components: {
    RepresentativeCountryPicker,
    Checkbox,
    UpdateAddress
  }
})
export default class Create extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  readonly company!: Object;

  @Prop({
    type: Array,
    required: true
  })
  readonly workerContracts!: any[];

  amountByMonth: number = 0;
  representativeCountries: any[] = [];
  allowedMOPs: string[] = [];
  businessIntroducer: any = null;
  showNumberOfWorkers = false;
  contractDurationInMonth:number = contractDurationInMonth;
  isRegisterWithoutPromoConfirmed = false;

  addressOptions = {
    showName: false,
    labelAsColumn: true,
    nbAddressLines: 2
  };
  vatDetails: any = "";

  form: any = {
    representativeCountries: [],
    company: {},
    conditions: false,
    mop: '',
    promoCode: "",
    businessIntroducer: null
  };

  laddaSubmit: Ladda.LaddaButton | null = null;

  availableListRepresentativeCountries(countryCode: any) {
    return this.representativeCountries.filter(
      (c: any) =>
        !this.selectedRepresentativeCountriesCode.includes(c.code) ||
        c.code === countryCode
    );
  }

  get selectedRepresentativeCountriesCode() {
    const allowedCountries = this.representativeCountries.map(c => c.code);
    return this.form.representativeCountries.map((c: any) => {
      return allowedCountries.includes(c.countryCode) ? c.countryCode : null;
    }).filter(Boolean);
  }

  get canAddRepresentativeCountry() {
    return (
      (this.form.representativeCountries.length < this.representativeCountries.length)
    );
  }

  created() {
    this.addValidation();
  }

  mounted() {
    this.updateListAllowedMops();
    this.getRepresentativeCountries();
    this.form.company = this.company;

    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=renew-contract] button.ladda-button"
    );

    this.laddaSubmit = Ladda.create(button!);
  }

  nextContractDatesFor(countryCode: string) {
    var startDate:Date = new Date();
    var endDate:Date = new Date(startDate);
    var latestContractForCountry = this.latestContractForCountry(countryCode);
    if (latestContractForCountry) {
      startDate = new Date(Date.parse(latestContractForCountry.endDate));
    }

    var endDateMoment = moment(startDate);
    endDateMoment.add(6, 'months');

    return {
      startDate: this.momentDate(startDate),
      endDate: this.momentDate(endDateMoment.toDate())
    }
  }

  latestContractForCountry(countryCode: string) {
    return this.workerContracts.concat().sort((a, b) => {
      return Date.parse(b.endDate) - Date.parse(a.endDate);
    }).find(workerContract => {
      return workerContract.representativeCountry.code === countryCode
    });
  }

  selectedCountryPackages(countryCode: string) {
    var country = this.representativeCountries.find(
      repCountry => repCountry.code === countryCode
    );

    if (!country || !country.workerPackages) {
      return [];
    }

    if (this.businessIntroducer !== null && this.businessIntroducer.workerPackages) {
      return this.businessIntroducer.workerPackages;
    }


    return country.workerPackages;
  }

  countryPackageSignature(countryPackage:any) {
    return countryPackage.fixedAmount ? 'f|' + countryPackage.fixedAmount : 'w|' + countryPackage.perWorkerAmount
  }

  canDeleteRepresentativeCountry(country: any) {
    return this.form.representativeCountries.length > 1;
  }

  addRepresentativeCountry() {
    if (this.canAddRepresentativeCountry) {
      this.form.representativeCountries.push({
        numberOfWorkers: "more",
        allowedNumberOfWorkers: 0,
        countryCode: ''
      });
    }
  }

  recalculateAmount() {
    this.amountByMonth = this.calculateAmountByMonth();
  }

  deleteRepresentativeCountry(index: any) {
    if (
      this.canDeleteRepresentativeCountry(
        this.form.representativeCountries[index]
      )
    ) {
      this.form.representativeCountries.splice(index, 1);
      this.recalculateAmount();
    }
  }

  get maxNumberOfWorker() {
    return this.businessIntroducer !== null
      ? this.businessIntroducer.workerPackageMaxNumberOfWorker
      : null;
  }

  minNumberOfWorkerForCountry(countryCode: string) {
    if(this.$store.state.authentication.user.roles.indexOf('eurofiscalis-workers-admin') !== -1) {
      return 1;
    }

    var latestContractForCountry = this.latestContractForCountry(countryCode);
    return (latestContractForCountry && latestContractForCountry.allowedNumberOfWorkers) || 0;
  }

  momentDate(date:Date){
    return moment(date).format('DD/MM/YYYY');
  }

  updateListAllowedMops() {
    // We need to get the list of available companies for the current logged in user
    var options: api.ApiOptions = {
      app: this
    };
    api
      .getAPI("/api/eurofiscalis/workers/listAllowedMops", options)
      .then((response: any) => {
        if (response.mops) {
          this.allowedMOPs = response.mops;
        }
      });
  }

  getRepresentativeCountries() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/representativeCountries/list", options)
      .then((response: any) => {
        if (response.countries) {
          this.representativeCountries = response.countries;
          this.resetFormDefaults();
        }
      });
  }

  resetFormDefaults() {
    var representativeCountries:any = [];
    this.workerContracts.forEach((w: any) => {
      var defaultPackage = null;
      var defaultNumberOfWorkers = w.allowedNumberOfWorkers === "Infinity" ? "more" : w.allowedNumberOfWorkers;
      var countryAlreadyAdded = representativeCountries.find((r: any) => {
        return r.countryCode === w.representativeCountry.code
      });

      if (countryAlreadyAdded) {
        return;
      }

      var country = this.representativeCountries.find(r => {
        return r.code === w.representativeCountry.code
      });

      if (!country) {
        return;
      }

      if (country.workerPackages) {
        var minWorkers = this.minNumberOfWorkerForCountry(w.representativeCountry.code);
        var countryPackage = this.selectedCountryPackages(w.representativeCountry.code).find((p: any) => {
          return p.maxWorkers >= minWorkers || p.maxWorkers === null
        });

        defaultPackage = this.countryPackageSignature(countryPackage);
        defaultNumberOfWorkers = countryPackage.maxWorkers || 'more';
      }

      representativeCountries.push({
        countryCode: w.representativeCountry.code,
        numberOfWorkers: defaultNumberOfWorkers,
        allowedNumberOfWorkers:
          w.allowedNumberOfWorkers === "Infinity"
            ? "more"
            : w.allowedNumberOfWorkers,
        package: defaultPackage
      });
    });

    // EU only
    if(representativeCountries.length === 0 && this.representativeCountries.length === 1) {
      var representativeCountry = this.representativeCountries[0];
      representativeCountries.push({
        countryCode: representativeCountry.code,
        numberOfWorkers: 1,
        allowedNumberOfWorkers: 'more',
        package: this.countryPackageSignature(representativeCountry.workerPackages[0])
      });
    }

    this.form.representativeCountries = representativeCountries;
    this.recalculateAmount();
  }

  confirmRegisterWithoutPromo() {
    // We update the flag to allow register without promo code
    this.isRegisterWithoutPromoConfirmed = true;
    // We submit the form
    (this.$refs.renewContractForm as HTMLFormElement).dispatchEvent(
      new Event("submit")
    );
  }

  onFormSubmit(evt: Event) {
    evt.preventDefault();

    var options: api.ApiOptions = {
      app: this
    };

    if (
      this.businessIntroducer === null &&
      !this.isRegisterWithoutPromoConfirmed
    ) {
      // @ts-ignore
      this.$refs.confirmEmptyPromoCodeModal.show();
      return;
    }

    this.form.businessIntroducer = this.businessIntroducer;
    this.form.workerContracts = this.workerContracts;

    this.laddaSubmit!.start();

    api
      .postAPI(
        "/api/eurofiscalis/workers/company/" +
          this.company._id +
          "/renewContract",
        this.form,
        options
      )
      .then((response: any) => {
        if (response.paymentRedirectionForm) {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            response.paymentRedirectionForm
          );
          // We switch to the payment page
          this.$router.push("/workers/payment");
        } else if (response.mop && response.mop == "TRANSFER") {
          // We update the eurofiscalis store
          this.$store.commit(
            "eurofiscalis/" +
              eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT,
            {
              mop: response.mop,
              requestId: response.requestId
            }
          );
          // We switch to the bank-transfer page
          this.$router.push("/workers/bank-transfer");
        }

        this.laddaSubmit!.stop();
      });
  }

  getTermsOfSalesDocumentURL(countryCode: any) {
    const country = this.representativeCountries.find(c => c.code === countryCode);
    var url =
      "/api/representativeCountries/" +
      country._id +
      "/termsOfSales/" +
      this.$store.state.languages.currentLanguageCode +
      "/download";
    return url;
  }

  get vatNumberValidationRules() {
    return {
      required: true,
      vatNumberValidation: true
    };
  }

  calculateAmountByMonth() {
    var amount = 0;
    var unlimitedPackageAmount = 0;
    var workerPackageAmount = 0;
    var country;

    if (this.form.representativeCountries.length === 0) {
      return amount;
    }

    this.form.representativeCountries.forEach((c: any) => {
      if (c.package) {
        var packageAmount = parseInt(c.package.slice(2));
        if (c.package[0] === 'w') {
          unlimitedPackageAmount = packageAmount * c.numberOfWorkers;
        } else {
          unlimitedPackageAmount = packageAmount;
        }
      } else {
        if (this.businessIntroducer !== null) {
          if (this.showNumberOfWorkers && c.numberOfWorkers !== "more") {
            workerPackageAmount = this.businessIntroducer.workerPackageAmount;
            amount += workerPackageAmount * c.numberOfWorkers;
          } else {
            unlimitedPackageAmount = this.businessIntroducer.unlimitedPackageAmount;
            amount += unlimitedPackageAmount;
          }
        } else {
          country = this.representativeCountries.find(
            repCountry => repCountry.code === c.countryCode
          );
          if (country) {
            unlimitedPackageAmount = country.unlimitedPackageAmount;
          }
        }
      }

      amount += unlimitedPackageAmount;
    });

    return amount;
  }

  addValidation() {
    var componentInstance = this;

    extend("promoCode", {
      validate(promoCode): Promise<boolean | string> {
        if (!promoCode || promoCode == "") {
          componentInstance.showNumberOfWorkers = false;
          componentInstance.businessIntroducer = null;
          componentInstance.resetFormDefaults();
          return Promise.resolve(true);
        }

        var input = {
          promoCode: promoCode
        };

        var options: api.ApiOptions = {
          app: componentInstance
        };

        return new Promise((resolve, reject) => {
          api
            .postAPI(
              "/api/businessIntroducer/validatePromoCode",
              input,
              options
            )
            .then((response: any) => {
              if (response.introducer) {
                componentInstance.showNumberOfWorkers =
                  response.introducer.isNumberOfWorkersNeeded;
                componentInstance.businessIntroducer = response.introducer;
              } else {
                componentInstance.showNumberOfWorkers = false;
                componentInstance.businessIntroducer = null;
              }

              componentInstance.resetFormDefaults();
              return resolve(true);
            })
            .catch(error => {
              return resolve(error);
            });
        });
      }
    });

    extend("vatNumberValidation", {
      validate: vatNumber => {
        if (!vatNumber || vatNumber == "") {
          return {
            valid: false
          };
        }

        var countryCode: string = vatNumber.substring(0, 2);
        var number: string = vatNumber.substring(2, vatNumber.length);

        var input = {
          countryCode: countryCode,
          vatNumber: number
        };

        var options: api.ApiOptions = {
          app: this
        };

        return api
          .postAPI("/api/utils/checkVat", input, options)
          .then(response => {
            if (response.valid) {
              return true;
            } else {
              return {
                valid: false
              };
            }
          })
          .catch(err => {
            console.log("err", err);
            return {
              valid: false
            };
          });
      },
      message: this.$t("common.error.fields.vatNumber") as string
    });
  }
}

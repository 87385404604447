






































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes, languagesTypes } from '@client/store/types';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis'
import PageTitle from '../../PageTitle.vue';
import CreateWorker from '../workers/Create.vue';
import UpdateWorker from '../workers/Update.vue';
import WorkerDuplicate from '../workers/Duplicate.vue';
import WorkerDetails from '../workers/Details.vue'
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery';

@Component({
  computed : {

  },
  components: {
    PageTitle,
    CreateWorker,
    UpdateWorker,
    WorkerDetails,
    WorkerDuplicate
  }
})
export default class ListWorkers extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  @Prop({
    type: Object,
    required: true
  }) readonly workerContract!: any

  @Prop({
    type: Array,
    required: true
  }) readonly workerContracts!: any[]

  emptyWorker:any = {
    firstName : "",
    lastName : ""
  };

  workerToReview:any = {...this.emptyWorker};
  workerToUpdate:any = {...this.emptyWorker};
  workerToRemove:any = {...this.emptyWorker};
  workerToDuplicate:any = {...this.emptyWorker};

  listWorkersLoading = false;
  isAddWorkerAllowed = false;

  listWorkersSelected = [];

  filter = null;
  rows = 0;
  workerFields = [
      {
        key: "index",
        label: "#"
      },
      {
        key: "firstName",
        label: ""
      },
      {
        key: "lastName",
        label: ""
      },
      {
        key: "formattedDateOfBirth",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
    ];

  get canDupplicate() {
    return this.workerContracts.length > 1
  }

  mounted() {
    this.updateTableLabels();
    enableIbox(this.$refs.listWorkers as HTMLElement);
  }

  updateTableLabels() {
    this.workerFields[0].label = '#';
    this.workerFields[1].label = this.$t('eurofiscalis_workers.worker.headers.first-name') as string;
    this.workerFields[2].label = this.$t('eurofiscalis_workers.worker.headers.last-name') as string;
    this.workerFields[3].label = this.$t('eurofiscalis_workers.worker.headers.birth-date') as string;
    this.workerFields[4].label = this.$t('eurofiscalis_workers.worker.headers.creation-date-time') as string;
    this.workerFields[5].label = this.$t('eurofiscalis_workers.worker.headers.id') as string;
    this.workerFields[6].label = this.$t('eurofiscalis_workers.worker.headers.options') as string;
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  beforeDestroy() {
    disableIbox(this.$refs.listWorkers as HTMLElement);
  }

  workersProvider(ctx:any) {
    var options:api.ApiOptions =  {
      app: this
    }

    this.listWorkersLoading = true;

    var url = '/api/eurofiscalis/workers/company/' + this.companyId + '/contract/' + this.workerContract._id + '/workers';
    if(ctx.filter) {
      url += '?filter=' + ctx.filter;
    }

    return api.getAPI(url, options).then((response:any) => {
      this.listWorkersLoading = false;
      if(response.workers) {
        // We update the flag for permissions
        this.isAddWorkerAllowed = response.isAddWorkerAllowed;
        this.$store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_SET_NUMBER_OF_WORKERS, response.workers.length);

        this.rows = response.workers.length;
        return response.workers;
      }
      else {
        return [];
      }
    })
  }

  showCreateWorkerModal() {
    // @ts-ignore
    this.$refs.createWorkerModal.show()
  }

  showUpgradeAccountModal() {
    // @ts-ignore
    this.$refs.upgrageAccountModal.show()
  }

  showWorkerUpdateModal(worker:any) {
    this.workerToUpdate = worker;
    // @ts-ignore
    this.$refs.updateWorkerModal.show()
  }

  showWorkerRemoveConfirmModal(worker:any) {
    this.workerToRemove = worker;
    // @ts-ignore
    this.$refs.removeWorkerModal.show()
  }

  removeWorker() {
    var options:api.ApiOptions =  {
      app: this
    }

    this.listWorkersLoading = true;

    api.getAPI('/api/eurofiscalis/workers/company/'+this.companyId+'/worker/'+this.workerToRemove._id+'/remove', options).then((response:any) => {
      if(response.removed) {
        // We update the list of workers
        // @ts-ignore
        this.$refs.listWorkersTable.refresh()
      }
      // We reset the role to remove
      this.workerToRemove = {...this.emptyWorker};

      this.listWorkersLoading = false;
    });
  }

  onWorkerCreated() {
    // @ts-ignore
    this.$refs.createWorkerModal.hide()
    // @ts-ignore
    this.$refs.listWorkersTable.refresh()
  }

  onWorkerUpdated() {
    // @ts-ignore
    this.$refs.listWorkersTable.refresh()
  }

  onWorkerDuplicated() {
    // @ts-ignore
    this.$refs.workerDuplicateModal.show()
  }

  showWorkerDetailsModal(worker:any) {
    this.workerToReview = worker;
    // @ts-ignore
    this.$refs.workerDetailsModal.show()
  }

  showDuplicateWorkerModal(worker:any) {
    this.workerToDuplicate = worker;
    // @ts-ignore
    this.$refs.workerDuplicateModal.show()
  }

}

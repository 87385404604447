




































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as api from '@client/utils/api';

@Component({
  components: { }
})
export default class UpdateAddress extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly address!: any | undefined

  @Prop({
    type: Object,
    required: false,
  }) readonly options!: any | undefined

  computedOptions:any = {
    showName: true,
    labelAsColumn: true,
    nbAddressLines: 3,
    ...this.options
  };

  nbAddressLines:number = this.address && this.address.addressLines ? Math.max(this.address.addressLines.length, this.computedOptions.nbAddressLines) : this.computedOptions.nbAddressLines;

  listCountries:any[] = [];

  form = this.address ? {
    name: this.address.name,
    addressLines : this.address.addressLines,
    postalCode : this.address.postalCode,
    city : this.address.city,
    countryCode : this.address.countryCode.toUpperCase()
    } : {
    name : '',
    addressLines : this.defaultAddressLines,
    postalCode : '',
    city : '',
    countryCode : ''
  };

  get defaultAddressLines():string[] {
    var lines = [];
    for(var i = 0 ; i < this.computedOptions.nbAddressLines ; i++) {
      lines.push('');
    }
    return lines;
  }

  labelCSSClass = this.computedOptions.labelAsColumn ? "col-lg-3 col-form-label" : null
  fieldCSSClass = this.computedOptions.labelAsColumn ? "col-lg-9" : null;
  rowCSSClass = this.computedOptions.labelAsColumn ? "form-group row" : "form-group";

  created() {
    this.updateListCountries();
  }

  updateListCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listEUCountries', options).then((response:any) => {
      if(response.countries) {  
        this.listCountries = response.countries;
        var _self = this;
        if(_self.form.countryCode && _self.form.countryCode != "") {
          // We get the value from countryCode
          var selected = this.listCountries.find(function(element) {
            return element.code == _self.form.countryCode;
          });
          if(selected) {
            // @ts-ignore
            _self.$refs.listCountries.updateValue(selected);
          }
        }
      }
    });
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListCountries();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:address', val);
  }
  
}

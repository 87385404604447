import { Store } from 'vuex';
import Vue from 'vue';
import { authenticationTypes, messagesTypes, Message, MessageTypes } from '@client/store/types';
import { types as applicationTypes } from '../store/application';
import { types as eurofiscalisTypes } from '../store/eurofiscalis';


import toastr from 'toastr';

/**
 * updateApplicationDataForLoggedInUser
 * This method is called for logged in user to update application with data needed associated to the logged in user.
 * It should be called after user is logged in or when loading application with logged in user
 * @param store - the store instance.
 * @param applicationData - the computed application data generated by server.hooks.buildApplicationDataForLoggedInUser.
 *    - modules - the list of modules the user is allowed to access
 *    - user - the logged in user information
 *    - isLanguageSwitchAllowed - the possibility to switch language.
 *    - workerContract - the worker contract if available for the user company.
 */
export function updateApplicationDataForLoggedInUser (store:Store<any>, applicationData:any) {
    // We update the authentication
    store.commit('authentication/' + authenticationTypes.mutations.AUTH_SUCCESS, applicationData.user);
    // We update the possibility to switch language
    store.commit('application/' + applicationTypes.mutations.SET_IS_LANGUAGE_SWITCH_ALLOWED, applicationData.isLanguageSwitchAllowed);
    // We provide the list of  modules
    store.commit('application/' + applicationTypes.mutations.SET_AVAILABLE_MODULES, applicationData.modules);
    // We update the worker contract if any
    if(applicationData.workerContracts) {
        store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_SET_ACTIVE_CONTRACTS, applicationData.workerContracts);
    }
    // We update the worker contract if any
    if(applicationData.workerRequests) {
        store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_SET_REQUESTS, applicationData.workerRequests);
    }
}

/**
 * toastrMessages
 * This method display all messages in messages store within toastr notification and clear the messages
 * from the store.
 * @param app - the vue application
 */
export function toastrMessages(app:Vue) {
    var messages = app.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
    if(messages.length > 0) {
        toastr.options = {
            progressBar: true
        };
        for(var message of messages) {
            console.log(message);
            if(message.type == MessageTypes.ERROR) {
                toastr.error(message.formattedMessage);
            }
            else if(message.type == MessageTypes.SUCCESS) {
                toastr.success(message.formattedMessage);
            }
            else if(message.type == MessageTypes.WARNING) {
                toastr.warning(message.formattedMessage)
            }
        }
        // We clear the messages as consummed
        app.$store.commit('messages/'+messagesTypes.mutations.CLEAR_MESSAGES);
    }
}

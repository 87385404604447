


































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListRepresentativeCountries from '../../panels/admin/settings/representativeCountry/List.vue';
import ListBusinessIntroducer from '../../panels/admin/settings/businessIntroducer/List.vue';
import ListAttachment from '../../panels/admin/settings/attachment/List.vue';

@Component({
  components: {
    PageTitle,
    ListRepresentativeCountries,
    ListBusinessIntroducer,
    ListAttachment
  }
})
export default class Settings extends mixins(GenericPage) {}




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as api from '@client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class ResetPassword extends Vue {

  form = {
    email: ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var resetPasswordButton:HTMLButtonElement|null = document.querySelector( 'form[name=reset-password] button.ladda-button.reset-password' );
    this.laddaSubmit = Ladda.create(resetPasswordButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "email" : this.form.email
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/reset-password', input, options).then((response:any) => {
      if(response.reseted) {
        // We emit event as password is reseted
        this.$emit('password-reseted');
      }
      this.form.email = '';
      this.laddaSubmit!.stop();
    });
  }
  
}

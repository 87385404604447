




































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import UploadWorkerDocument from './FileUpload.vue'
import * as Ladda from 'ladda';

@Component({
  components: { 
    UploadWorkerDocument
  }
})
export default class Details extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly worker!: any

  listWorkerDocumentsLoading = false;

  listWorkerDocumentsSelected = [];

  readonly emptyWorkerDocument:any = {
    "fileName" : ""
  };

  workerDocumentToRemove:any = {...this.emptyWorkerDocument};

  workerDocumentFields = [
      /*{
        key: "select",
        label: ""
      },*/
      {
        key: "fileName",
        label: ""
      },
      {
        key: "category",
        label: "",
        formatter:(categoryCode:string) => {
          return this.getCategoryLabel(categoryCode);
        }
        
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "mime",
        label: "",
        formatter:(mime:string) => {
          return this.getMimeLabel(mime);
        }
      },
      {
        key: "_id",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
    ];

  getCategoryLabel(categoryCode:string) {
    return this.$t('eurofiscalis_workers.worker.documents.categories.'+categoryCode) as string;
  }

  getMimeLabel(mime:string) {
    if(!mime) {
      return this.$t('eurofiscalis_workers.worker.documents.mime-unkown') as string;
    }
    return mime;
  }

  mounted() {
    this.updateTableLabels();
  }

  updateTableLabels() {
    this.workerDocumentFields[0].label = this.$t('eurofiscalis_workers.worker.documents.name') as string;
    this.workerDocumentFields[1].label = this.$t('eurofiscalis_workers.worker.documents.category') as string;
    this.workerDocumentFields[2].label = this.$t('eurofiscalis_workers.worker.documents.creation-date') as string;
    this.workerDocumentFields[3].label = this.$t('eurofiscalis_workers.worker.documents.format') as string;
    this.workerDocumentFields[4].label = this.$t('eurofiscalis_workers.worker.documents.id') as string;
    this.workerDocumentFields[5].label = this.$t('eurofiscalis_workers.worker.documents.options') as string; 
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  workerDocumentsProvider(ctx:any) {
    var options:api.ApiOptions =  {
      app: this
    }

    this.listWorkerDocumentsLoading = true;

    return api.getAPI('/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/documents', options).then((response:any) => {
      this.listWorkerDocumentsLoading = false;
      if(response.documents) {  
        return response.documents;
      }
      else {
        return [];
      }
    })
  }

  getWorkerDocumentURL(document:any) {
    var url = '/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/document/'+document._id+'/download';
    return url;
  }

  confirmRemoveWorkerDocument(document:any) {
    this.workerDocumentToRemove = document;
    // @ts-ignore
    this.$refs.removeWorkerDocumentModal.show()
  }

  removeWorkerDocument() {

    var options:api.ApiOptions =  {
      app: this
    }

    this.listWorkerDocumentsLoading = true;
    
    api.getAPI('/api/eurofiscalis/workers/company/'+this.worker.company._id+'/worker/'+this.worker._id+'/document/'+this.workerDocumentToRemove._id+'/remove', options).then((response:any) => {
      if(response.removed) {
        // We update the list of worker documents
        // @ts-ignore
        this.$refs.listWorkerDocuments.refresh()
      }
      // We reset the role to remove
      this.workerDocumentToRemove = {...this.emptyWorkerDocument};

      this.listWorkerDocumentsLoading = false;
    });
  }

  showAddWorkerDocumentModal() {
    // @ts-ignore
    this.$refs.uploadWorkerDocumentModal.show()
  }

  onWorkerDocumentsUploaded() {
    // @ts-ignore
    this.$refs.listWorkerDocuments.refresh();
  }

  
}

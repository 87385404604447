













































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextField from "../../../../panels/input/TextField.vue";
import Date from "../../../../panels/input/Date.vue";
import Phone from '../../../../panels/contact/phones/Phone.vue';
import UpdateAddress from '../../../../panels/UpdateAddress.vue';
import { extend } from "vee-validate";
import * as api from '@client/utils/api';

@Component({
  components: {
    TextField,
    Phone,
    UpdateAddress,
    Date
  }
})
export default class FormRepresentativeCompany extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  representativeCompany!: Object;

  addressOptions = {
    showName: false,
    labelAsColumn: false,
    nbAddressLines: 2
  }

  vatDetails:any = "";

  created() {
    extend("vatNumberValidation", {
      validate: vatNumber => {
        if (!vatNumber || vatNumber == "") {
          return {
            valid: true
          };
        }

        var countryCode: string = vatNumber.substring(0, 2);
        var number: string = vatNumber.substring(2, vatNumber.length);

        var input = {
          countryCode: countryCode,
          vatNumber: number
        };

        var options: api.ApiOptions = {
          app: this
        };

        return api
          .postAPI("/api/utils/checkVat", input, options)
          .then(response => {
            if (response.valid) {
              return true;
            } else {
              return {
                valid: false
              };
            }
          })
          .catch(err => {
            console.log("err", err);
            return {
              valid: false
            };
          });
      },
      message: this.$t("common.error.fields.vatNumber") as string
    });
  }

  get vatNumberValidationRules() {
    return {
      vatNumberValidation: true
    }
  }
}

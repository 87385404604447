












































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as api from "@client/utils/api";
import $ from "jquery";
import { extend } from "vee-validate";
import { messagesTypes, MessageTypes } from "@client/store/types";
import * as Ladda from "ladda";
import Phone from "../../contact/phones/Phone.vue";
import UpdateAddress from "../../../panels/UpdateAddress.vue";
import { positions, PhoneTypes } from "../../../../types/common";

@Component({
  components: {
    Phone,
    UpdateAddress
  }
})
export default class UpdateProfile extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  userProfile!: any;

  civilities = ["MR", "MRS"];
  positions = positions;
  addressOptions = {
    showName: false,
    labelAsColumn: false,
    nbAddressLines: 2
  };
  vatDetails: any = "";
  laddaSubmit: Ladda.LaddaButton | null = null;

  form = {
    firstName: this.userProfile.firstName,
    middleName: this.userProfile.middleName,
    lastName: this.userProfile.lastName,
    civility: this.userProfile.civility ? this.userProfile.civility : undefined,
    position: this.userProfile.position ? this.userProfile.position : undefined,
    phone: this.userProfile.phones
      ? this.userProfile.phones[0]
      : {
          type: PhoneTypes.BUSINESS,
          number: ""
        },
    company: {
      vatNumber: this.userProfile.company.vatNumber,
      address: {
        addressLines: this.userProfile.company.address
          ? this.userProfile.company.address.addressLines
          : [],
        postalCode: this.userProfile.company.address
          ? this.userProfile.company.address.postalCode
          : "",
        city: this.userProfile.company.address
          ? this.userProfile.company.address.city
          : "",
        countryCode: this.userProfile.company.address
          ? this.userProfile.company.address.countryCode
          : ""
      }
    }
  };

  get vatNumberValidationRules() {
    return {
      required: true,
      vatNumberValidation: true
    };
  }

  mounted() {
    var button: HTMLButtonElement | null = document.querySelector(
      "form[name=update-profile] button"
    );
    this.laddaSubmit = Ladda.create(button!);

    this.addValidationVatNumber();
  }

  addValidationVatNumber() {
    var componentInstance = this;

    extend("vatNumberValidation", {
      validate: vatNumber => {
        if (!vatNumber || vatNumber == "") {
          return {
            valid: false,
            data: {
              errorMessage: "Obligatoire"
            }
          };
        }

        var countryCode: string = vatNumber.substring(0, 2);
        var number: string = vatNumber.substring(2, vatNumber.length);

        var input = {
          countryCode: countryCode,
          vatNumber: number
        };

        var options: api.ApiOptions = {
          app: this
        };

        return api
          .postAPI("/api/utils/checkVat", input, options)
          .then(response => {
            if (response.valid) {
              return true;
            } else {
              return {
                valid: false
              };
            }
          })
          .catch(err => {
            console.log("err", err);
            return {
              valid: false
            };
          });
      },
      message: this.$t("common.error.fields.vatNumber") as string
    });
  }

  onFormSubmit() {
    var profile = {
      _id: this.userProfile._id,
      firstName: this.form.firstName,
      middleName: this.form.middleName,
      lastName: this.form.lastName,
      civility: this.form.civility,
      position: this.form.position,
      phone: [this.form.phone],
      company: {
        _id: this.userProfile.company._id,
        address: this.form.company.address,
        vatNumber: this.form.company.vatNumber
      }
    };

    var options: api.ApiOptions = {
      app: this
    };

    this.laddaSubmit!.start();

    api
      .postAPI("/api/profile/updateAs", profile, options)
      .then((response: any) => {
        this.laddaSubmit!.stop();
        if (response.updated) {
          this.$emit("profile-updated");
        }
      });
  }
}

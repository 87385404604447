









































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import CreateAttachment from "./Create.vue";

@Component({
  components: {
    CreateAttachment
  }
})
export default class ListAttachment extends Vue {
  selectedBusinessIntroducer:any=null;
  listLoading: boolean = false;
  sortBy = "title";
  sortDesc = false;

  listData: any[] = [];
  attachmentToRemove:any = null;

  listFields = [
    {
      key: "title",
      label: "",
      sortable: true
    },
    {
      key: "countryCode",
      label: "",
      sortable: true
    },
    {
      key: "hasCode",
      label: "",
      sortable: true

    },
    {
      key: "isActive",
      label: "",
      sortable: true
    },
    {
      key: "attachmentUrl",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: "",
      sortable: false
    }
  ];

  mounted() {
    this.listFields[0].label = this.$t(
      "admin_settings.attachment.headers.title"
    ) as string;
    this.listFields[1].label = this.$t(
      "admin_settings.attachment.headers.countryCode"
    ) as string;
    this.listFields[2].label = this.$t(
      "admin_settings.attachment.headers.hasCode"
    ) as string;
    this.listFields[3].label = this.$t(
      "admin_settings.attachment.headers.isActive"
    ) as string;
    this.listFields[4].label = this.$t(
      "admin_settings.attachment.headers.attachmentUrl"
    ) as string;
    this.getListData();
  }

  getListData() {
    var options: api.ApiOptions = {
      app: this
    };

    api
      .getAPI("/api/admin/attachment/list", options)
      .then((response: any) => {
        this.listData = response.attachments;
      });
  }

  addAttachment() {
    // @ts-ignore
    this.$refs.createAttachmentModal.show();
  }

  onCreated() {
    // @ts-ignore
    this.$refs.createAttachmentModal.hide();
    this.getListData();
  }

  getDownloadURL(attachment:any) {
    return '/api/attachment/' + attachment._id + '/download';
  }

  confirmRemoveAttachment(attachment: any) {
    this.attachmentToRemove = attachment;
    // @ts-ignore
    this.$refs.confirmRemoveModal.show();
  }

  removeAttachment() {
    var options:api.ApiOptions =  {
      app: this
    }

    api.postAPI(
      "/api/admin/attachment/" + this.attachmentToRemove?._id + "/remove",
      {},
      options
    ).then((response: any) => {
      this.getListData();
    });
  }
}

const AdminEurofiscalis = () => import(/* webpackChunkName: "route-module-admin-eurofiscalis" */ '../components/modules/admin/Eurofiscalis.vue').then(m => m.default);
const Settings = () => import(/* webpackChunkName: "route-module-admin-settings" */ '../components/modules/admin/Settings.vue').then(m => m.default);
const WorkerContracts = () => import(/* webpackChunkName: "route-module-admin-workerContracts" */ '../components/modules/admin/WorkerContracts.vue').then(m => m.default);
const Invoices = () => import(/* webpackChunkName: "route-module-admin-Invoices" */ '../components/modules/admin/Invoices.vue').then(m => m.default);

export const routes = [
  {
    path: '/admin/eurofiscalis',
    component: AdminEurofiscalis,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: ['company','admin_companies','admin_eurofiscalis', 'profile', 'eurofiscalis_workers','admin_workerContracts', 'common'],
    }
  },
  {
    path: '/admin/workerContracts',
    component: WorkerContracts,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: ['admin_workerContracts', 'admin_companies', 'common'],
    }
  },
  {
    path: '/admin/settings',
    component: Settings,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: ['admin_settings', 'company', 'profile', 'eurofiscalis_workers', 'common'],
    }
  },
  {
    path: '/admin/invoices',
    component: Invoices,
    meta: {
      layout: "Portal",
      requiresAuth: true,
      scrollToTop: true,
      languageBundle: ['admin_invoices', 'admin_eurofiscalis', 'company', 'profile', 'eurofiscalis_workers', 'common'],
    }
  }
]
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import { Store } from 'vuex';
import { Component, RouteConfig } from 'vue-router/types/router';

import { utils } from '@client/router/utils';
import { routerTypes, languagesTypes } from '@client/store/types';
import { PAGE_NOT_FOUND } from '@client/router/utils';
import { getRoutes } from '@client/router/routes';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  ssrAppId : "1" // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});

/**
 * createRouter
 * This method create a new router instance
 */
export function createRouter (store:Store<any>) {

  // We get the routes
  var routes:RouteConfig[] = getRoutes(store);
  // We store them in the store to avoid need to regenerate them.
  store.commit('router/' + routerTypes.mutations.SET_ROUTES, routes);

  const router:VueRouter = new VueRouter({
    routes,
    scrollBehavior : function(to, next, savedPosition) {
      // We use specific implementation for scroll behavior
      return utils.scrollBehavior(to, next, savedPosition, router);
    },
    mode: 'history',
    linkActiveClass: 'current'
  });

  /**
   * Generic beforeEach router implementation
   * It is used to do all checks before entering the requested route.
   *    - Localization check
   *    - Layout check
   *    - authentication check
   */
  router.beforeEach((to, from, next) => {
    // We check if in the store we have the language available for the route.
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ")");
    }

    var promises = [];
    var isLanguageUpdated = false;

    // We check if we are displaying the first route
    var isFirstRoute = from.name == null;

    var requestedLanguage = to.params.lang;
    var isRequestedLanguageInPath = requestedLanguage != null && requestedLanguage!= "";
    var currentLanguage = router.app.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE].code;

    /**
     * Authentication check
     * We check if the user is allowed to display this page
     */
    if(to.meta && to.meta.requiresAuth) {
      if(!router.app.$store.state.authentication.isLoggedIn) {
        var redirectPath = "/login";
        // We check if we add localization in path.
        if(isRequestedLanguageInPath) {
          redirectPath = "/" + requestedLanguage + redirectPath;
        }
        else if(currentLanguage) {
          redirectPath = "/" + currentLanguage + redirectPath;
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ") - AUTHENTICATION CHECK FAILED REDIRECT TO: " + redirectPath);
        }
        // We redirect to the login page
        return next(redirectPath);
      }
    }

    /**
     * Language check
     * We check the language to be used for the next route to be displayed.
     */

    // We check if language is valid and available in the store
    // We update the language if not the current one
    if(isRequestedLanguageInPath &&
        currentLanguage != requestedLanguage) {
      if(process.env.CONSOLE == "LOG") {
        console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ") - UPDATE LANGUAGE TO: " + requestedLanguage);
      }
      // We call the UPDATE_LANGUAGE action of the language store
      var updateLanguagePromise = router.app.$store.dispatch('languages/' + languagesTypes.actions.UPDATE_LANGUAGE, {
          languageCode: requestedLanguage,
          app: router.app,
          route: to
      });
      // We add the update language in the list of promises to be processed.
      promises.push(updateLanguagePromise);
      isLanguageUpdated = true;
    }
    else {
      // We need to redirect to the localized version if any
      var redirectPath = "/" + currentLanguage + to.path;
      var redirectRoute = router.resolve(redirectPath).route;
      // We redirect if the route exists
      if(redirectRoute.name != PAGE_NOT_FOUND) {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ") - LANGUAGE REDIRECT TO: " + redirectPath);
        }
        return next(redirectPath);
      }
    }

    /**
     * Layout check
     * before each route, we check that the approriate layout is loaded if needed.
     */
    // We check the Layout to be displayed for next route
    if(to && to.meta && to.meta.layout && to.meta.layout != "") {
      var params = {
        layoutName : to.meta.layout
      }
      promises.push(router.app.$store.dispatch('router/' + routerTypes.actions.CHECK_LAYOUT, params));
    }

    /**
     * Localization check
     * before each route, we check that all needed translation is loaded for the target route and language
     * This is needed only if we are not updating the language as well as route bundle is loaded when language is updated.
     */
    if(!isLanguageUpdated) {
      promises.push(router.app.$store.dispatch('languages/' + languagesTypes.actions.CHECK_ROUTE_LANGUAGE, {
        app: router.app,
        route: to
      }));
    }

    Promise.all(promises)
      .then(() => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ") - NEXT");
        }
        next();
      })
      .catch((error) => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE EACH (To: " + to.fullPath + ") - ERROR: "+error);
        }
        next(error);
      });
  });

  /**
   * Asynchronous data
   * Before resolving route, we get the asynchronous data if needed.
   */
  router.beforeResolve((to, from, next) => {
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - BEFORE RESOLVE (To: " + to.fullPath + ")");
    }

    const matched:Component[] = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    let diffed = false
    const newComponents = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c))
    })

    if (!newComponents.length) {
      if(process.env.CONSOLE == "LOG") {
        console.log("ROUTER - BEFORE RESOLVE (To: " + to.fullPath + ") - NO NEW COMPONENT");
      }
      return next()
    }

    // We update components in router store which will load async data.
    router.app.$store.dispatch('router/' + routerTypes.actions.UPDATE_COMPONENTS, newComponents)
      .then(() => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE RESOLVE (To: " + to.fullPath + ") - NEXT");
        }
        next();
      })
      .catch(next);
  })

  return router;

}

import Vue, {VueConstructor} from "vue";
import { Store } from 'vuex';
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import { createApplicationStore, types as applicationTypes } from "./store/application";
import { createAuthenticationStore } from "@client/store/authentication";
import { languagesTypes } from "@client/store/types";
import { createLayoutStore } from "./store/layout";
import { createEurofiscalisStore } from "./store/eurofiscalis";
import moment from 'moment'

// We import Bootstrap-Vue components
import { BDropdown, BNavItemDropdown, BNavItem, BNav, BNavbarBrand, BNavbar, BCol,
  BRow, BContainer, BDropdownItem, BDropdownDivider, BTable, BModal, BFormFile, BTabs, BTab, BCard, BFormDatepicker, BPagination, BFormGroup, BInputGroup, BFormInput } from 'bootstrap-vue';

// @ts-ignore
import PrettyCheckbox from 'pretty-checkbox-vue';

import vSelect from 'vue-select';

import { hooks } from '@client/interfaces/hooks';

// We import the bootstrap 4 style
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// We import pretty checkbox style
import './theme/pretty-checkbox.scss';

// We import the metismenu style
import 'metismenu/dist/metisMenu.min.css';

// We import the ladda style
import 'ladda/dist/ladda-themeless.min.css';

// We import the vue-select style
import 'vue-select/dist/vue-select.css';

// We import the intl-tel-input stype
import 'intl-tel-input/build/css/intlTelInput.css';

// We import the toastr style
import 'toastr/toastr.scss';

// @ts-ignore
import VueAutosuggest from "vue-autosuggest";

// We import Vee Validate components
import {ValidationProvider,ValidationObserver, extend, configure, localize} from "vee-validate";
import { required, email } from 'vee-validate/dist/rules';

/**
 * onCreateAppEnter
 * This method is called first when we enter the createApp method of the client.ts
 * @param Vue
 * @param initialState
 */
hooks.onCreateAppEnter = function (Vue:VueConstructor<Vue>, initialState:any) {

  // We import the v-b-scrollspy component
  Vue.component('b-container', BContainer);
  Vue.component('b-col',BCol);
  Vue.component('b-row', BRow);
  Vue.component('b-navbar', BNavbar);
  Vue.component('b-navbar-brand', BNavbarBrand);
  Vue.component('b-nav', BNav);
  Vue.component('b-nav-item', BNavItem);
  Vue.component('b-nav-item-dropdown', BNavItemDropdown);
  Vue.component('b-dropdown', BDropdown);
  Vue.component('b-dropdown-item', BDropdownItem);
  Vue.component('b-dropdown-divider', BDropdownDivider);
  Vue.component('b-table', BTable);
  Vue.component('b-modal', BModal);
  Vue.component('b-form-file', BFormFile);
  Vue.component('b-tabs', BTabs);
  Vue.component('b-tab', BTab);
  Vue.component('b-card', BCard);
  Vue.component('b-form-datepicker', BFormDatepicker);
  Vue.component('b-pagination', BPagination);
  Vue.component('b-form-group', BFormGroup);
  Vue.component('b-input-group', BInputGroup);
  Vue.component('b-form-input', BFormInput);

  Vue.component('v-select', vSelect);

  Vue.use(PrettyCheckbox);
  Vue.use(VueAutosuggest);

  // We add the common validaton and component for Vee Validate
  extend('required',required);
  extend('email',email);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);

  Vue.filter('formatDate', (value:any) => {
    if (value)  return moment(String(value)).format('DD/MM/YYYY')
    else return ''
  })
}

/**
   * onStoreCreated
   * This method is called when the store is created within store/index.ts
   * @param store - the store instance just created.
   * @param initialState - the initial state of the store if available.
   */
hooks.onStoreCreated = function(store: Store<any>, initialState) {

  // We register a module in the store
  var applicationStore = createApplicationStore();
  store.registerModule("application", applicationStore);

  // We register the layout store
  var layoutStore = createLayoutStore();
  store.registerModule("layout", layoutStore);

  // We register the authentication module in the store
  var authenticationStore = createAuthenticationStore();
  store.registerModule("authentication", authenticationStore);

  // We register the eurofiscalis module in the store
  var eurofiscalisStore = createEurofiscalisStore();
  store.registerModule("eurofiscalis", eurofiscalisStore);

  // We add a method for language store after language is set to set the local of VeeValidate
  store.commit('languages/' + languagesTypes.mutations.ADD_AFTER_SET_LANGUAGE_ACTION, {action: 'application/' + applicationTypes.actions.UPDATE_LANGUAGE});
}

/**
 * onVueDependenciesCreated
 * This method is called when all dependencies are created (store, router, i18n), before creating the Vue instance.
 * This method is called in client.ts.
 * @param store
 * @param router
 * @param i18n
 * @param initialState
 */
hooks.onVueDependenciesCreated = function(store: Store<any>, router: VueRouter, i18n: VueI18n, initialState: any) {
  // We call the localize method initially
  // This is done as when called the first time, defaultMessage method is configured as well.
  // https://github.com/logaretm/vee-validate/issues/2426
  localize('en');

  configure({
    classes: {
      valid: 'has-success',
      invalid: 'has-error'
    },
    defaultMessage: function(_, values) {
      return i18n.t(`error.fields.${values!._rule_}`, values) as string
    }
  });

  if(store.state.languages.currentLanguageCode != null) {
    // We localize vee validate. The language code is available here on client side only.
    var languageCode = store.state.languages.currentLanguageCode;
    localize(languageCode);
  }
}

// We export the updated hooks
export { hooks };

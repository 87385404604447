const Login = () => import(/* webpackChunkName: "route-login" */ '../components/pages/Login.vue').then(m => m.default);

export const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      bodyClasses: ['gray-bg'],
      languageBundle: ['login','profile']
    }
  }
]
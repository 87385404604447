










import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
import { Component } from 'vue-property-decorator';

@Component({})
export default class Empty extends Vue {
  
  constructor() {
    super();
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior
   */
  afterLeave () {
    this.$root.$emit('triggerScroll');
  }

}

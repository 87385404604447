


























































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ListAttachments from '../eurofiscalis/company/ListAttachments.vue';
import UpgradeAccount from '../eurofiscalis/company/UpgradeAccount.vue';
import RenewContract from '../eurofiscalis/company/RenewContract.vue';
import moment from 'moment'
import * as api from '@client/utils/api';
import { WorkerContract } from "../../../../server/api/models/bean/eurofiscalis/WorkerContract";

@Component({
  components: {
    ListAttachments,
    UpgradeAccount,
    RenewContract,
    WorkerContract : () => import(/* webpackChunkName: "panels-eurofiscalis-workerContract" */ '../../panels/eurofiscalis/workers/Contract.vue'),
    ListInvoices : () => import(/* webpackChunkName: "panels-company-listInvoices" */ '../../panels/company/ListInvoices.vue'),
    ListWorkers : () => import(/* webpackChunkName: "panels-company-listWorkers" */ '../../panels/eurofiscalis/company/ListWorkers.vue'),
    Representative : () => import(/* webpackChunkName: "panels-company-representative" */ '../../panels/eurofiscalis/company/Representative.vue')
  }
})
export default class Contracts extends Vue {
  isUpgradeAccountAllowed:boolean=false
  pendingRequest:boolean=false
  activeTab:number = 0;
  @Prop({
    type: Object,
    required: true
  })
  readonly company!: Object;

  @Prop({
    type: Array,
    required: true
  })
  readonly workerContracts!: any[];

  @Prop({
    type: Array,
    required: true
  })
  readonly workerRequests!: any[];

  created() {
    this.checkUpgradeAccountAllowed();
  }

  get activeWorkerContracts() {
    var today = moment();

    return this.workerContracts.filter(workerContract => {
      return today.isBetween(
        moment(workerContract.startDate), moment(workerContract.endDate)
      );
    })
  }

  get mostRecentWorkerContractsPerCountry() {
    var countries:string[] = [];

    return this.workerContracts.sort((a, b) => {
      return Date.parse(b.endDate) - Date.parse(a.endDate);
    }).filter(workerContract => {
      var countryCode = workerContract.representativeCountry.code;

      if (this.company && this.company.migratedToMobilityPacket && countryCode !== 'EU') {
        countries.push(countryCode);
        return false;
      }

      if(countries.indexOf(countryCode) === -1) {
        countries.push(countryCode);
        return true;
      }

      return false;
    });
  }

  get companyId() {
    return this.company._id;
  }

  displayContractDetails(workerContract:any) {
    return !workerContract.isExpired ||
           this.$store.state.authentication.user.roles.indexOf('eurofiscalis-workers-admin') !== -1;
  }

  activeWorkerContractForCountry(countryCode:string) {
    return this.activeWorkerContracts.find(workerContract => {
      return workerContract.representativeCountry &&
             workerContract.representativeCountry.code === countryCode;
    });
  }

  pendingWorkerRequestForCountry(countryCode:string) {
    return this.workerRequests.find(workerRequest => {
      return workerRequest.contracts.find(contract => {
        return contract.representativeCountry &&
               contract.representativeCountry.code === countryCode;
      });
    });
  }

  renewedWorkerContractForCountry(countryCode:string) {
    var todayTimestamp = (new Date()).getTime();

    return this.workerContracts.find(workerContract => {
      return (workerContract.representativeCountry.code === countryCode) &&
             (Date.parse(workerContract.startDate) > todayTimestamp);
    });
  }

  momentDate(date:Date){
    return moment(date).format('DD/MM/YYYY');
  }

  checkUpgradeAccountAllowed() {
    this.isUpgradeAccountAllowed = true;

    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI('/api/eurofiscalis/workers/company/'+ this.companyId + '/checkUpgradeAccount', options).then((response:any) => {
      this.isUpgradeAccountAllowed = response.isUpgradeAccountAllowed;
      this.pendingRequest = response.pendingWorkerRequest;
    })
  }

  showUpgradeAccountModal() {
    // @ts-ignore
    this.$refs.upgrageAccountModal.show()
  }

  showRenewalModal() {
    // @ts-ignore
    this.$refs.renewalModal.show()
  }
}


































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import { formatAmount } from "@client/utils/helper";
import moment from "moment";

@Component({
  components: {}
})
export default class ListCreditNote extends Vue {
  debouncedSearch:Function = ()=>{};
  listCreditNoteLoading: boolean = false;
  sortBy = "number";
  sortDesc = false;

  listCreditNoteData: any[] = [];

  validatedDateFrom:string= "";
  validatedDateTo:string="";

  emptyForm:any={
    dateFrom: "",
    dateTo: "",
    global: ""
  }

  searchForm:any= {
    ...this.emptyForm
  }

  listCreditNoteFields = [
    {
      key: "number",
      label: "",
      sortable: true
    },
    {
      key: "creationDateTime",
      label: "",
      sortable: true,
      sortByFormatted: true,
      formatter: (value:any) => {
        return Date.parse(value)
      }
    },
    {
      key: "invoice.customer.name",
      label: "",
      sortable: true
    },
    {
      key: "invoice.customer.vatNumber",
      label: "",
      sortable: true
    },
    {
      key: "amountExcluding",
      label: "",
      sortable: true
    },
    {
      key: "amountIncluding",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: ""
    }
  ];

  @Watch('searchForm', { deep: true })
  onSearchFormChange(val:any) {
    this.debouncedSearch();
  }

  mounted() {
    this.listCreditNoteFields[0].label = this.$t(
      "admin_invoices.headers.number"
    ) as string;
    this.listCreditNoteFields[1].label = this.$t(
      "admin_invoices.headers.date"
    ) as string;
    this.listCreditNoteFields[2].label = this.$t(
      "admin_invoices.headers.name"
    ) as string;
    this.listCreditNoteFields[3].label = this.$t(
      "admin_invoices.headers.vatNumber"
    ) as string;
    this.listCreditNoteFields[4].label = this.$t(
      "admin_invoices.headers.excludingTaxes"
    ) as string;
    this.listCreditNoteFields[5].label = this.$t(
      "admin_invoices.headers.includingTaxes"
    ) as string;

    this.getListCreditNote();

    this.debouncedSearch = debounce(() => {
      if(!moment(this.searchForm.dateFrom).isValid()) this.validatedDateFrom = ""
      else this.validatedDateFrom = this.searchForm.dateFrom

      if(!moment(this.searchForm.dateTo).isValid()) this.validatedDateTo = ""
      else this.validatedDateTo = this.searchForm.dateTo

      this.getListCreditNote();
    }, 500);
  }

  getListCreditNote() {
    var options: api.ApiOptions = {
      app: this
    };

    var input = {
      dateFrom: this.validatedDateFrom,
      dateTo: this.validatedDateTo,
      global: this.searchForm.global
    }

    api.postAPI("/api/admin/creditnotes/list", input, options).then((response: any) => {
      this.listCreditNoteData = response.creditNotes;
    });
  }

  formatAmount(amount: number, currencyCode: string) {
    return formatAmount(
      this.$store.state.languages.currentLanguageCode,
      currencyCode,
      amount
    );
  }

  formatCreditNotes() {
    var creditNotes: any[] = [];
    this.listCreditNoteData.forEach(c => {
      creditNotes.push({
        number: c.number,
        date: c.creationDateTime,
        companyName: c.invoice.customer.name,
        vatNumber: c.invoice.customer.vatNumber,
        amountExcludingTaxes: c.amount,
        amountIncludingTaxes: c.amount
      });
    });
    return creditNotes;
  }

  exportCreditNotes() {
    var creditNotes = this.formatCreditNotes();
    var book = utils.book_new();
    var sheet = utils.json_to_sheet(creditNotes);
    utils.book_append_sheet(book, sheet);
    writeFile(book, "creditNotes.xlsx");
  }

  get currentLanguage() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }
   getDownloadCreditNoteURL(creditnote:any) {
    return '/'+ this.currentLanguage.code +'/api/creditnotes/'+creditnote._id+'/download';
  }

  resetFilter() {
    this.searchForm = {
      ...this.emptyForm
    }
  }
}

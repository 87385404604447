












import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class LastName extends mixins<GenericInput<string>>(GenericInput) {

  inputLabel = "";
  inputPlaceholder = "";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$i18n.t('profile.info.lastName') as string;
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$i18n.t('profile.info.lastName_placeholder') as string;
  }
  
}

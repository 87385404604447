









































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import * as api from '@client/utils/api';

import ResetPassword from './ResetPassword.vue';

import '../../../../../public/ext/inspinia-29/css/plugins/bootstrapSocial/bootstrap-social.css';
import * as Ladda from 'ladda';
import { updateApplicationDataForLoggedInUser } from '../../../utils/application';

@Component({
  components: {
    ResetPassword
  }
})
export default class Login extends Vue {

  loginForm = {
    login : "",
    password : ""
  }
  
  isLoginFromUsernameAllowed = true;
  isForgotPasswordAllowed = true;

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=login-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onLoginFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/login', this.loginForm, options).then((response:any) => {
      if(response.isLoggedIn) {
        // We update the application data for logged in user
        var applicationDataForLoggedInUser = response.applicationDataForLoggedInUser;
        updateApplicationDataForLoggedInUser(this.$store, applicationDataForLoggedInUser);
        
        // We redirect to logged in page
        this.$router.push(response.redirect);
      }
      else {
        this.laddaSubmit!.stop();
      }
    });
  }

  onResetPasswordClick() {
    // @ts-ignore
    this.$refs.resetPasswordModal.show()
  }

  onPasswordReseted() {
    // @ts-ignore
    this.$refs.resetPasswordModal.hide()
  }
  
}

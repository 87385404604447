





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
})
export default class IFrameWithForm extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly form: any

  mounted() {
    // @ts-ignore
    this.$refs.theForm.submit();
  }


}
























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes, languagesTypes } from '@client/store/types';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis'
import PageTitle from '../../PageTitle.vue';
import CreateWorker from '../workers/Create.vue';
import UpdateWorker from '../workers/Update.vue';
import WorkerDuplicate from '../workers/Duplicate.vue';
import WorkerDetails from '../workers/Details.vue'
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery';

@Component({
  components: {
  }
})
export default class ListAttachments extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  @Prop({
    type: String,
    required: true
  }) readonly countryCode!: string

  attachments = [];

  mounted() {
    this.attachmentsProvider();
  }

  attachmentsProvider() {
    var options:api.ApiOptions =  {
      app: this
    }

    api.getAPI(
      '/api/eurofiscalis/company/' + this.companyId + '/attachments', options
    ).then((response:any) => {
      if(response.attachments) {
        this.attachments = response.attachments.filter((attachment:any) => {
          return attachment.countryCode && attachment.countryCode.indexOf(this.countryCode) !== -1;
        });
      }
      else {
        this.attachments = [];
      }
    })
  }

  getDownloadURL(attachment:any) {
    return '/api/attachment/' + attachment._id + '/download';
  }
}

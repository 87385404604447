
import { Module, Store } from 'vuex';
import { Component, RouteConfig } from 'vue-router/types/router';
import Vue from 'vue';

import { languagesTypes, routerTypes as types } from '@client/store/types';

export function createRouterStore (store:Store<any>) {

  const routerStore:Module<any, any> = {
    namespaced: true,
    state: {
      routes: [], // contains the list of route config without component attribute.
      currentComponents: [], // contains a list of component objects to watch for async data
      availableLayouts: {}, // contains a map of available layouts
      asyncData: {}, // contains async data loaded associated to current components.
    },
    getters: {
      /**
       *  GET_ROUTES
       *  This getter provide the list of routes config.
       *  !!! It does not contains the component attribute !!!
       */
      [types.getters.GET_ROUTES]: (state, getters, rootState, rootGetters) => {
        return [...state.routes];
      },
      /**
       *  GET_ASYNC_DATA_BY_COMPONENT
       *  This getter provide the async data for a component.
       *  In case of localized component, it takes the data for current language.
       */
      [types.getters.GET_ASYNC_DATA_BY_COMPONENT]: (state, getters, rootState, rootGetters) => (component:Component) => {
        var componentName:string = component.name as string;
        var isLocalized = (component as any).isLocalizedData;
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - GET ASYNC DATA BY COMPONENT GETTER - " + componentName);
        }
        var asyncData = state.asyncData[componentName]; // This trigger refresh on getter using asyncData - issue is that it trigger getter for all components as reactivity is not working on the object child property.
        if(isLocalized && asyncData) {
          var currentLanguage = rootState.languages.currentLanguageCode; // This trigger refresh of the getter with language code is updated.
          return asyncData[currentLanguage];
        }
        return asyncData;
      }
    },
    mutations: {
      /**
       *  SET_ROUTES
       *  This mutation store the generated routes used by the router.
       */
      [types.mutations.SET_ROUTES] (state, routes:RouteConfig[]) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_ROUTES MUTATION")
        }
        state.routes = routes;
      },
      /**
       *  SET_ASYNC_DATA
       *  This mutation update store with async data for component name.
       */
      [types.mutations.SET_ASYNC_DATA] (state, {data, componentName}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_ASYNC_DATA MUTATION")
        }
        // We update the store
        if(state.asyncData[componentName]) {
          state.asyncData[componentName] = data;
        }
        else {
          // We add the componentName property to the asynData
          Vue.set(state.asyncData, componentName, data);
        }
      },
      /**
       *  SET_COMPONENTS
       *  This mutation update store with list of components matching current route.
       */
      [types.mutations.SET_COMPONENTS] (state, {listComponents}) {
        // We update the store
        Vue.set(state, 'currentComponents', [...listComponents]);
      },
      /**
       *  ADD_LAYOUT
       *  This mutation update store to add layout component.
       */
      [types.mutations.ADD_LAYOUT] (state, {layoutName, layoutComponent}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - ADD_LAYOUT MUTATION - " + layoutName)
        }
        // We update the store
        Vue.set(state.availableLayouts, layoutName, layoutComponent);
      }
    },
    actions: {
      /**
       *  UPDATE_COMPONENTS
       *  This method update the list of components to review.
       *  It will load the async data for all of the components in the current language.
       */
      [types.actions.UPDATE_COMPONENTS]({ commit, state, rootState, getters, dispatch }, listComponents:Array<Component>) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - UPDATE COMPONENTS ACTION");
        }
        // this is where we should trigger a loading indicator if there is one
        return Promise.all(listComponents.map((component) => {
          return dispatch(types.actions.CHECK_ASYNC_DATA_COMPONENT, {component});
        })).then(() => {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - ROUTER - UPDATE COMPONENTS - SET")
          }
          // We update the store with the list of components to review.
          commit(types.mutations.SET_COMPONENTS, {
            listComponents: listComponents
          });
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - ROUTER - UPDATE COMPONENTS - SET - AFTER COMMIT");
          }
          return Promise.resolve();
        })
        .catch((error) => {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - ROUTER - UPDATE COMPONENTS - ERROR - " + error);
          }
          // We update the store with empty list of components.
          commit(types.mutations.SET_COMPONENTS, {
            listComponents: []
          });
          return Promise.reject(error);
        });
      },
      /**
       *  CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE
       *  This method check if async data is loaded for all current components in the languageCode provided.
       */
      [types.actions.CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE](payload: any, {languageCode}) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;
        // We need to get data for all components that are currently loaded
        return Promise.all(
          state.currentComponents.map((component:Component) => {
            return dispatch(types.actions.CHECK_ASYNC_DATA_COMPONENT, {component, languageCode});
          })
        )
      },
      /**
       *  CHECK_ASYNC_DATA_COMPONENT
       *  This method check if async data is loaded for the component in parameter.
       */
      [types.actions.CHECK_ASYNC_DATA_COMPONENT](payload: any, {component, languageCode}):Promise<void> {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;
        // We must have a loadAsyncData function defined for the component
        if (!(component as any).loadAsyncData) {
          return Promise.resolve();
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - CHECK ASYNC DATA - We check if we need asyncData for: " + component.name);
        }
        var isLocalized = (component as any).isLocalizedData;
        if(!languageCode) {
          languageCode = rootState.languages.currentLanguageCode;
        }
        const asyncData = state.asyncData[component.name as string];
        var updatedData:any = {};
        if(!asyncData || (isLocalized && !asyncData[languageCode])) {
          if(process.env.CONSOLE == "LOG") {
            if(!isLocalized) {
              console.log("STORE - ROUTER - CHECK ASYNC DATA - We need to load asyncData for: " + component.name);
            }
            else {
              console.log("STORE - ROUTER - CHECK ASYNC DATA - We need to load asyncData for: " + component.name + " in language: " + languageCode);
            }
          }
          
          var context = {
            languageCode: languageCode
          }
          return (component as any).loadAsyncData(context).then((data:any) => {
            if(isLocalized) {
              if(asyncData) {
                updatedData = {...asyncData};
              }
              updatedData[languageCode] = data;
              updatedData.isLocalized = true;
            }
            else {
              updatedData = data;
            }
            // We store the data.
            commit(types.mutations.SET_ASYNC_DATA, {
              data: updatedData,
              componentName: component.name
            });
            return Promise.resolve();
          });
        }
        return Promise.resolve();
      },
      /**
       *  CHECK_LAYOUT
       *  This method check if layout in parameter is available.
       */
      [types.actions.CHECK_LAYOUT](payload: any, {layoutName}) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;

        if(!state.availableLayouts[layoutName]) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - ROUTER - CHECK LAYOUT - We need to load layout: " + layoutName);
          }
          return import(/* webpackChunkName: "layout-[request]" */ `../components/layouts/${layoutName}.vue`)
          .then(
            (m) => {
              var layoutComponent = m.default;
              // We store the data.
              commit(types.mutations.ADD_LAYOUT, {
                layoutName,
                layoutComponent
              });
            }
          )
          .catch((err:string) => {
            return Promise.reject(err);
          })
        }
        else {
          return Promise.resolve();
        }
      }
    }
  }

  // We add beforeLanguageUpdate handler to retrieve localized async data if needed.
  store.commit('languages/' + languagesTypes.mutations.ADD_BEFORE_SET_LANGUAGE_ACTION, {action: 'router/' + types.actions.CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE});

  return routerStore;
};












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import * as api from '@client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class UpdatePassword extends Vue {

  form = {
    currentPassword : '',
    password : '',
    confirmPassword : ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-password] button.ladda-button.update-details' );
    this.laddaSubmit = Ladda.create(updateDetailsButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "currentPassword" : this.form.currentPassword,
      "password" : this.form.password,
      "confirmPassword" : this.form.confirmPassword
    }

    var options:api.ApiOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/update', input, options).then((response:any) => {
      if(response.updated) {  
        // We reset the field
        this.form.currentPassword = "";
        this.form.password = "";
        this.form.confirmPassword = "";
        // @ts-ignore
        this.$refs.currentPasswordProvider.reset();
      }

       this.laddaSubmit!.stop();
    });
  }
  
}

































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import * as api from '@client/utils/api';
import { messagesTypes, MessageTypes } from '@client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListInvoices from '../../panels/admin/invoice/List.vue';
import ListCreditNotes from '../../panels/admin/creditnote/List.vue';

@Component({
  components: { 
    PageTitle,
    ListInvoices,
    ListCreditNotes
  }
})
export default class Invoices extends mixins(GenericPage) {}






























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GenericPage from '@client/components/mixins/GenericPage.vue';
import LoginForm from '../panels/authentication/Login.vue';

@Component({
  components: {
    LoginForm
  }
})
export default class Login extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();
  
}

import Vue from 'vue';
import { Module } from 'vuex';

import { messagesTypes as types, Message } from '@client/store/types';


export function createMessagesStore () {
  const messagesStore:Module<any, any> = {
    namespaced: true,
    state: {
      messages: [], // It contains the list of current messages of type Message.
    },
    getters: {
      /**
       * GET_MESSAGES
       * This returns the list of messages
       */
      [types.getters.GET_MESSAGES]: (state) => {
        return state.messages;
      },
    },
    mutations: {
      /**
       * ADD_MESSAGES
       * This method adds messages with type provided
       * @param messages - The list of messages (of type Message) 
       */
      [types.mutations.ADD_MESSAGES] (state, messages) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - MESSAGES - MUTATION - ADD_MESSAGES");
        }
        // We update the store
        Vue.set(state, 'messages', [...state.messages, ...messages]);
      },
      [types.mutations.ADD_GENERIC_MESSAGE] (state, {message, type}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - MESSAGES - MUTATION - ADD_GENERIC_MESSAGE");
        }
        var messagesToStore:Message[] = [{
          key:3007,
          number:3007,
          formattedMessage: message + " (3007)",
          type: type
        }]
        // We update the store
        Vue.set(state, 'messages', [...state.messages, ...messagesToStore]);
      },
      [types.mutations.CLEAR_MESSAGES] (state, messages) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - MESSAGES - MUTATION - CLEAR_MESSAGES");
        }
        // We update the store
        state.messages = [];
      },
    },
    actions: {
      
    }
  };

  return messagesStore;
};
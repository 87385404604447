



































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { mixins } from "vue-class-component";
import { write, utils, writeFile } from "xlsx";
import { debounce } from "ts-debounce";
import * as api from "@client/utils/api";
import { languagesTypes } from "@client/store/types";
import Date from "../../input/Date.vue";
import UpdateWorkerContract from '../workerContracts/Update.vue';
import moment from "moment";

@Component({
  components: {
    UpdateWorkerContract
  }
})
export default class ListCompanies extends Vue {
  debouncedSearch: Function = () => {};
  isMultiSelectAllowed = false;

  listCompaniesLoading: boolean = false;
  listCompaniesSelected = [];
  sortBy = "name";
  sortDesc = false;

  listRepresentativeCountries: any[] = [];

  listCompaniesData: any[] = [];
  companyForModal : any = "";
  listCompaniesFields = [
    {
      key: "select",
      label: "",
      class: this.isMultiSelectAllowed ? "" : "hidden"
    },
    {
      key: "name",
      label: "",
      sortable: true
    },
    {
      key: "vatNumber",
      label: "",
      sortable: true
    },
    {
      key: "activePromo",
      label: "",
      sortable: true
    },
    {
      key: "migratedToMobilityPacket",
      label: "",
      sortable: true
    },
    {
      key: "options",
      label: ""
    }
  ];

  mounted() {
    this.updateListCompanies();
    this.listCompaniesFields[1].label = this.$t("company.info.name") as string;
    this.listCompaniesFields[2].label = this.$t(
      "company.headers.vatNumber"
    ) as string;
     this.listCompaniesFields[3].label = this.$t("company.headers.activePromoCode") as string;
     this.listCompaniesFields[4].label = 'Pakiet mobilności';
    this.listCompaniesFields[5].label = this.$t(
      "company.headers.options"
    ) as string;
  }

  get currentLanguage() {
    return this.$store.getters[
      "languages/" + languagesTypes.getters.GET_CURRENT_LANGUAGE
    ];
  }

  showCompanyPage(company: any) {
    this.$router.push(
      "/" + this.currentLanguage.code + "/company/" + company._id
    );
  }

  formatCompaniesData() {
    var companies: any[] = [];
    this.listCompaniesData.forEach(c => {
      companies.push({
        companyName: c.name,
        vatNumber: c.vatNumber,
        email: c.customerEmail,
        phone: c.customerPhoneNumber,
        promoCode : c.activePromo
      });
    });
    return companies;
  }

  exportCompanies() {
    var companies = this.formatCompaniesData();
    var book = utils.book_new();
    var sheet = utils.json_to_sheet(companies);
    utils.book_append_sheet(book, sheet);
    writeFile(book, "companies.xlsx");
  }

  updateListCompanies() {
    var options: api.ApiOptions = {
      app: this
    };

    var input= {}

    this.listCompaniesLoading = true;
    api
      .postAPI("/api/admin/companies/list", input, options)
      .then((response: any) => {
        // We check the additional fields
        if (response.fields) {
          this.listCompaniesFields.splice(
            3,
            0,
            ...response.fields.map((field: any) => {
              return {
                key: field.name,
                label: this.$t("company.headers." + field.name) as string,
                sortable: field.sort
              };
            })
          );
        }
        if (response.options) {
          this.isMultiSelectAllowed = response.options.isMultiSelectAllowed;
        }
        this.listCompaniesLoading = false;
        if (response.companies) {
          this.listCompaniesData = response.companies;
        } else {
          this.listCompaniesData = [];
        }
      });
  }
   showWorkerContractUpdateModal(company:any) {
    this.companyForModal = company;

    // @ts-ignore
    this.$refs.workerContractUpdateModal.show();
  }
  onWorkerContractUpdated() {
    // @ts-ignore
    this.$refs.workerContractUpdateModal.hide();
    // @ts-ignore
   this.updateListCompanies();
  }
}
